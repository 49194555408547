import { Auth } from "../helpers";
import { baseUrlLogin } from "./interceptor";

export const downloadNewBatchFile = (batchId, accountId) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post(
    "wwi/review/download/search/input",
    {},
    {
      headers: headers,
      params: {
        batchId,
        accountId,
      },
    }
  );
};

export const batchSearchRecords = (selectedFile, batchId, accountId) => {
  let formData = new FormData();
  formData.append("file", selectedFile);

  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post("wwi/review/reupload", formData, {
    headers: headers,
    params: {
      batchId,
      accountId,
    },
  });
};

export const downloadBatchResultFileForSA = (
  batchId,
  accountId,
  originalTracerFile
) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.get(
    originalTracerFile
      ? "wwi/batch/search/download/original"
      : "wwi/batch/search/download",
    {
      headers,
      responseType: "blob",
      params: {
        id: batchId,
        batchId,
        accountId,
      },
    }
  );
};

export const getAccountStatistics = (accountId) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.get("wwi/review/batch/counts", {
    headers: headers,
    params: {
      accountId,
    },
  });
};

export const batchSearchHistoryForSuperAdmin = (body, accountId) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post("wwi/review/batch/stats", body, {
    headers,
    params: {
      accountId,
    },
  });
};

export const publishFileToCA = (selectedFile, batchId, accountId) => {
  let formData = new FormData();
  formData.append("file", selectedFile);

  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post("wwi/review/upload/result", formData, {
    headers: headers,
    params: {
      batchId,
      accountId,
    },
  });
};

export const rePublishFileToCA = (batchId, accountId) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post(
    "wwi/review/republish/batch",
    {},
    {
      headers: headers,
      params: {
        batchId,
        accountId,
      },
    }
  );
};
