/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Alert, Button, Col, Container, Row, Spinner } from "reactstrap";
import AppInput from "../../Components/AppInput/AppInput";
import { emailRegex } from "../../shared/constants/constants";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { forgotPassword } from "../../Utils/services/login.service";
import "./ForgotPassword.css";
import { ToastrService } from "../../Utils/services/tostr.service";
import useSubDomainName from "../../shared/hooks/useSubDomainName";

export default function ForgotPassword(props) {
  useDocumentTitle("Forgot-Password");

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const { subDomainName, setSubDomainName } = useSubDomainName();

  useEffect(() => {
    saveSubDomainName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSubDomainName = () => {
    if (subDomainName === "") {
      setSubDomainName();
    }
  };

  function goToHome() {
    history.push(`/`);
  }

  function handleValidation() {
    let err = {};
    let formIsValid = true;

    if (email === "") {
      formIsValid = false;
      err["email"] = "Email is required";
    } else {
      if (!emailRegex.test(email)) {
        formIsValid = false;
        err["email"] = "Email is invalid";
      }
    }

    setErrors({ ...errors, ...err });
    if (!formIsValid) {
      setShowError(true);
    }
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (handleValidation()) {
      try {
        const response = await forgotPassword({
          email,
        });
        const data = response.data.responseObject;
        if (data.code === 200) {
          ToastrService.success(data.message);
          setEmail("");
        } else {
          setShowError(true);
          const err = {
            alert: data.message,
          };
          setErrors({ ...errors, ...err });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setShowError(true);
        const err = {
          alert: error.message,
        };
        setErrors({ ...errors, ...err });
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container fluid className="forgotPassContainer">
        <div className="logoImg" onClick={goToHome}></div>
        <Row>
          <Col md={{ size: 5 }}>
            <div className="image-wrapper">
              <div></div>
            </div>
          </Col>
          <Col md={{ size: 7 }}>
            <div className="forgotPassword-wrapper">
              <div>
                <div className="forgotPasswordTitle">Forgot Password</div>
              </div>
              <div className="forgotPasswordSection">
                <div className="inputControls">
                  {showError && errors.alert && (
                    <Alert
                      fade={false}
                      color={showError ? "danger" : "success"}
                      toggle={() => setShowError(false)}
                    >
                      {errors.alert}
                    </Alert>
                  )}

                  <AppInput
                    label="Email Id"
                    errorMsg={showError ? errors.email : ""}
                    className="my-3"
                    type="text"
                    value={email}
                    validations={{
                      required: true,
                      pattern: emailRegex,
                    }}
                    onChange={(e) => {
                      setShowError(false);
                      setEmail(e.target.value);
                    }}
                  />

                  <Button
                    className="forgotPasswordBtn"
                    type="button"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <Spinner size="sm" className="mx-2"></Spinner>
                    )}
                    Submit
                  </Button>
                </div>
                <div className="footer">
                  <div className="text-center">
                    <a
                      className="link"
                      onClick={() => {
                        history.push("login");
                      }}
                    >
                      Sign In
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
