import baseUrl from "./interceptor";

export const handleSubscriptionSearch = async (query, body) => {
  return await baseUrl.post(
    `subscriptions/search/${
      query.dataset.type === "DL" ? "driver" : "vehicle"
    }`,
    body
  );
};
