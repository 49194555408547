import { Box, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import React, { act, useEffect } from "react";
import closeIcon from "../../assets/icons/close 1.svg";
import { useForm } from "react-hook-form";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

const AppTabs = ({
  centered = false,
  scrollable = false,
  showSingleTab = true,
  active,
  onTabChange,
  onClose,
  children,
}) => {
  return (
    <div>
      <Nav
        horizontal={centered ? "center" : null}
        tabs
        hidden={
          (!showSingleTab &&
            children &&
            children.filter((e) => !e.props.hidden).length === 1) ||
          children.filter((e) => !e.props.hidden).length === 0
        }
        style={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto" /* Enables horizontal scrolling */,
        }}
      >
        {children.map((tab, index) => {
          return (
            <NavItem
              hidden={tab.props.hidden}
              key={tab.props.tabKey}
              style={{
                padding: 0,
                flex: "0 0 auto",
              }}
            >
              <NavLink
                className={
                  active === tab.props.tabKey ? "active text-white" : ""
                }
                style={{
                  background: active === tab.props.tabKey && "#673ab7",
                  fontWeight: active === tab.props.tabKey && "bold",
                }}
                active={active ? true : false}
                onClick={() => {
                  onTabChange(tab.props.tabKey);
                }}
              >
                {tab.props.title}{" "}
                {tab.props.onClose && (
                  <Tooltip title={`Close ${tab.props.title}`}>
                    <IconButton
                      onClick={() => tab.props.onClose(index)}
                      style={{ color: "white" }}
                    >
                      <img
                        src={closeIcon}
                        width={10}
                        style={{
                          filter: active === tab.props.tabKey && "invert(1)",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>

      <TabContent activeTab={active}>
        {children.map((tab, index) => {
          return (
            <TabPane
              hidden={tab.props.hidden}
              tabId={tab.props.tabKey}
              key={tab.props.tabKey}
              style={{
                background: "white",
                  cursor:"default"
              }}
            >
              {tab.props.children}
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

export default AppTabs;
