import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../Utils/helpers";
import { fetchListOfState } from "../../Utils/services/accounts.service";
import { ToastrService } from "../../Utils/services/tostr.service";
import * as ActionTypes from "../../Redux/ActionTypes";

export default function useRestrictedStatesList() {
  const dispatch = useDispatch();
  const { stateList } = useSelector((state) => state.stateList);

  // State to manage the states list
  const [statesList, setStatesList] = useState([]);
  const [stateLoader, setStateLoader] = useState(false);

  // Effect to synchronize local statesList with Redux stateList
  useEffect(() => {
    setStatesList(stateList);
  }, [stateList]);

  const saveStatesList = async () => {
    setStateLoader(true);
    try {
      let result = await fetchListOfState(Auth.getTenant());
      if (result.code === 200) {
        if (result.body && result.body.length) {
          const updatedStatesList = [...result.body];

          // Update local statesList
          setStatesList(updatedStatesList);
          
          // Dispatch action to update Redux store
          dispatch({
            type: ActionTypes.SAVE_STATE_LIST,
            payload: updatedStatesList,
          });
        }
      } else {
        ToastrService.error(result.message);
      }
    } catch (error) {
      ToastrService.error(error.message);
    } finally {
      setStateLoader(false);
    }
    return Promise.resolve();
  };

  return {
    setStateList: saveStatesList,
    statesList,
    loader: stateLoader,
  };
}
