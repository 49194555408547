import {combineReducers} from "redux";
import accountDataReducer from "./accountDataReducer";
import stateListReducer from "./stateListReducer";
import subDomainReducer from "./subDomainReducer";

const rootReducer = combineReducers({
    stateList: stateListReducer,
    subDomain: subDomainReducer,
    accountData: accountDataReducer
});

export default rootReducer;