import * as React from "react";
import "./IconAppInput.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

export default function IconAppInput(props) {
  const [values, setValues] = React.useState("");

  const { label, className, onEnter, icon, placeholder, value } = props;

  React.useEffect(() => {
    if (typeof value === "string") {
      setValues(value);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event) => {
    setValues(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.code === "Enter") {
      onEnter(event.target.value);
    }
  };

  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        className={`selectContainer ${className ? className : ""}`}
      >
        {(values || !placeholder) && (
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{
              color: "#6c7985",
              fontSize: "15px",
              fontFamily: '"Open sans"',
              "&.Mui-focused ": {
                paddingRight: "3px",
                color: "#673ab7",
                background: "white",
              },
            }}
          >
            {label}
          </InputLabel>
        )}
        <OutlinedInput
          id="outlined-adornment-password"
          className={`customIconAppInput ${
            !values ? "iconAppInputHaveNoValue" : ""
          }`}
          type={"text"}
          placeholder={placeholder ? placeholder : ""}
          value={values}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          sx={{
            height: "38px",
            background: "white",
            fontSize: "15px",
            color: "#323840",
          }}
          endAdornment={
            <InputAdornment position="end" onClick={() => onEnter(values)}>
              <div
                className="inputSearchIcon"
                style={{ backgroundImage: `url(${icon})` }}
              ></div>
            </InputAdornment>
          }
          label={label}
        />
      </FormControl>
    </>
  );
}
