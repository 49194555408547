import React from "react";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import "./AccessDenied.css";

export default function AccessDenied() {
  useDocumentTitle("Access denied");

  return (
    <>
      <div className="container accessDenied">
        <section>
          <div className="accessDeniedImage"></div>
          <div className="accessDeniedTitle">403</div>
          <div className="accessDeniedSubTitle">Access Denied</div>
          <p className="accessDeniedPara">
            Sorry about that, but you do not have permission to access the
            requested page.
          </p>
        </section>
      </div>
    </>
  );
}
