import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "./AppLoader.css";

export default function AppLoader(props) {
  const { isLoading, message } = props;

  return (
    <>
      {isLoading && (
        <div className="loaderBackdrop">
          <Stack
            sx={{ color: "grey.500" }}
            className="appLoaderContainer"
            spacing={2}
            direction="row"
          >
            <div className="d-flex  align-items-center gap-4 justify-content-center">
              <CircularProgress color="secondary" />
              {message && (
                <div
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {message}
                </div>
              )}
            </div>
          </Stack>
        </div>
      )}
    </>
  );
}
