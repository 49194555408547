import * as actionTypes from '../ActionTypes/index';
import { storeInitialState } from '../Store';

const stateListReducer = (state = storeInitialState.stateStore, action) => {
    switch (action.type) {
        case actionTypes.SAVE_STATE_LIST: {
            let temp = [...action.payload];
            return {
                ...state,
                stateList: [...temp]
            };
        }
    
        default:
            return {
                ...state
            };
    }
};

export default stateListReducer;