// State List
export const SAVE_STATE_LIST = "SAVE_STATE_LIST";

// Restricted State List
export const SAVE_RESTRICTED_STATE_LIST = "SAVE_RESTRICTED_STATE_LIST";

// Sub Domain

export const SAVE_SUBDOMAIN_NAME = "SAVE_SUBDOMAIN_NAME";


// account data for review process
export const SAVE_ACCOUNT_DATA = "SAVE_ACCOUNT_DATA";
export const CLEAR_ACCOUNT_DATA = "CLEAR_ACCOUNT_DATA"