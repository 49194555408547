import React from "react";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import "./NotFound.css";

export default function NotFound() {
  useDocumentTitle("Not found");

  return (
    <>
      <div className="container notFoundPage">
        <section>
          <div className="sadIconImage"></div>
          <div className="notFoundPageTitle">404</div>
          <div className="notFoundPageSubTitle">Page Not Found</div>
          <p className="notFoundPagePara">
            The page you are looking for doesn't exist or an error occured.
          </p>
        </section>
      </div>
    </>
  );
}
