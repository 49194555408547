import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row, Col } from "reactstrap";
import AppIconSection from "../../Components/AppIconSection/AppIconSection";
import Dashboard from "../../Containers/Dashboard/Dashboard";
import DashboardNavTabs from "../../Containers/DashboardNavTabs/DashboardNavTabs";
import "./SearchResult.css";
import { Auth } from "../../Utils/helpers";
import {
  fetchExistingSearchRecod,
  fetchTracerSingleSearch,
} from "../../Utils/services/accounts.service";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { useLocation } from "react-router-dom";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";

export default function SearchResult(props) {
  useDocumentTitle("Search result");

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [batchResult, setBatchResult] = useState(false);
  const [searchInfo, setSearchInfo] = useState({
    ownerDetails: [
      {
        nameRole: null,
        fullName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        businessName: null,
        nameTitle: null,
        suffix: null,
        ssn: null,
        doB: null,
        fromDate: null,
        toDate: null,
        ownerAddressDTO: [
          {
            addressType: "",
            city: "",
            countryCode: null,
            county: null,
            fullStreet: "",
            houseNumber: "",
            state: "",
            streetName: "",
            zip: "",
            zip4: "",
          },
        ],
      },
    ],
    lienHolderDetails: [
      {
        nameRole: null,
        fullName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        businessName: null,
        nameTitle: null,
        suffix: null,
        ssn: null,
        doB: null,
        fromDate: null,
        toDate: null,
        lienHolderAddressDTOs: [
          {
            addressType: "",
            city: "",
            countryCode: null,
            county: null,
            fullStreet: "",
            houseNumber: "",
            state: "",
            streetName: "",
            zip: "",
            zip4: "",
          },
        ],
      },
    ],
    searchedOn: "",
    recordAvailableFor: null,
    vehicleDetails: {
      licencePlateNumber: "",
      vehicleType: "",
      make: "",
      model: "",
      bodyType: "",
      year: "",
      engine: null,
      driveLine: null,
      licensePlateState: "",
      vin: "",
    },
    lessorList: [
      {
        nameRole: null,
        fullName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        businessName: null,
        nameTitle: null,
        suffix: null,
        ssn: null,
        doB: null,
        fromDate: null,
        toDate: null,
        ownerAddressDTO: [
          {
            addressType: "",
            city: "",
            countryCode: null,
            county: null,
            fullStreet: "",
            houseNumber: "",
            state: "",
            streetName: "",
            zip: "",
            zip4: "",
          },
        ],
      },
    ],
  });

  const getBatchResultParamValue = () => {
    const query = new URLSearchParams(search);
    const isBatchResult = query.get("batchResult");
    return isBatchResult;
  };

  const { search } = useLocation();

  useEffect(() => {
    if (getBatchResultParamValue() === "true") {
      setBatchResult(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (history.location.pathname.includes("true")) {
      fetchSearchResult();
    } else {
      fetchSearchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fetchSearchRecords = async () => {
    let id = sessionStorage.getItem("searchId");
    let batchId = sessionStorage.getItem("batchId");

    if (!id) {
      if (batchResult || getBatchResultParamValue() === "true") {
        history.goBack();
      } else {
        history.push("/account/history");
      }
      return true;
    }
    try {
      setIsLoading(true);
      let result = await fetchExistingSearchRecod(
        Auth.getTenant(),
        id,
        batchId
      );
      if (result.code === 200) {
        if (result.body && result.body) {
          setSearchInfo(result.body);
          sessionStorage.removeItem("searchId");
          sessionStorage.removeItem("batchId");
        }
      } else {
        // ToastrService.error(result.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // ToastrService.error(error.message);
    }
  };

  const fetchSearchResult = async () => {
    let licencePlateNumber = sessionStorage.getItem("licencePlateNumber");
    let licensePlateState = sessionStorage.getItem("licensePlateState");

    if (!licencePlateNumber && !licensePlateState) {
      if (batchResult || getBatchResultParamValue() === "true") {
        history.goBack();
      } else {
        history.push("/account/history");
      }
      return true;
    }
    try {
      setIsLoading(true);
      let result = await fetchTracerSingleSearch(
        Auth.getTenant(),
        licencePlateNumber,
        licensePlateState
      );
      if (result.code === 200) {
        if (result.body && result.body) {
          setSearchInfo(result.body);
          sessionStorage.removeItem("licencePlateNumber");
          sessionStorage.removeItem("licensePlateState");
        }
      } else {
        // ToastrService.error(result.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // ToastrService.error(error.message);
    }
  };

  const checkIfValueIsNullOrEmpty = (value) => {
    if (value && value !== "") {
      return value;
    } else {
      return "-";
    }
  };

  const checkIfHaveVehicleDetails = (data) => {
    if (data) return true;
    return false;
  };

  const checkIfHaveOwnerDetails = (data) => {
    if (!data.ownerDetails) return false;
    else {
      if (!data?.ownerDetails.length) return false;
      return true;
    }
  };

  const checkIfHaveSecondOwnerDetails = (data) => {
    if (!data.ownerDetails) return false;
    else {
      if (data?.ownerDetails.length <= 1) return false;
      return true;
    }
  };

  const checkIfHaveLienDetails = (data) => {
    if (!data.lienHolderDetails) return false;
    else {
      if (!data?.lienHolderDetails.length) return false;
      return true;
    }
  };

  const checkIfHaveLessorDetails = (data) => {
    if (!data.lessorList) return false;
    else {
      if (!data?.lessorList.length) return false;
      return true;
    }
  };

  const checkIfHaveOwnerAddressDetails = (data) => {
    if (
      checkIfHaveOwnerDetails(data) &&
      searchInfo?.ownerDetails[0]["ownerAddressDTO"] &&
      searchInfo?.ownerDetails[0]["ownerAddressDTO"].length
    )
      return true;
    else return false;
  };

  const checkIfHaveSecondOwnerAddressDetails = (data) => {
    if (
      checkIfHaveSecondOwnerDetails(data) &&
      searchInfo?.ownerDetails[1]["ownerAddressDTO"] &&
      searchInfo?.ownerDetails[1]["ownerAddressDTO"].length
    )
      return true;
    else return false;
  };

  const checkIfHaveLienAddressDetails = (data) => {
    if (
      checkIfHaveLienDetails(data) &&
      searchInfo?.lienHolderDetails[0]["lienHolderAddressDTOs"] &&
      searchInfo?.lienHolderDetails[0]["lienHolderAddressDTOs"].length
    )
      return true;
    else return false;
  };

  const checkIfHaveLessorAddressDetails = (data) => {
    if (
      checkIfHaveLessorDetails(data) &&
      searchInfo?.lessorList[0]["ownerAddressDTO"] &&
      searchInfo?.lessorList[0]["ownerAddressDTO"].length
    )
      return true;
    else return false;
  };

  return (
    <>
      <Dashboard showAlert={true} />
      <AppLoader isLoading={isLoading} />
      <DashboardNavTabs active="single" />
      <div className="searchResultContainer container-fluid">
        <Row className="searchResultHeader">
          <Col md={{ size: 6 }}>
            <div
              className="backIcon logo"
              onClick={() => {
                if (batchResult) {
                  history.goBack();
                } else {
                  history.push("/account/history");
                }
              }}
            ></div>
            <div className="searchResultTitle">Search Result</div>
          </Col>
          <Col md={{ size: 6 }}>
            <AppIconSection />
          </Col>
        </Row>
        <div className="resultSection">
          <Row className="mb-3">
            <Col lg={{ size: 5 }}>
              <Row className="resultColumnTitleRow mb-3">
                <Col md={{ size: 6 }} className="resultColumnTitleIcon">
                  <div className="resultSectionCarLogo logo"></div>
                </Col>
                <Col md={{ size: 6 }} className="resultColumnTitleText">
                  <div className="resultSectionTitle">Vehicle Information</div>
                </Col>
              </Row>
              <div className="mb-3">
                <div className="labelName">License Plate Number</div>
                <div className="labelValue">
                  {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo.vehicleDetails.licencePlateNumber
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">State of Issuance</div>
                <div className="labelValue">
                  {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo.vehicleDetails.licensePlateState
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Vehicle Identification Number</div>
                <div className="labelValue">
                  {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                    ? checkIfValueIsNullOrEmpty(searchInfo.vehicleDetails.vin)
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">Make</div>
                      <div className="labelValue">
                        {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo.vehicleDetails.make
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">Model</div>
                      <div className="labelValue">
                        {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo.vehicleDetails.model
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">Body Type</div>
                      <div className="labelValue">
                        {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo.vehicleDetails.bodyType
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">Year</div>
                      <div className="labelValue">
                        {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo.vehicleDetails.year
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">Series</div>
                      <div className="labelValue">-</div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">Trim</div>
                      <div className="labelValue">-</div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">Engine</div>
                      <div className="labelValue">
                        {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo.vehicleDetails.engine
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">Driveline</div>
                      <div className="labelValue">
                        {checkIfHaveVehicleDetails(searchInfo.vehicleDetails)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo.vehicleDetails.driveLine
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={{ size: 7 }}>
              <Row className="resultColumnTitleRow mb-3">
                <Col md={{ size: 6 }} className="resultOwnerColumn">
                  <div className="resultSectionUserLogo logo"></div>
                </Col>
                <Col md={{ size: 6 }} className="resultColumnTitleText">
                  <div className="resultSectionTitle">Owner Information</div>
                </Col>
              </Row>

              <div className="mb-3">
                <Row className="dualContainer">
                  <Col lg={{ size: 6 }}>
                    <div className="mb-3">
                      <div className="labelName">Owner Full Name</div>
                      <div className="labelValue">
                        {checkIfHaveOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[0]["fullName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Owner First Name</div>
                      <div className="labelValue">
                        {checkIfHaveOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[0]["firstName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Owner Middle Name</div>
                      <div className="labelValue">
                        {checkIfHaveOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[0]["middleName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Owner Last Name</div>
                      <div className="labelValue">
                        {checkIfHaveOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[0]["lastName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Owner Business Name</div>
                      <div className="labelValue">
                        {checkIfHaveOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[0]["businessName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Owner Address</div>
                      <div className="labelValue">
                        {checkIfHaveOwnerAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[0]["ownerAddressDTO"][0][
                                "fullStreet"
                              ]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <Row className="dualContainer">
                        <Col>
                          <div>
                            <div className="labelName">Owner City</div>
                            <div className="labelValue">
                              {checkIfHaveOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[0][
                                      "ownerAddressDTO"
                                    ][0]["city"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="labelName">Owner State</div>
                            <div className="labelValue">
                              {checkIfHaveOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[0][
                                      "ownerAddressDTO"
                                    ][0]["state"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row className="dualContainer">
                        <Col>
                          <div>
                            <div className="labelName">Owner Zip</div>
                            <div className="labelValue">
                              {checkIfHaveOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[0][
                                      "ownerAddressDTO"
                                    ][0]["zip"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="labelName">Owner Zip +4</div>
                            <div className="labelValue">
                              {checkIfHaveOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[0][
                                      "ownerAddressDTO"
                                    ][0]["zip4"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={{ size: 6 }}>
                    <div className="mb-3">
                      <div className="labelName">Second Owner Full Name</div>
                      <div className="labelValue">
                        {checkIfHaveSecondOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[1]["fullName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Second Owner First Name</div>
                      <div className="labelValue">
                        {checkIfHaveSecondOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[1]["firstName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Second Owner Middle Name</div>
                      <div className="labelValue">
                        {checkIfHaveSecondOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[1]["middleName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Second Owner Last Name</div>
                      <div className="labelValue">
                        {checkIfHaveSecondOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[1]["lastName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">
                        Second Owner Business Name
                      </div>
                      <div className="labelValue">
                        {checkIfHaveSecondOwnerDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[1]["businessName"]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="labelName">Second Owner Address</div>
                      <div className="labelValue">
                        {checkIfHaveSecondOwnerAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.ownerDetails[1]["ownerAddressDTO"][0][
                                "fullStreet"
                              ]
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="mb-3">
                      <Row className="dualContainer">
                        <Col>
                          <div>
                            <div className="labelName">Second Owner City</div>
                            <div className="labelValue">
                              {checkIfHaveSecondOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[1][
                                      "ownerAddressDTO"
                                    ][0]["city"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="labelName">Second Owner State</div>
                            <div className="labelValue">
                              {checkIfHaveSecondOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[1][
                                      "ownerAddressDTO"
                                    ][0]["state"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row className="dualContainer">
                        <Col>
                          <div>
                            <div className="labelName">Second Owner Zip</div>
                            <div className="labelValue">
                              {checkIfHaveSecondOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[1][
                                      "ownerAddressDTO"
                                    ][0]["zip"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="labelName">Second Owner Zip +4</div>
                            <div className="labelValue">
                              {checkIfHaveSecondOwnerAddressDetails(searchInfo)
                                ? checkIfValueIsNullOrEmpty(
                                    searchInfo?.ownerDetails[1][
                                      "ownerAddressDTO"
                                    ][0]["zip4"]
                                  )
                                : "-"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={{ size: 5 }}>
              <Row className="resultColumnTitleRow mb-3">
                <Col md={{ size: 6 }} className="resultColumnTitleIcon">
                  <div className="resultSectionLienLogo logo"></div>
                </Col>
                <Col md={{ size: 6 }} className="resultColumnTitleText">
                  <div className="resultSectionTitle">Lien Information</div>
                </Col>
              </Row>
              <div className="mb-3">
                <div className="labelName">Full Name</div>
                <div className="labelValue">
                  {checkIfHaveLienDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lienHolderDetails[0]["fullName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">First Name</div>
                <div className="labelValue">
                  {checkIfHaveLienDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lienHolderDetails[0]["firstName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Middle Name</div>
                <div className="labelValue">
                  {checkIfHaveLienDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lienHolderDetails[0]["middleName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Last Name</div>
                <div className="labelValue">
                  {checkIfHaveLienDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lienHolderDetails[0]["lastName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Business Name</div>
                <div className="labelValue">
                  {checkIfHaveLienDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lienHolderDetails[0]["businessName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Address</div>
                <div className="labelValue">
                  {checkIfHaveLienAddressDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lienHolderDetails[0][
                          "lienHolderAddressDTOs"
                        ][0]["fullStreet"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">City</div>
                      <div className="labelValue">
                        {checkIfHaveLienAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lienHolderDetails[0][
                                "lienHolderAddressDTOs"
                              ][0]["city"]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">State</div>
                      <div className="labelValue">
                        {checkIfHaveLienAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lienHolderDetails[0][
                                "lienHolderAddressDTOs"
                              ][0]["state"]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">Zip</div>
                      <div className="labelValue">
                        {checkIfHaveLienAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lienHolderDetails[0][
                                "lienHolderAddressDTOs"
                              ][0]["zip"]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">Zip +4</div>
                      <div className="labelValue">
                        {checkIfHaveLienAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lienHolderDetails[0][
                                "lienHolderAddressDTOs"
                              ][0]["zip4"]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={{ size: 7 }}>
              <Row className="resultColumnTitleRow mb-3">
                <Col
                  md={{ size: 6 }}
                  className="resultColumnTitleIcon resultLessorColumn"
                >
                  <div className="resultSectionLessorLogo logo"></div>
                </Col>
                <Col md={{ size: 6 }} className="resultColumnTitleText">
                  <div className="resultSectionTitle">Lessor Information</div>
                </Col>
              </Row>
              <div className="mb-3">
                <div className="labelName">Full Name</div>
                <div className="labelValue">
                  {checkIfHaveLessorDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lessorList[0]["fullName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">First Name</div>
                <div className="labelValue">
                  {checkIfHaveLessorDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lessorList[0]["firstName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Middle Name</div>
                <div className="labelValue">
                  {checkIfHaveLessorDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lessorList[0]["middleName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Last Name</div>
                <div className="labelValue">
                  {checkIfHaveLessorDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lessorList[0]["lastName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Business Name</div>
                <div className="labelValue">
                  {checkIfHaveLessorDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lessorList[0]["businessName"]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <div className="labelName">Address</div>
                <div className="labelValue">
                  {checkIfHaveLessorAddressDetails(searchInfo)
                    ? checkIfValueIsNullOrEmpty(
                        searchInfo?.lessorList[0]["ownerAddressDTO"][0][
                          "fullStreet"
                        ]
                      )
                    : "-"}
                </div>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">City</div>
                      <div className="labelValue">
                        {checkIfHaveLessorAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lessorList[0]["ownerAddressDTO"][0][
                                "city"
                              ]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">State</div>
                      <div className="labelValue">
                        {checkIfHaveLessorAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lessorList[0]["ownerAddressDTO"][0][
                                "state"
                              ]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row className="dualContainer">
                  <Col>
                    <div>
                      <div className="labelName">Zip</div>
                      <div className="labelValue">
                        {checkIfHaveLessorAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lessorList[0]["ownerAddressDTO"][0][
                                "zip"
                              ]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="labelName">Zip +4</div>
                      <div className="labelValue">
                        {checkIfHaveLessorAddressDetails(searchInfo)
                          ? checkIfValueIsNullOrEmpty(
                              searchInfo?.lessorList[0]["ownerAddressDTO"][0][
                                "zip4"
                              ]
                            )
                          : "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
