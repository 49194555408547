import React from "react";
import { useHistory } from "react-router";
import { Navbar, NavbarBrand } from "reactstrap";
import "./Header.css";
import Menu from "./Menu/Menu";

export default function Header(props) {
  const { isMobileMenu = true } = props;
  const history = useHistory();

  const showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };

  function goToHome() {
    isMobileMenu && history.push(`/login`);
  }

  return (
    <>
      <header className="header-container">
        <Navbar light>
          {isMobileMenu && (
            <div className="sidebar-btn" onClick={showMobilemenu}></div>
          )}

          <NavbarBrand
            className="me-auto logoContainer"
             onClick={goToHome}
          />
          <Menu />
        </Navbar>
      </header>
    </>
  );
}
