import { toast } from "react-toastify";

const success = (message) => {
  toast.success(message, {
    position: "top-center",
    className: message.length > 50 ? "customToastrWidth" : "normalToastrWidth",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
};

const error = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 5000,
    className: message.length > 50 ? "customToastrWidth" : "normalToastrWidth",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

const info = (message) => {
  toast.info(message, {
    position: "top-center",
    autoClose: 5000,
    className: message.length > 50 ? "customToastrWidth" : "normalToastrWidth",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

export const ToastrService = {
  success,
  error,
  info,
};
