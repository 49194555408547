import React, { useEffect, useState } from "react";
import "./ReviewStepper.css";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useHistory } from "react-router-dom";

const status = ["New", "Pending Review", "Published", "Expired"];

const statusBasedStepsInfo = [
  {
    activeStep: 0,
    completedSteps: {},
    disabledSteps: {
      2: true,
      3: true,
      4: true,
    },
  },
  {
    activeStep: 2,
    completedSteps: {
      0: true,
      1: true,
    },
    disabledSteps: {
      0: true,
      1: true,
      4: true,
    },
  },
  {
    activeStep: 3,
    completedSteps: {
      0: true,
      1: true,
      2: true,
      3: true,
    },
    disabledSteps: {
      0: true,
      1: true,
      4: true,
    },
  },
  {
    activeStep: 4,
    completedSteps: {
      0: true,
      1: true,
      2: true,
      3: true,
    },
    disabledSteps: {
      0: true,
      1: true,
      3: true,
    },
  },
];

export default function ReviewStepper(props) {
  const [completed, setCompleted] = useState({});
  const [disabled, setDisabled] = useState({});
  const [isMobileScreen] = useState(window.innerWidth < 486 ? true : false);

  const history = useHistory();

  useEffect(() => {
    if (props.batchDetails) {
      const index = status.indexOf(props.batchDetails.fileStatus);
      // const index = status.indexOf("Pending Review");
      if (index === -1) return history.goBack();
      props.activeStepChanged(statusBasedStepsInfo[index].activeStep);
      setCompleted(statusBasedStepsInfo[index].completedSteps);
      setDisabled(statusBasedStepsInfo[index].disabledSteps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStep = (step) => () => {
    props.activeStepChanged(step);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper nonLinear activeStep={props.activeStep} alternativeLabel={!isMobileScreen} orientation={isMobileScreen ? "vertical" : "horizontal"}>
          {props.steps.map((label, index) => (
            <Step
              key={label}
              completed={completed[index]}
              disabled={disabled[index]}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "#673ab7", // circle color (COMPLETED)
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "#673ab7", // circle color (ACTIVE)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "black", // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "#673ab7", // Just text label (ACTIVE)
                  },
                "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                  {
                    color: "#00000099", // Just text label (DISABLED)
                  },
                "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                  color: "black", // Just text label
                },
              }}
            >
              <StepButton onClick={handleStep(index)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
}
