import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import TableLoader from "../../Components/TableLoader/TableLoader";
import useTable from "../../Components/useTable";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import "./AccountStats.css";
import * as ActionTypes from "../../Redux/ActionTypes";
import AppIconSection from "../../Components/AppIconSection/AppIconSection";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { getAccountStatistics } from "../../Utils/services/review.service";

const headCells = [
  {
    id: "accountName",
    label: "Account Name",
    width: "20%",
    disableSorting: true,
  },
  {
    id: "newFile",
    label: "New Batch File",
    width: "20%",
    disableSorting: true,
  },
  {
    id: "pendingReview",
    label: "Pending Review",
    width: "20%",
    disableSorting: true,
  },
  {
    id: "pubishedBatch",
    label: "Published Batch Count",
    width: "20%",
    disableSorting: true,
  },
  {
    id: "totalCount",
    label: "Total Batch Search",
    width: "20%",
    disableSorting: true,
  },
];

export default function AccountStats() {
  useDocumentTitle("Account Statistics");

  const { accountData } = useSelector((state) => state);

  // const [currentUserRole] = useState(Auth.getUserRole());
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { TblContainer, TblHead } = useTable(data, headCells);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (accountData.accountId) {
      fetchAccountStats();
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page]);

  const fetchAccountStats = async (resetData = false) => {
    setloader(true);

    try {
      let response = await getAccountStatistics(accountData.accountId);

      let responseObject = response.data.responseObject;
      if (responseObject.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (responseObject.body && Object.keys(responseObject.body).length) {
          temp.push({...responseObject.body});
          setData(temp);
          setTotalRecords(temp.length);
        }
        setloader(false);
        setIsLoading(false);
        setError("");
      } else {
        setloader(false);
        setIsLoading(false);
        setError(responseObject.message);
      }
    } catch (error) {
      setloader(false);
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleSortRequest = (orderBy, order) => {
    document.getElementsByClassName("accountStatsTable")[0].scrollTo(0, 0);
    setOrder(order);
    setPage(0);
    setOrderBy(orderBy);
    setTimeout(() => {
      setData([]);
    }, 10);
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  const checkIfValueIsNullOrEmpty = (value) => {
    if (value && value !== "") {
      return value;
    } else {
      return "-";
    }
  };

  const handleLinkClick = (status) => {
    dispatch({
      type: ActionTypes.SAVE_ACCOUNT_DATA,
      payload: {
        batchListFilterValue: status,
      },
    });

    history.push("/account/batchList");
  };

  // const isSuperAdmin = () => {
  //   return currentUserRole === super_admin ? true : false;
  // };

  return (
    <>
      <AppLoader isLoading={isLoading} />
      <div className="container-fluid">
        <Row >
          <Col lg={{ size: 6 }}>
            <div>
              <div className="pageTitle text-capitalize">Account Statistics</div>
            </div>
          </Col>
          <Col lg={{ size: 6 }}>
            <div className="accountStatsIconSection">
              <AppIconSection
                hideUploadIcon={true}
                refreshIcon={true}
                onRefreshClicked={() => {
                  setIsLoading(true);
                  if (page) {
                    setPage(0);
                  } else {
                    fetchAccountStats(true);
                  }
                  document
                    .getElementsByClassName("accountStatsTable")[0]
                    .scrollTo(0, 0);
                }}
              />
            </div>
          </Col>
        </Row>
        {/* <div className="pageTitle text-capitalize">Account Statistics</div>
        <AppIconSection
          hideUploadIcon={true}
          refreshIcon={true}
          onRefreshClicked={() => {
            setIsLoading(true);
            if (page) {
              setPage(0);
            } else {
              fetchAccountStats(true);
            }
            document
              .getElementsByClassName("accountStatsTable")[0]
              .scrollTo(0, 0);
          }}
        /> */}
        <div className="accountStatsSection">
          <Row className="accountStatsList">
            <Col>
              {error !== "" && (
                <span className="text-danger errorMsg">{error}</span>
              )}
            </Col>
          </Row>
          <Row className="accountStatsList">
            <Col>
              <InfiniteScroll
                className="accountStatsTable"
                dataLength={data.length}
                next={() => addNewData()}
                hasMore={data.length < totalRecords}
                height={350}
                endMessage={
                  !loader &&
                  !data.length && (
                    <p className="text-secondary mt-3 text-center">
                      No Result Found
                    </p>
                  )
                }
              >
                <TblContainer>
                  <TblHead
                    handleSort={(orderBy, order) =>
                      handleSortRequest(orderBy, order)
                    }
                  />
                  <TableBody>
                    {data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(accountData.tenantName)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue statsLink" onClick={() => handleLinkClick('New')}>
                            {checkIfValueIsNullOrEmpty(item.newBatchFileCount)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue statsLink" onClick={() => handleLinkClick('Pending Review')}>
                            {checkIfValueIsNullOrEmpty(item.pendingReviewCount)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue statsLink" onClick={() => handleLinkClick('Published')}>
                            {checkIfValueIsNullOrEmpty(item.publishedCount)}
                          </div>
                        </TableCell>
												<TableCell>
                          <div className="tableCellValue statsLink" onClick={() => handleLinkClick('All')}>
                            {checkIfValueIsNullOrEmpty(item.totalBatchFilesCount)}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {loader && <TableLoader columnsNumber={5} />}
                  </TableBody>
                </TblContainer>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
