import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import AppIconSection from "../../Components/AppIconSection/AppIconSection";
import AppMenu from "../../Components/AppMenu/AppMenu";
import TableLoader from "../../Components/TableLoader/TableLoader";
import useTable from "../../Components/useTable";
import Dashboard from "../../Containers/Dashboard/Dashboard";
import DashboardNavTabs from "../../Containers/DashboardNavTabs/DashboardNavTabs";
import "./BatchSearchHistory.css";

import downloadFileIcon from "../../assets/icons/download-excel.svg";
import {
  batchSearchHistory,
  downloadBatchResultFile,
  downloadExcelFile,
  downloadFile,
} from "../../Utils/services/batchSearch.service";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { Auth } from "../../Utils/helpers";
import {
  convertTZ,
  getFileNameFromHeader,
  roles,
} from "../../shared/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "../../Redux/ActionTypes";
import AppSelect from "../../Components/AppSelect/AppSelect";
import { batchSearchHistoryForSuperAdmin } from "../../Utils/services/review.service";
import useSubDomainName from "../../shared/hooks/useSubDomainName";

const menuOptions = [
  { icon: downloadFileIcon, label: "Download Results", value: "csv/excel" },
];

const tableHeadCells = [
  {
    id: "fileName",
    label: "Generated Batch Files",
    width: "30%",
  },
  // { id: "searchType", label: "Search Type", width: "10%", disableSorting: false },
  { id: "createdAt", label: "Searched On", width: "15%", disableSorting: true },
  {
    id: "searchedBy",
    label: "Searched By",
    width: "15%",
    disableSorting: true,
  },
  {
    id: "recordAvailableForHrs",
    label: "Record Available for (Hours)",
    width: "15%",
    disableSorting: true,
  },
  {
    id: "recordsProcessed",
    label: "Records Processed",
    width: "10%",
    disableSorting: true,
  },
  { id: "status", label: "Status", width: "10%", disableSorting: true },
  { id: "action", label: "Action", width: "5%", disableSorting: true },
];

export const Status = [
  { name: "New", value: "new" },
  { name: "Pending Review", value: "pendingReview" },
  { name: "Published", value: "published" },
  { name: "All", value: "all" },
];

export default function BatchSearchHistory() {
  useDocumentTitle("Batch Search History");

  const { accountData } = useSelector((state) => state);

  const [headCells, setHeadCells] = useState(tableHeadCells);
  const [currentUserRole] = useState(Auth.getUserRole());
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilterValue, setStatusFilterValue] = useState(
    accountData.batchListFilterValue ? accountData.batchListFilterValue : "New"
  );

  const { TblContainer, TblHead } = useTable(data, headCells);
  const { subDomainName, setSubDomainName } = useSubDomainName();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    saveSubDomainName();
    let tableHeader = Array.from(tableHeadCells);
    if (isSuperAdmin()) {
      tableHeader.splice(3, 1);
      setHeadCells(tableHeader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSubDomainName = () => {
    if (subDomainName === "") {
      setSubDomainName();
    }
  };

  useEffect(() => {
    if (!accountData.accountId && isSuperAdmin()) {
      history.push("/account/list");
      return;
    }

    if (isSuperAdmin()) {
      fetchBatchSearchHistoryForSuperAdmin();
      return;
    } else {
      fetchBatchSearchHistory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, statusFilterValue]);

  const fetchBatchSearchHistory = async (resetData = false) => {
    setloader(true);
    try {
      let response = await batchSearchHistory({
        sortDirection: resetData ? "DESC" : order,
        sortBy: resetData ? "createdAt" : orderBy,
        pageNumber: resetData ? 0 : page,
        size: 10,
        searchBy: "",
      });

      if (response.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach(
            (item) => (item.searchedOn = convertTZ(item.searchedOn))
          );
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setIsLoading(false);
        setError("");
      } else {
        setloader(false);
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setIsLoading(false);
      setError(error.message);
    }
  };

  const fetchBatchSearchHistoryForSuperAdmin = async (resetData = false) => {
    setloader(true);
    try {
      let apiResponse = await batchSearchHistoryForSuperAdmin(
        {
          sortDirection: resetData ? "DESC" : order,
          sortBy: orderBy === "createdAt" ? "created_at" : "file_name",
          pageNumber: resetData ? 0 : page,
          size: 10,
          searchBy: statusFilterValue === "All" ? "" : statusFilterValue,
        },
        accountData.accountId
      );

      const response = apiResponse.data.responseObject;
      if (response.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach(
            (item) => (item.searchedOn = convertTZ(item.searchedOn))
          );
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setIsLoading(false);
        setError("");
      } else {
        setloader(false);
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleSortRequest = (OrderBy, Order) => {
    document.getElementsByClassName("batchHistoryTable")[0].scrollTo(0, 0);
    setOrder(Order);
    setPage(0);
    setOrderBy(OrderBy);
    setTimeout(() => {
      setData([]);
    }, 10);
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  const downloadBatchResultFileForCA = async (id, type) => {
    setIsLoading(true);
    try {
      let response = await downloadBatchResultFile(id, subDomainName);

      const fileName = getFileNameFromHeader(
        response?.headers["content-disposition"]
      );
      if (fileName.includes("csv")) {
        downloadFile(response.data, "batch_search_result.csv");
      } else {
        downloadExcelFile(response.data, "batch_search_result.xlsx");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const selectedMenu = (value, id) => {
    downloadBatchResultFileForCA(id, value);
  };

  const checkFileStatus = (status) => {
    if (isSuperAdmin()) return true;
    if (
      status &&
      (status.toLowerCase() === "completed" ||
        status.toLowerCase() === "expired")
    )
      return true;
    else return false;
  };

  const checkIfFileIsInProcess = (status) => {
    if (
      status &&
      (status.toLowerCase() === "processing search" ||
        status.toLowerCase() === "publishing")
    ) {
      return false;
    } else return true;
  };

  const isSuperAdmin = () => {
    return currentUserRole.includes(roles.super_admin) ? true : false;
  };

  const checkFileDate = (recordDate) => {
    const BatchFileDate = new Date(recordDate);
    const reviewFeatureDeploymentDate = new Date("2022-06-19");
    if (BatchFileDate > reviewFeatureDeploymentDate) return true;
    else return false;
  };

  return (
    <>
      {!isSuperAdmin() && (
        <>
          <AppLoader isLoading={isLoading} />
          <Dashboard />
          <DashboardNavTabs active="batch" />
        </>
      )}
      <div className="container-fluid">
        {isSuperAdmin() && (
          <div className="pageTitle text-capitalize">
            {accountData?.tenantName}
          </div>
        )}
        <Row className="batchHistoryHeader mt-4">
          <Col md={{ size: 6 }}>
            {!isSuperAdmin() && (
              <div className="batchHistoryTitle">Batch Search List</div>
            )}
          </Col>
          <Col md={{ size: 6 }} className="batchListFilterSection">
            {isSuperAdmin() && (
              <div className="batchListFilterInput">
                <AppSelect
                  label="Status"
                  value={statusFilterValue}
                  options={Status}
                  onChange={(e) => {
                    setPage(0);
                    setStatusFilterValue(e.target.value);
                  }}
                />
              </div>
            )}

            <div className="batchListAppIconSection">
              <AppIconSection
                hideUploadIcon={isSuperAdmin() ? true : false}
                refreshIcon={true}
                onRefreshClicked={() => {
                  setIsLoading(true);
                  if (page) {
                    setPage(0);
                  } else {
                    if (isSuperAdmin())
                      fetchBatchSearchHistoryForSuperAdmin(true);
                    else fetchBatchSearchHistory(true);
                  }
                  document
                    .getElementsByClassName("batchHistoryTable")[0]
                    .scrollTo(0, 0);
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="historySection">
          <Row className="batchHistoryList">
            <Col>
              {error !== "" && (
                <span className="text-danger errorMsg">{error}</span>
              )}
            </Col>
          </Row>
          <Row className="batchHistoryList">
            <Col>
              <InfiniteScroll
                className="batchHistoryTable"
                dataLength={data.length}
                next={() => addNewData()}
                hasMore={data.length < totalRecords}
                height={398}
                endMessage={
                  !loader &&
                  !data.length && (
                    <p className="text-secondary mt-3 text-center">
                      No Result Found
                    </p>
                  )
                }
              >
                <TblContainer>
                  <TblHead
                    handleSort={(orderBy, order) =>
                      handleSortRequest(orderBy, order)
                    }
                  />
                  <TableBody>
                    {data.map((item) => (
                      <TableRow key={item.id}>
                        {isSuperAdmin() ? (
                          <TableCell>
                            <div className="batchFileCell disableColumn">
                              <div className="smallFileIcon"></div>
                              <div className="batchFileNameText">
                                {item.fileName}
                              </div>
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell
                            className={`batchFileColumn ${
                              !checkFileStatus(item.fileStatus) &&
                              "disableColumn"
                            }`}
                          >
                            <div
                              className="batchFileCell"
                              onClick={() => {
                                if (checkFileStatus(item.fileStatus)) {
                                  history.push(
                                    `/account/search/batchResult?id=${item.id}`
                                  );
                                }
                              }}
                            >
                              <div className="smallFileIcon"></div>
                              <div className="batchFileNameText">
                                {item.fileName}
                              </div>
                            </div>
                          </TableCell>
                        )}
                        {/* <TableCell>
                          <div className="tableCellValue">
                            {checkFileStatus(item.fileStatus) ||
                            item.fileStatus === "Processing"
                              ? item.searchType
                              : "-"}
                          </div>
                          <div className="tableCellSubValue"></div>
                        </TableCell> */}
                        <TableCell>
                          <div className="tableCellValue">
                            {checkFileStatus(item.fileStatus) ||
                            item.fileStatus === "Processing"
                              ? item.searchedOn
                              : "-"}
                          </div>
                          <div className="tableCellSubValue"></div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkFileStatus(item.fileStatus) ||
                            item.fileStatus === "Processing"
                              ? item.searchedBy
                              : "-"}
                          </div>
                          <div className="tableCellSubValue"></div>
                        </TableCell>
                        {!isSuperAdmin() && (
                          <TableCell>
                            {checkFileStatus(item.fileStatus) ? (
                              <div className="remainingTimeCell">
                                <div className="smallTimerIcon"></div>
                                <div
                                  className={`tableCellValue remainingTimeValue ${
                                    !+item.recordAvailableForHrs &&
                                    "text-danger"
                                  }`}
                                >
                                  {item.recordAvailableForHrs}
                                </div>
                              </div>
                            ) : (
                              <div className="tableCellValue">-</div>
                            )}
                            <div className="tableCellSubValue">
                              {checkFileStatus(item.fileStatus) && "Hours Left"}
                            </div>
                          </TableCell>
                        )}
                        {isSuperAdmin() ? (
                          <TableCell>
                            <div className="tableCellValue">
                              {!checkIfFileIsInProcess(item.fileStatus)
                                ? "-"
                                : item.recordProcessed
                                ? item.recordProcessed
                                : "-"}
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell>
                            {checkFileStatus(item.fileStatus) ? (
                              <div
                                className={`totalRecordCell ${
                                  !checkFileStatus(item.fileStatus) &&
                                  "disableColumn"
                                }`}
                                onClick={() => {
                                  if (checkFileStatus(item.fileStatus)) {
                                    history.push(
                                      `/account/search/batchResult?id=${item.id}`
                                    );
                                  }
                                }}
                              >
                                {item.recordProcessed}
                              </div>
                            ) : (
                              <div className="tableCellValue">-</div>
                            )}
                          </TableCell>
                        )}
                        <TableCell className="tableCellValue">
                          <div className="tableCellValue">
                            {item.fileStatus}
                          </div>
                        </TableCell>
                        <TableCell>
                          {isSuperAdmin() &&
                            checkIfFileIsInProcess(item.fileStatus) && (
                              <div
                                className={`tableCellValue ${
                                  checkFileDate(item.searchedOn)
                                    ? "statsLink"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (checkFileDate(item.searchedOn)) {
                                    dispatch({
                                      type: ActionTypes.SAVE_ACCOUNT_DATA,
                                      payload: {
                                        batchFileDetails: item,
                                      },
                                    });

                                    history.push("/account/review");
                                  }
                                }}
                              >
                                {!checkFileDate(item.searchedOn)
                                  ? "Expired"
                                  : item.fileStatus === "Expired"
                                  ? "Re-publish"
                                  : "Review"}
                              </div>
                            )}
                          {!isSuperAdmin() && (
                            <>
                              {checkFileStatus(item.fileStatus) && (
                                <AppMenu
                                  header={<div className="threeDotIcon"></div>}
                                  options={menuOptions}
                                  onSelect={(value) =>
                                    selectedMenu(value, item.id)
                                  }
                                />
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {loader && <TableLoader columnsNumber={isSuperAdmin() ? 6 : 7} />}
                  </TableBody>
                </TblContainer>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
