import React from "react";
import "./DppaModal.css";
import {
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

export default function DppaModal(props) {
  const { onClose } = props;

  return (
    <>
      <Modal
        scrollable={true}
        isOpen={true}
        toggle={() => onClose()}
        className="dppaModal"
      >
        <ModalHeader toggle={() => onClose()}>DPPA Agreement</ModalHeader>
        <ModalBody className="dppaModalDescription">
          <p>The customer type and corresponding DPPA are as follows:</p>
          <div className="dppaDescriptionTitle">Parking</div>
          <p>
            18 U.S.C. § 2721 (b)(3) For use in the normal course of business by
            a legitimate business or its agents, employees, or contractors, but
            only <br />
            (A) to verify the accuracy of personal information submitted by the
            individual to the business or its agents, employees, or contractors;
            and <br />
            (B) if such information as so submitted is not correct or is no
            longer correct, to obtain the correct information, but only for the
            purposes of preventing fraud by, pursuing legal remedies against, or
            recovering on a debt or security interest against, the individual.
          </p>
          <div className="dppaDescriptionTitle">Verify Fraud Debt</div>
          <p>
            18 U.S.C. § 2721 (b)(3) For use in the normal course of business by
            a legitimate business or its agents, employees, or contractors, but
            only <br />
            (A) to verify the accuracy of personal information submitted by the
            individual to the business or its agents, employees, or contractors;
            and <br />
            (B) if such information as so submitted is not correct or is no
            longer correct, to obtain the correct information, but only for the
            purposes of preventing fraud by, pursuing legal remedies against, or
            recovering on a debt or security interest against, the individual.
          </p>
          <div className="dppaDescriptionTitle">Verify Fraud Debt/Plate</div>
          <p>
            18 U.S.C. § 2721 (b)(3) For use in the normal course of business by
            a legitimate business or its agents, employees, or contractors, but
            only <br />
            (A) to verify the accuracy of personal information submitted by the
            individual to the business or its agents, employees, or contractors;
            and <br />
            (B) if such information as so submitted is not correct or is no
            longer correct, to obtain the correct information, but only for the
            purposes of preventing fraud by, pursuing legal remedies against, or
            recovering on a debt or security interest against, the individual.
          </p>
          <div className="dppaDescriptionTitle">Insurance Underwriting</div>
          <p>
            18 U.S.C. § 2721 (b)(6) For use by any insurer or insurance support
            organization, or by a self-insured entity, or its agents, employees,
            or contractors, in connection with claims investigation activities,
            antifraud activities, rating or underwriting.
          </p>
          <div className="dppaDescriptionTitle">Government</div>
          <p>
            18 U.S.C. § 2721 (b)(1) For use by any government agency, including
            any court or law enforcement agency, in carrying out its functions,
            or any private person or entity acting on behalf of a Federal,
            State, or local agency in carrying out its functions.
          </p>
          <div className="dppaDescriptionTitle">Private Toll</div>
          <p>
            18 U.S.C. § 2721 (b)(10) For use in connection with the operation of
            private toll transportation facilities.
          </p>
          <div className="dppaDescriptionTitle">Towing</div>
          <p>
            18 U.S.C. § 2721 (b)(7) For use in providing notice to the owners of
            towed or impounded vehicles.
          </p>
          <div className="dppaDescriptionTitle">
            Investigation for Litigation
          </div>
          <p>
            18 U.S.C. § 2721 (b)(4) For use in connection with any civil,
            criminal, administrative, or arbitral proceeding in any Federal,
            State, or local court or agency or before any self-regulatory body,
            including the service of process, investigation in anticipation of
            litigation, and the execution or enforcement of judgments and
            orders, or pursuant to an order of a Federal, State, or local court.
          </p>
          <p>
            By proceeding, I acknowledge my compliance with the above listed
            permissible use under 18 USC § 2721(b) as well as acknowledge the
            penalties as prescribed under{" "}
            <span id="DisabledAutoHideExample">18 U.S.C § 2723-2724.</span>
          <UncontrolledTooltip
						className="customDppaTooltip"
            autohide={false}
            target="DisabledAutoHideExample"
            placement="top"
          >
            <div className="dppaDescriptionTitle">Sec. 2723. - Penalties</div>
            <p>
              (a) Criminal Fine. - A person who knowingly violates this chapter
              shall be fined under this title. <br />
              (b) Violations by State Department of Motor Vehicles. - Any State
              department of motor vehicles that has a policy or practice of
              substantial noncompliance with this chapter shall be subject to a
              civil penalty imposed by the Attorney General of not more than
              $5,000 a day for each day of substantial noncompliance.
            </p>
            <div className="dppaDescriptionTitle">
              Sec. 2724. - Civil action
            </div>
            <p>
              (a) Cause of Action. - A person who knowingly obtains, discloses
              or uses personal information, from a motor vehicle record, for a
              purpose not permitted under this chapter shall be liable to the
              individual to whom the information pertains, who may bring a civil
              action in a United States district court. <br />
              (b) Remedies. - The court may award - <br />
              (1) actual damages, but not less than liquidated damages in the
              amount of $2,500; <br />
              (2) punitive damages upon proof of willful or reckless disregard
              of the law; <br />
              (3) reasonable attorneys' fees and other litigation costs
              reasonably incurred; and <br />
              (4) such other preliminary and equitable relief as the court
              determines to be appropriate
            </p>
          </UncontrolledTooltip>
          </p>
        </ModalBody>
      </Modal>
    </>
  );
}
