import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

export default function useTable(records, defaultHeaders, filterFn) {
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const [headers, setHeaders] = useState(defaultHeaders);

  const TblContainer = (props) => (
    <Table stickyHeader aria-label="sticky table">
      {props.children}
    </Table>
  );

  const TblHead = (props) => {

    if(props.order && props.orderBy){
      setOrder(props.order);
      setOrderBy(props.orderBy);
    }

    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "desc";
      setOrder(isAsc ? "asc" : "desc");
      setOrderBy(cellId);
      props.handleSort(cellId, isAsc ? "ASC" : "DESC");
    };

    return (
      <TableHead style={props.style}>
        <TableRow>
          {headers &&
            headers.length > 0 &&
            headers.map((headCell) => (
              <TableCell
                key={headCell.id}
                className="customTableHeaderCell"
                sortDirection={orderBy === headCell.id ? order : false}
                width={headCell.width ? headCell.width : "auto"}
                align={headCell.align ? headCell.align : "left"}
              >
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    active={(props.orderBy || orderBy) === headCell.id}
                    direction={ (props.orderBy || orderBy) === headCell.id ? props.order || order : "asc"}
                    onClick={() => {
                      handleSortRequest(headCell.id);
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  return {
    TblContainer,
    TblHead,
    setHeaders,
  };
}
