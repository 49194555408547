import React from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import useToken from "../../shared/hooks/useToken";
import { logoutUser } from "../../Utils/services/login.service";
import "./PasswordStatusScreens.css";

export default function PasswordStatusScreens(props) {
  const { page, errormsg } = props;

  const history = useHistory();

  const { token } = useToken()

  async function handleGotoLogin() {
    if (token) {
      await logoutUser();
    }
    sessionStorage.clear();
    history.push(`/login`);
  }

  return (
    <>
      {page === "success" ? (
        <div className="successScreenSection">
          <div className="successScreenIcon successScreenSuccessIcon"></div>
          <div className="successScreenMsg my-3">
            Click on below button to login with your new password.
          </div>
          <div className="text-center mt-5">
            <Button
              className="successScreenBtn"
              type="button"
              onClick={handleGotoLogin}
            >
              Go To Login
            </Button>
          </div>
        </div>
      ) : (
        <div className="successScreenSection">
          <div className="successScreenIcon successScreenErrorIcon"></div>
          <div className="successScreenMsg my-3">
            {errormsg ? errormsg : "Your token has expired"}
          </div>
        </div>
      )}
    </>
  );
}
