import baseUrl from "./interceptor";

export const getAccountDatasetsForSubscriptionUser = async () => {
    return await baseUrl.get(`subscriptions/search/my-datasets`);
};

export const getAllSubscriptionDatasetsForSuperAdmin = async () => {
    return await baseUrl.get(`subscriptions/admin/subscription-datasets`);
};

export const getSubscriptionLicensesForTenantAdmin = async () => {
    return await baseUrl.get(`subscriptions/admin/subscription-license/my-account`);
};

export const createSubscriptionDatasetForSuperAdmin = async (dataset) => {
    return await baseUrl.post(`subscriptions/admin/subscription-datasets`, {
        ...dataset,
    });
};

export const updateSubscriptionDatasetForSuperAdmin = async (dataset) => {
    return await baseUrl.put(`subscriptions/admin/subscription-datasets`, {
        ...dataset,
    });
};

export const getSubscriptionLicensesForTenantAccount = async (
    accountNumber
) => {
    return await baseUrl.get(
        `subscriptions/admin/subscription-license/${accountNumber}`
    );
};

export const updateSubscriptionLicensesForTenantAccount = async (
    accountNumber,
    subscriptionLicenses
) => {
    return await baseUrl.post(
        `subscriptions/admin/subscription-license`,
        {
            accountNumber: accountNumber,
            dataSetSeats: {...subscriptionLicenses},
        }
    );
};

export const bulkAssignDatasetsToUser = async (email, dataSetIds) => {
    return await baseUrl.post(`subscriptions/admin/subscription-license/my-account/bulk-assign-datasets`, {
        dataSetIds: [...dataSetIds],
        emails: [email],
    });
};

export const bulkAssignUsersToDataset = async (emails, dataSetId) => {
    return await baseUrl.post(`subscriptions/admin/subscription-license/my-account/bulk-assign-users`, {
        dataSetIds: [dataSetId],
        emails: [...emails],
    });
};

export const getSingleSubscriptionDataset = async (datasetId) => {
    return await baseUrl.get(
        `subscriptions/admin/subscription-datasets/${datasetId}`
    );
};

export const deleteSubscriptionDatasetBySuperAdmin = async (datasetId) => {
    return await baseUrl.delete(
        `subscriptions/admin/subscription-datasets/${datasetId}`
    );
};

export const generateUploadUrlsForChunks = async (
    datasetId,
    fileName,
    partCount
) => {
    return await baseUrl.post(
        `subscriptions/admin/subscription-datasets/upload-urls`,
        {
            datasetId,
            fileName,
            partCount,
        }
    );
};

export const finalizeDatasetUploadBySuperAdmin = async (
    datasetId,
    completedParts
) => {
    return await baseUrl.post(
        `subscriptions/admin/subscription-datasets/complete-upload`,
        {
            datasetId,
            completedParts,
        }
    );
};

export const getDatasetSeatsCountByAccountForSuperAdmin = async (datasetId) => {
    return baseUrl.get(
        "subscriptions/admin/subscription-license/dataset-seats-count-by-account/" + datasetId
    );
};

export const updateDatasetSeatsCountByAccountForSuperAdmin = async (
    datasetId,
    accounts
) => {
    return baseUrl.post("subscriptions/admin/subscription-license/dataset-seats-count-by-account/", {
        datasetId,
        accounts,
    });
};