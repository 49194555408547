import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Sidebarlayout from "./layouts/Sidebarlayout";
import Fulllayout from "./layouts/Fulllayout";
import Login from "./Pages/Login/Login";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import DownloadOnly from "./Pages/DownloadOnly/DownloadOnly";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./Utils/CommonStore";
import { AuthProvider } from "./Utils/AuthContext";
import useAutoLogout from "./Utils/useAutoLogout"; // Import the auto-logout hook

toast.configure({
  theme: "colored",
});

const AppContent = () => {
  useAutoLogout(); // Activate auto-logout
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/resetPassword">
        <ResetPassword />
      </Route>
      <Route path="/forgotPassword">
        <ForgotPassword />
      </Route>
      <Route path="/account">
        <Sidebarlayout />
      </Route>
      <Route path="/downloads">
        <DownloadOnly />
      </Route>
      <Route path="/">
        <Fulllayout />
      </Route>
    </Switch>
  );
};

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  );
}

export default App;
