import React, { useEffect, useState } from "react";
import "./BillAccount.css";
import { Button, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import useTable from "../../Components/useTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { TableBody, TableCell, TableRow } from "@mui/material";
import TableLoader from "../../Components/TableLoader/TableLoader";
import {
  downloadSearchListFile,
  getListOfSearches,
} from "../../Utils/services/billing.service";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { downloadFile } from "../../Utils/services/batchSearch.service";
import IconAppInput from "../../Components/IconAppInput/IconAppInput";
import searchIcon from "../../assets/icons/small_search.png";
import DateRangeFilter from "../../Components/DateRangeFilter/DateRangeFilter";
import { convertTZ, firstDayOfMonth, formatDate } from "../../shared/constants/constants";

const headCells = [
  {
    id: "plateNumber",
    label: "License Plate Number",
    width: "18%",
    disableSorting: true,
  },
  { id: "licenseState", label: "State", width: "10%", disableSorting: true },
  {
    id: "searchType",
    label: "Search Type",
    width: "10%",
  },
  {
    id: "searchedOn",
    label: "Searched On",
    width: "10%",
    disableSorting: true,
  },
  {
    id: "searchedBy",
    label: "Searched By",
    width: "10%",
    disableSorting: true,
  },
];

export default function BillAccount() {
  useDocumentTitle("Bill Account");

  const [accountInfo, setAccountInfo] = useState();
  const [startDate, setStartDate] = useState(firstDayOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [overallData, setOverallData] = useState([]);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalSearches, setTotalSearches] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { TblContainer, TblHead } = useTable(data, headCells);

  const history = useHistory();

  useEffect(() => {
    const accountDetails = {
      tenant: sessionStorage.getItem("tenantName"),
      accountId: sessionStorage.getItem("accountId"),
    };
    if (accountDetails.tenant && accountDetails.accountId) {
      setAccountInfo(accountDetails);
    } else {
      history.goBack();
    }

    return () => {
      sessionStorage.removeItem("tenantName");
      sessionStorage.removeItem("accountId");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.getElementsByClassName("billAccountTable")[0].scrollTo(0, 0);
    fetchSearchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, searchQuery, order, orderBy]);

  useEffect(() => {
    mapDataAsPerPageNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchSearchList = async (resetData = false) => {
    setloader(true);

    const accountId = accountInfo
      ? accountInfo.accountId
      : sessionStorage.getItem("accountId");

    try {
      let response = await getListOfSearches(
        {
          fromDate: formatDate(startDate),
          toDate: formatDate(endDate),
          sortDirection: order,
          sortBy: orderBy,
          pageNumber: page,
          size: 10,
          searchBy: searchQuery ? searchQuery : "",
        },
        accountId
      );

      let responseObject = response.data.responseObject;
      if (responseObject.code === 200) {
        const temp = Array.from([]);
        if (responseObject.body) {
          temp.push(...responseObject.body);
          setOverallData(temp);
          setTotalRecords(responseObject.body.length);
          temp.forEach(
            (item) => (item.searchedOn = convertTZ(item.searchedOn))
          );
          mapDataAsPerPageNumber(temp)
          if (!searchQuery || searchQuery === "") {
            setTotalSearches(responseObject.body.length);
          }
        }
        setloader(false);
        setError("");
      } else {
        setloader(false);
        setError(responseObject.message);
      }
    } catch (error) {
      setloader(false);
      setError(error.message);
    }
  };

  const mapDataAsPerPageNumber = (response) => {
    let allData = response ? response : overallData;
    // let startIndex = page * 10 ? page * 10 : page * 10;
    let allDataTemp = Array.from(allData);
    let currentPageData = allDataTemp.splice(page * 10, 10);
    let temp = Array.from(page ? data : []);
    temp.push(...currentPageData);
    setData(temp);
  }

  const handleSortRequest = (orderBy, order) => {
    document.getElementsByClassName("billAccountTable")[0].scrollTo(0, 0);
    setOrder(order);
    setPage(0);
    setOrderBy(orderBy);
    setTimeout(() => {
      setData([]);
    }, 10);
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  const checkIfValueIsNullOrEmpty = (value) => {
    if (value && value !== "") {
      return value;
    } else {
      return "-";
    }
  };

  const downloadSearchListExcelFile = async () => {
    setIsLoading(true);

    const accountId = accountInfo
      ? accountInfo.accountId
      : sessionStorage.getItem("accountId");

    try {
      let response = await downloadSearchListFile(
        {
          fromDate: formatDate(startDate),
          toDate: formatDate(endDate),
        },
        accountId
      );
      downloadFile(response.data, `${accountInfo?.tenant}_Search_list.csv`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  return (
    <>
      <AppLoader isLoading={isLoading} />

      <div className="container-fluid">
        <div className="pageTitle text-capitalize">{accountInfo?.tenant}</div>
        <DateRangeFilter
          className="mt-4"
          startDate={startDate}
          endDate={endDate}
          onChange={(data) => {
            setData([]);
            setTotalRecords(0);
            setPage(0);
            setSearchQuery("");
            setStartDate(data.startDate);
            setEndDate(data.endDate);
          }}
        />
        <Row className="billSearchHistoryHeader mt-3">
          <Col md={{ size: 12 }}>
            <div className="billSearchTotalCountTitle">
              Total Search Count : {totalSearches}
            </div>
          </Col>
        </Row>
        <Row className="billSearchHistoryHeader mt-4">
          <Col md={{ size: 6 }}>
            <div className="billSearchHistoryTitle">List of Searches</div>
          </Col>
          <Col md={{ size: 6 }} className="text-end">
            <div className="listSearchBar">
              <div className="searchInput billSearchInput">
                <IconAppInput
                  label="Search..."
                  value={searchQuery}
                  icon={searchIcon}
                  placeholder="Search by License Plate/State"
                  onEnter={(value) => {
                    if (searchQuery !== value) {
                      setData([]);
                      setTotalRecords(0);
                      setPage(0);
                      setSearchQuery(value);
                    }
                  }}
                />
              </div>
              <div>
                <Button
                  className="billExportBtn"
                  onClick={(e) => downloadSearchListExcelFile()}
                >
                  Export CSV
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="historySection">
          <Row className="searchHistoryList">
            <Col>
              {error !== "" && (
                <span className="text-danger errorMsg">{error}</span>
              )}
            </Col>
          </Row>
          <Row className="searchHistoryList">
            <Col>
              <InfiniteScroll
                className="billAccountTable"
                dataLength={data.length}
                next={() => addNewData()}
                hasMore={data.length < totalRecords}
                height={350}
                endMessage={
                  !loader &&
                  !data.length && (
                    <p className="text-secondary mt-3 text-center">
                      No Result Found
                    </p>
                  )
                }
              >
                <TblContainer>
                  <TblHead
                    handleSort={(orderBy, order) =>
                      handleSortRequest(orderBy, order)
                    }
                  />
                  <TableBody>
                    {data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className="licensePlateColumn billListPlateCell">
                          <div
                            className="licensePlateCell"
                            // onClick={() => {
                            //   sessionStorage.setItem("searchId", vehicle.id);
                            //   history.push("/account/search/result/false");
                            // }}
                          >
                            <div className="smallCarIcon"></div>
                            <div className="tableCellValue">
                              {checkIfValueIsNullOrEmpty(item.plateNumber)}
                            </div>
                          </div>
                          <div className="tableCellSubValue"></div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(item.licenseState)}
                          </div>
                          <div className="tableCellSubValue">&nbsp;</div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(item.searchType)}
                          </div>
                          <div className="tableCellSubValue">{}</div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(item.searchedOn)}
                          </div>
                          <div className="tableCellSubValue">{}</div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(item.searchedBy)}
                          </div>
                          <div className="tableCellSubValue">{}</div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {loader && <TableLoader columnsNumber={5} />}
                  </TableBody>
                </TblContainer>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
