/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import "./TermsofUse.css";

export default function TermsofUse(props) {
  const [privacyPolicyPage, setPrivacyPolicyPage] = useState(false);

  return (
    <>
      {privacyPolicyPage ? (
        <PrivacyPolicy backClick={() => setPrivacyPolicyPage(false)} />
      ) : (
        <div>
          <div
            className="backButtonIcon"
            onClick={() => props.backClick(true)}
          ></div>
          <div className="container py-4">
            <div className="text-center">
              <h1>WORLDWIDE INFORMATION, LLC.</h1>
              <h1>Terms of Use</h1>
            </div>
            <div className="termsOfUseSection mt-4">
              <p>WWI Terms of Use (Last Updated 03/07/2022)</p>
              <p className="fw-bold">
                YOUR USE OF THIS WEB SITE CONSTITUTES YOUR AGREEMENT TO BE BOUND
                BY THESE TERMS AND CONDITIONS OF USE.
              </p>
              <p>
                This web site www.worldwideinformation.com, including all its
                features and content (this “Web Site”) is a service made
                available by Worldwide Information, LLC (“Provider,” “we,” “us”
                or “our”) and all content, information, services, and software
                ordered or provided on or through this Web Site (“Content”) may
                be used solely under the following terms and conditions (“Terms
                of Use”).
              </p>
              <p>
                Age Requirements. The Web Site is intended for general adult
                audiences, and you represent that you are over the age of 18
                years of age and that you possess the legal right and ability to
                enter into this agreement and to use the Web Site in accordance
                with these Terms. If we have any reason to believe you are not
                18 or older, we may terminate your access to the Web Site.
              </p>
              <p>
                Web Site Limited License. As a user of this Web Site, you are
                granted a nonexclusive, nontransferable, revocable, limited
                license to access and use this Web Site and Content in
                accordance with these Terms of Use. Provider may terminate this
                license at any time for any reason. Except for the limited
                license set forth in these Terms of Use, Provider does not grant
                you any express or implied rights or licenses under any patents,
                trademarks, copyrights, or other proprietary or intellectual
                property rights.
              </p>
              <p className="text-danger">
                Privacy and Security Please review our Privacy and Security
                Policy at{" "}
                <a
                  className="privacyPolicyLink text-primary"
                  onClick={() => setPrivacyPolicyPage(true)}
                >
                  privacy and security policy
                </a>{" "}
                for a description of our privacy and security practices and an
                explanation of how we may use any personal information we
                collect on this Web Site.
              </p>
              <p>
                Patents, Copyrights and Trademarks. The technology and content
                provided on this Web Site are owned by or licensed to Provider
                and protected by United States and international intellectual
                property laws. Provider and its licensors retain all proprietary
                rights to that content and technology. The trademarks, service
                marks, trade names and logos (the “Marks”) appearing on this Web
                Site are Marks of Provider and/or its affiliates or licensors.
                You agree not to copy, reproduce, publish, transmit, distribute,
                perform, sell, create derivative works of, or in any way
                exploit, any of the Marks or the Content, in whole or in part,
                without Provider’s prior written consent.
              </p>
              <p>
                Limitations on Use. You may not use the Content to determine a
                consumer’s eligibility for: (a) credit or insurance for
                personal, family, or household purposes; (b) employment; (c) a
                government license or benefit; or (d) any purpose covered by the
                Fair Credit Reporting Act (15 U.S.C. §1681, et seq.). You may
                not decompile, reverse engineer, disassemble, rent, lease, loan,
                sell, sublicense, or create derivative works from this Web Site
                or the Content. Nor may you use any network monitoring or
                discovery software to determine the site architecture, or
                extract information about usage, individual identities or users.
                You may not use any robot, spider, other automatic software or
                device, or manual process to monitor or copy our Web Site or the
                Content without Provider’s prior written permission. You may not
                use this Web Site to transmit any false, misleading, fraudulent,
                or illegal communications. You may not use information obtained
                from the Web Site to transmit any commercial, advertising,
                promotional materials, “junk mail,” “spam,” “chain letters,”
                “pyramid schemes,” or any other form of solicitation, harass,
                offend, threaten, embarrass, or invade the privacy of any
                individual or entity, or violate any applicable law, regulation,
                or rule. You may not copy, modify, reproduce, republish,
                distribute, display, or transmit for commercial, non-profit, or
                public purposes all or any portion of this Web Site, except to
                the extent permitted above. You may not use or otherwise export
                or re-export this Web Site or any portion thereof, or the
                Content in violation of the export control laws and regulations
                of the United States of America. To the extent that you can
                upload, post or otherwise transmit content using this Web Site,
                you may not upload, post, or otherwise transmit any content that
                you do not have a right to transmit under any law or under
                contractual or fiduciary relationships (such as inside
                information, proprietary and confidential information learned or
                disclosed as part of employment relationships or under
                nondisclosure agreements). Any unauthorized use of this Web Site
                or its Content is prohibited.
              </p>
              <p>
                Advertisers. This Web Site may contain advertising and
                sponsorship. Advertisers and sponsors are responsible for
                ensuring that material submitted for inclusion on this Web Site
                is accurate and complies with applicable laws. Provider is not
                responsible for the illegality of, or any error or inaccuracy
                in, advertisers’ or sponsors’ materials, or for the acts or
                omissions of such advertisers or sponsors.
              </p>
              <p>
                Links to Third Party Sites. This Web Site may provide links to
                other third-party websites or resources. Provider makes no
                representations whatsoever about any other website which you may
                access through this Web Site. Because Provider has no control
                over such websites and resources, you acknowledge and agree that
                Provider is not responsible for the availability of such
                external sites or resources and is not responsible or liable for
                any content, advertising, products, services, or other materials
                on or available from such websites or resources. It is up to you
                to take precautions to ensure that whatever you select for your
                use is free of such items as viruses, worms, trojan horses and
                other items of a destructive nature. References on this Web Site
                to any names, marks, products, or services of any third parties
                or hypertext links to third party websites or information are
                provided solely as a convenience to you, and do not constitute
                or imply an endorsement, sponsorship, or recommendation of, or
                affiliation with the third party or its products and services.
                Provider makes no representation or warranty as to any
                third-party content, products, or services, and you agree that
                Provider shall not be responsible or liable, directly, or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such
                third-party content, products or services available on or
                through any such website or resource.
              </p>
              <p>
                Registration. Certain sections of this Web Site require you to
                register. If registration is requested, you agree to provide
                accurate and complete registration information. It is your
                responsibility to inform Provider of any changes to that
                information. Each registration is for a single individual only,
                unless specifically designated otherwise on the registration
                page. Provider does not permit (a) anyone other than you to use
                the sections requiring registration by using your name or
                password; or (b) access through a single name being made
                available to multiple users on a network or otherwise. You are
                responsible for preventing such unauthorized use. If you believe
                there has been unauthorized use, you must notify Provider
                immediately by using any of the methods described on our contact
                page.
              </p>
              <p>
                Accuracy of Data. No representations, neither express nor
                implied, are made as to the validity of any Content on the Web
                Site. While we have made every effort to supply you with current
                and valid data, there can be no guarantee as to any specific
                piece of data obtained by users.
              </p>
              <p>
                DISCLAIMER OF WARRANTIES. THIS WEB SITE AND THE CONTENT ARE
                PROVIDED ON AN “AS IS,” AND “AS AVAILABLE” BASIS. PROVIDER
                EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT. PROVIDER DISCLAIMS ALL RESPONSIBILITY FOR ANY
                LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING
                FROM, ARISING OUT OF OR ANY WAY RELATED TO (A) ANY ERRORS IN OR
                OMISSIONS FROM THIS WEB SITE AND THE CONTENT INCLUDING, BUT NOT
                LIMITED TO, TECHNICAL INACCURACIES AND TYPOGRAPHICAL ERRORS, (B)
                THIRD PARTY COMMUNICATIONS, (C) ANY THIRD PARTY WEB SITES OR
                CONTENT THEREIN DIRECTLY OR INDIRECTLY ACCESSED THROUGH LINKS IN
                THIS WEB SITE, INCLUDING BUT NOT LIMITED TO ANY ERRORS IN OR
                OMISSIONS THEREFROM, (D) THE UNAVAILABILITY OF THIS WEB SITE,
                THE CONTENT, OR ANY PORTION THEREOF, (E) YOUR USE OF THIS WEB
                SITE OR THE CONTENT, OR (F) YOUR USE OF ANY EQUIPMENT OR
                SOFTWARE IN CONNECTION WITH THIS WEB SITE OR THE CONTENT.
              </p>
              <p>
                PROVIDER DOES NOT REPRESENT OR WARRANT THAT THIS WEB SITE OR THE
                CONTENT WILL BE ERROR-FREE, FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS, OR THAT DEFECTS WILL BE CORRECTED OR THAT IT WILL
                ALWAYS BE ACCESSIBLE. PROVIDER DOES NOT WARRANT OR REPRESENT
                THAT THE CONTENT AVAILABLE ON OR THROUGH THIS WEB SITE WILL BE
                CORRECT, ACCURATE, TIMELY, OR OTHERWISE RELIABLE. PROVIDER MAY
                MAKE IMPROVEMENTS AND/OR CHANGES TO ITS FEATURES, FUNCTIONALITY
                OR CONTENT AT ANY TIME.
              </p>
              <p>
                WHEN USING THE WEB SITE, INFORMATION WILL BE TRANSMITTED OVER A
                MEDIUM WHICH IS BEYOND THE CONTROL AND JURISDICTION OF PROVIDER,
                ITS PARTNERS, ADVERTISERS, AND SPONSORS OR ANY OTHER THIRD PARTY
                MENTIONED ON THE WEB SITE. ACCORDINGLY, PROVIDER ASSUMES NO
                LIABILITY FOR OR RELATING TO THE DELAY, FAILURE, INTERRUPTION,
                OR CORRUPTION OF ANY DATA OR OTHER INFORMATION TRANSMITTED IN
                CONNECTION WITH USE OF THE WEB SITE.
              </p>
              <p>
                LIMITATION OF LIABILITY. PROVIDER SHALL NOT BE LIABLE FOR ANY
                LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING
                FROM YOUR USE OF THIS WEB SITE OR THE CONTENT. PROVIDER SHALL
                NOT BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL,
                PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER
                (INCLUDING, WITHOUT LIMITATION, ATTORNEYS’ FEES) IN ANY WAY DUE
                TO, RESULTING FROM, OR ARISING IN CONNECTION WITH THE USE OF OR
                INABILITY TO USE THIS WEB SITE OR THE CONTENT. TO THE EXTENT THE
                FOREGOING LIMITATION OF LIABILITY IS PROHIBITED OR FAILS OF ITS
                ESSENTIAL PURPOSE, PROVIDER’S SOLE OBLIGATION TO YOU FOR DAMAGES
                SHALL BE LIMITED TO $100.00.
              </p>
              <p>
                Indemnification. You understand that you are personally
                responsible for your behavior while on the Web Site or using the
                Content and agree to indemnify and hold Provider, and its
                subsidiaries, affiliates, business partners, distributors,
                agents, representatives and other authorized users, and their
                respective officers, directors, employees, and agents
                (collectively, the “Indemnified Parties”), harmless from and
                against any loss, damage, liability, cost, or expense of any
                kind (including attorneys’ fees) that the Indemnified Parties
                may incur in connection with any claim arising out of or related
                to your use of the Web Site or Content or your violation of
                either these Terms, applicable law or the rights of any third
                party.
              </p>
              <p>
                No Legal Advice. The information and services on this Web Site
                are not intended to and shall not be used as legal advice. No
                attorney-client relationship is formed. You use the content,
                information, and services on this Web Site at your own risk.
              </p>
              <p>
                Termination. You acknowledge and agree that Provider, in its
                sole discretion, may terminate your Web Site access if your
                conduct is found to be unlawful, inconsistent with, or in
                violation of, the letter or spirit of these Terms of Use, or for
                any other reason. Provider shall not be liable to you or any
                third party for termination of Web Site access. Should you
                object to any terms and conditions of these Terms of Use, or to
                any subsequent modifications thereto, your only recourse is to
                immediately discontinue use of the Web Site.
              </p>
              <p>
                Updates to Terms. Provider reserves the right to update or
                change these Terms of Use at any time by posting the most
                current version of the Terms of Use on the Web Site with a new”
                Last Updated” date shown. All such changes in the Terms of Use
                shall be effective from the” Last Updated” date shown. If we
                make any material changes to the Terms, we will also notify you
                by email (sent to the e-mail address specified in your account)
                or by means of a notice on this Web Site prior to the change
                becoming effective. Your continued use of the Web Site after we
                post any changes to the Terms of Use signifies your agreement to
                any such changes. If you do not agree to these Terms, you must
                discontinue using the Web Site. Note that special terms or rules
                may apply to some services offered on the Web Site, such as
                rules for promotions, applications or other features or
                activities. Any such terms are in addition to these Terms of
                Use.
              </p>
              <p>
                Governing Law. These Terms of Use shall be governed by and
                construed in accordance with the laws of the State of
                Massachusetts without application of conflict of laws rules.
              </p>
              <p>
                Resolution of any Dispute. In the event a dispute arises between
                you and Provider, we want to provide you with an efficient,
                neutral, and cost-effective means of resolving the dispute. Most
                customer concerns can be resolved quickly and to the customer’s
                satisfaction by calling our Customer Support Department at (888)
                273-3260, or by emailing us using our contact form located at{" "}
                <a
                  className="privacyPolicyLink text-primary"
                  href="https://www.worldwideinformation.com/Contact_Us"
                  target="_blank" rel="noreferrer"
                >
                  www.worldwideinformation.com/contactus
                </a>{" "}
                If, however, there is an issue that needs to be resolved, these
                Terms of Use describe how both of us will proceed:
              </p>
              <ol>
                <li>
                  <p>
                    Limitation of Legal Remedies. INSTEAD OF SUING IN COURT, YOU
                    AND PROVIDER EACH AGREE TO ARBITRATE DISPUTES ON A BILATERAL
                    (INDIVIDUAL) BASIS. ARBITRATION ON AN INDIVIDUAL BASIS MEANS
                    THAT YOU WILL NOT HAVE, AND YOU WAIVE, THE RIGHT FOR A JUDGE
                    OR JURY TO DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT PROCEED
                    IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY. You
                    and Provider agree that any dispute, controversy or claim
                    arising out of or relating to any aspect of our
                    relationship; the Content accessible from the Web Site; and
                    claims related to marketing efforts, including complaints
                    concerning unsolicited text messages, emails, and
                    telemarketing calls (“Claim(s)”) which cannot be settled
                    through the Customer Support Department refunding your
                    payments in full, shall be resolved by one arbitrator
                    through binding arbitration. This agreement to arbitrate is
                    intended to be broadly interpreted. It includes Claims based
                    in contract, tort, statute, fraud, misrepresentation, or any
                    other legal theory. The arbitrator’s decision and award are
                    final and binding, with some exceptions under the Federal
                    Arbitration Act, 9 U.S.C. 1, et seq., and judgment on the
                    award may be entered in any court with jurisdiction.
                  </p>
                </li>
                <li>
                  <p>
                    Class Action Waiver. We each agree that each of us are
                    waiving the right to a trial by jury and may bring claims
                    against the other only in an individual capacity and not in
                    a class action or representative proceeding. All
                    arbitrations under these Terms of Use shall be conducted on
                    an individual (and not a class-wide) basis, and an
                    arbitrator shall have no authority to award class-wide
                    relief. You acknowledge and agree that these Terms of Use
                    specifically prohibit you from commencing any legal
                    proceedings as a representative of others or joining in any
                    arbitration proceedings brought by any other person (“class
                    action waiver”).
                  </p>
                </li>
                <li>
                  <p>
                    Arbitration Procedures. A party who intends to seek
                    arbitration must first send to the other a written Notice of
                    Dispute describing the nature and basis of the Claim and
                    setting forth the specific relief sought. All Notices to
                    Provider shall be sent to the following address: Worldwide
                    Information, LLC 100 Cummings Center, Suite 331-A, Boston,
                    MA 01915. All notices to you will be sent to the email
                    provided in your account with Provider. Upon receipt of such
                    Notice, the other party shall have a sixty (60) day period
                    in which it may satisfy the Claim against it by fully curing
                    the Claim and/or providing all the relief requested in the
                    Notice, and/or entering a settlement to resolve the Claim to
                    the mutual satisfaction of the parties. After the expiration
                    of such sixty-day cure period, you or Provider may commence
                    an arbitration proceeding. The arbitration of any Claim
                    under this Agreement shall be referred to the American
                    Arbitration Association (“AAA”) under its rules and
                    procedures, including the AAA’s Commercial Dispute
                    Resolution Procedures and Supplementary Procedures for
                    Consumer-Related Disputes. These rules and procedures are
                    available by calling the AAA or by visiting its website at
                    www.adr.org. The arbitration of any Claim shall be conducted
                    in the State of Massachusetts, and for any non-frivolous
                    claim that does not exceed $10,000 Provider will: (1) pay
                    all costs of the arbitration; (2) if you prefer will conduct
                    the arbitration by telephone; and (3) will not seek
                    attorney’s fees in the event Provider prevails. Each party
                    shall pay the fees and costs of its own counsel, experts,
                    and witnesses.
                  </p>
                </li>
                <li>
                  <p>
                    Choice of Law. This arbitration agreement concerns a
                    transaction in interstate commerce, and therefore shall be
                    governed by the United States Federal Arbitration Act, 9
                    U.S.C. Â§ 1 et seq. The FAA’s provisions, not state law,
                    govern all questions of whether a dispute is subject to
                    arbitration.
                  </p>
                </li>
                <li>
                  <p>
                    Survival. This arbitration provision shall survive
                    termination of this agreement and the closing of your
                    account or membership.
                  </p>
                </li>
              </ol>
              <p>
                Severability. If any provision of these Terms of Use is declared
                or found to be unlawful, unenforceable, or void, such provision
                will be ineffective only to the extent that it is found
                unlawful, unenforceable, or void, and the remainder of the
                provision and all other provisions shall remain fully
                enforceable except that if for some reason this prohibition on
                class action waiver cannot be enforced, then this agreement to
                arbitrate will not apply.
              </p>
              <p>
                Contact Information. If you have any questions about these Terms
                of Use, or about the Content, information, or services on this
                Web Site, you may contact us using any of the methods described
                on our contact page at{" "}
                <a
                  className="privacyPolicyLink text-primary"
                  href="https://www.worldwideinformation.com/Contact_Us"
                  target="_blank" rel="noreferrer"
                >
                  www.worldwideinformation.com/contact/
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
