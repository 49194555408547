import baseUrl from "./interceptor";

export const getTenantDetails = (accountNumber) => {
  return baseUrl.get("auth/tenant/get/details", {
    params: {
      accountNumber: accountNumber ? accountNumber : null,
    },
  });
};

export const addNewTenant = (body) => {
  return baseUrl.post("auth/tenant/add", body);
};

export const editTenant = (body, accountId) => {
  return baseUrl.post("auth/tenant/edit", body);
};

export const getListOfTenants = (body) => {
  return baseUrl.post("auth/tenant/get/list", body);
};

export const getListOfUsers = (body) => {
  return baseUrl.post("auth/user/getAllUsers", body);
};

export const deleteAccount = (accountNumber) => {
  return baseUrl.delete("auth/tenant/delete", {
    params: {
      accountNumber,
    },
  });
};

export const resendInviteToAccount = (accountNumber) => {
  return baseUrl.post(
    "auth/tenant/resend/invite",
    {},
    {
      params: {
        accountNumber,
      },
    }
  );
};

export const fetchListOfState = (tenant) => {
  return baseUrl.get(
    "auth/tenant/get/state/list",
    {},
    {
      params: {
        tenant,
      },
    }
  );
};
//Tracers

export const fetchRestrictedListOfState = (tenant, account_no) => {
  return baseUrl.get(
    `auth/tenant/get/allowed/states?accountNumber=${account_no}`,
    {},
    {
      params: {
        tenant,
      },
    }
  );
};

export const getSingleSearchList = (body) => {
  return baseUrl.post("wwi/single/search/list", body);
};

export const fetchTracerSingleSearch = (
  tenant,
  licencePlateNumber,
  licensePlateState
) => {
  return baseUrl.get(
    `/wwi/single/search?licencePlateNumber=${licencePlateNumber}&licensePlateState=${licensePlateState}`,
    {},
    {
      params: {
        tenant,
      },
    }
  );
};

export const fetchExistingSearchRecod = (tenant, id, batchId) => {
  let url = `/wwi/detail/single/search?id=${id}`;
  if (batchId && batchId !== "") {
    url = url + `&batchId=${batchId}`;
  }
  return baseUrl.get(
    url,
    {},
    {
      params: {
        tenant,
      },
    }
  );
};

export const downloadSingleSearchCountFile = (data) => {
  return baseUrl.post("wwi/export/single/search", data);
};

export const createAccountKey = () => {
  return baseUrl.get("auth/tenant/generate/api-key");
};

export const getBatchFileSources = () => {
  return baseUrl.get("auth/tenant/get/providers");
};
