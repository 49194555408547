import * as actionTypes from '../ActionTypes/index';
import { storeInitialState } from '../Store';

const accountDataReducer = (state = storeInitialState.accountData, action) => {
    switch (action.type) {
        case actionTypes.SAVE_ACCOUNT_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }

        case actionTypes.CLEAR_ACCOUNT_DATA: {
            return {};
        }
    
        default:
            return {
                ...state
            };
    }
};

export default accountDataReducer;