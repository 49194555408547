import * as React from "react";
import "./DashboardNavTabs.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useHistory } from "react-router";
import { Auth } from "../../Utils/helpers";
import { privileges } from "../../shared/constants/constants";

export default function DashboardNavTabs(props) {
  const { active } = props;

  const history = useHistory();

  const handleChange = (event, newValue) => {
    if (newValue === "single") {
      history.push("/account/history");
    } else {
      history.push("/account/batchHistory");
    }
  };

  return (
    <Box sx={{ width: "100%" }} className="container-fluid">
      <Tabs
        className="customNavTab"
        value={active}
        onChange={handleChange}
        aria-label="secondary tabs example"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#673AB7",
            width: "172px !important" 
          },
        }}
      >
        <Tab
          value="single"
          onClick={(e) => handleChange(e, "single")}
          label="Single Search List"
        />
        {Auth.getUserPrivileges().includes(privileges.batch_search) && (
          <Tab
            value="batch"
            onClick={(e) => handleChange(e, "batch")}
            label="Batch Search List"
          />
        )}
      </Tabs>
    </Box>
  );
}
