import { ToastrService } from "../../Utils/services/tostr.service";
import dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as tz from "dayjs/plugin/timezone";

// api endpoints
export const apiDevEndpoint = process.env.REACT_APP_DEV_ENDPOINT;
export const apiProdEndpoint = process.env.REACT_APP_PROD_ENDPOINT.replace(
  "__HOST__",
  window.location.host
);

// Regex
export const passwordRegex = new RegExp(
  "^(?=.*[A-Z])(?=.*[_@.!/$%^#&*+-])[a-zA-Z0-9_@.!/$%^#&*+-d]{8,}$"
);
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
export const onlyText = /^[a-zA-Z- ]*$/;
export const websiteText = /^[a-zA-Z]*$/;

// Roles
export const roles = {
  super_admin: "SUPER_ADMIN",
  company_admin: "COMPANY_ADMIN",
  admin: "ADMIN",
  non_admin: "NON_ADMIN",
  subscription_only: "SUBSCRIPTION_ONLY",
  batch_admin: "BATCH_ADMIN",
  search_admin: "SEARCH_ADMIN",
};

// Privileges
export const privileges = {
  single_search: "SINGLE_SEARCH",
  batch_search: "BATCH_SEARCH",
  add_user: "ADD_USER",
  edit_user: "EDIT_USER",
  list_user: "LIST_USERS",
  delete_user: "DELETE_USERS",
  view_all_searches: "VIEW_ALL_SEARCHES",
  add_account: "ADD_ACCOUNT",
  list_account: "LIST_ACCOUNTS",
  billing: "BILLING",
  review_flow: "REVIEW_FLOW",
  my_profile: "MY_PROFILE",
  subscription_only: "SUBSCRIPTION_ONLY",
  search_and_subscription: "SEARCH_AND_SUBSCRIPTION",
  upload_files: "UPLOAD_FILES",
  subscription_search: "SUBSCRIPTION_SEARCH",
  list_subscription_datasets: "LIST_SUBSCRIPTION_DATASETS",
  add_subscription_dataset: "ADD_SUBSCRIPTION_DATASET",
};

// Max file size i.e. 15MB in bytes
// export const maxFileSize = 15728640;


// Max file size i.e. 2GB in bytes
export const maxFileSize = 2147483648;

// titles
export const titles = [
  { name: "OWNER" },
  { name: "CIO" },
  { name: "SALESPERSON" },
];

// status
export const Status = [
  { name: "Active", value: "Active" },
  { name: "InActive", value: "InActive" },
];

// user role
export const userRole = [
  { name: roles.admin, value: roles.admin },
  { name: roles.non_admin, value: roles.non_admin },
  // { name: roles.download_only.replace("_", " "), value: roles.download_only },
];

// phone number masking functions
export const createMask = (string) => {
  if (string.length < 4) {
    return string.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else if (string.length < 7) {
    return string.replace(/(\d{3})/, "($1) ");
  } else if (string.length < 10) {
    return string.replace(/(\d{3})(\d{3})/, "($1) $2-");
  }
  return string.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const destroyMask = (string) => {
  return string.replace(/\D/g, "");
};

// date formatter code
export const firstDayOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const formatDate = (date) => {
  let d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getFileNameFromHeader = (headerString) => {
  // console.log(headerString);
  let name = headerString.split(`"`)[1];
  // console.log(name);
  return name;
};

export const copyToClipboard = (value) => {
  if (value) {
    navigator.clipboard.writeText(value).then(
      function () {
        ToastrService.success("Copied to clipboard");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  }
};

// export const replaceUnderscoreWithSpace = (string) => {
//   return string.replace("_", " ");
// };

export const convertTZ = (date) => {
  dayjs.extend(utc);
  dayjs.extend(tz);

  return dayjs(date).utc("z").local().tz("EST").format("MM-DD-YYYY HH:mm");
};

export const defaultBatchSearchProvider = "TRACER";

// super admin sub-domain name constant
export const domainName = "qa-wwiliveportal";

// Account Types
export const accountTypesLists = [
  {
    id: 1,
    name: "SEARCH_ONLY",
  },
  {
    id: 2,
    name: "SUBSCRIPTION_ONLY",
  },
  {
    id: 3,
    name: "SEARCH_AND_SUBSCRIPTION",
  },
]
