import baseUrl, { baseUrlLogin } from "./interceptor";
import * as FileSaver from "file-saver";
import { Auth } from "../helpers";

export const batchSearchPreview = (selectedFile, isReviewedInput) => {
  let formData = new FormData();
  formData.append("file", selectedFile);

  return baseUrl.post("wwi/batch/search/preview", formData, {
    params: {
      isReviewedInput,
    },
  });
};

export const batchFileDownload = (data) => {
  return baseUrl.post("wwi/batch/search/preview/download", {
    batchSearchRecordsPreview: data,
  });
};

export const batchFileUploadForCA = (selectedFile) => {
  let formData = new FormData();
  formData.append("file", selectedFile);

  return baseUrl.post("wwi/batch/search", formData);
};

export const batchSearchHistory = (body) => {
  return baseUrl.post("wwi/batch/search/history", body);
};

export const batchFileRecordDetails = (id, body) => {
  return baseUrl.post(`wwi/batch/search/detail`, body, {
    params: {
      id,
    },
  });
};

export const downloadBatchResultFile = (id, subdomain) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
    subdomain,
  };

  return baseUrlLogin.get("wwi/batch/search/download", {
    headers,
    responseType: "blob",
    params: {
      id,
    },
  });
};

export const downloadFile = (
  data,
  filename,
  type = "data:attachment/csv;charset=utf-8,"
) => {
  let file = new File([data], filename, { type: "text/csv;charset=utf-8" });
  FileSaver.saveAs(file);

  return;
};

export const downloadExcelFile = (data, filename) => {
  let file = new File([data], filename, {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(file);

  return;
};
