import React from "react";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy(props) {
  return (
    <>
      <div className="backButtonIcon" onClick={() => props.backClick(true)}></div>
      <div className="container py-4">
        <div className="text-center">
          <h1>WORLDWIDE INFORMATION, LLC.</h1>
          <h1>PRIVACY AND SECURITY POLICY</h1>
        </div>
        <div className="privacyPolicySection mt-4">
          <h4>Privacy and Security Policy</h4>
          <p>
            We at WORLDWIDE INFORMATION, LLC recognize the importance of privacy
            and the sensitivity of personal information. We are committed to
            protecting personal information we hold in accordance with law. Our
            Privacy and Security Policy outlines how we manage personal
            information and safeguard privacy. By accessing our services or by
            accessing our website, or both, you acknowledge and fully understand
            this Privacy and Security Policy and freely consent to the
            information collection and use practices described in this Privacy
            and Security Policy.
          </p>
          <h4>Personal Information</h4>
          <p>
            We define “Personal information” as information about an
            identifiable individual, employee or subject. It also includes but
            is not limited to “Account” and or Credit Card/Payment information.
            It does not include the name, title or business address or telephone
            number of an employee of an organization.{" "}
          </p>
          <h4>Personal Information at WORLDWIDE INFORMATION</h4>
          <p>
            WORLDWIDE INFORMATION provides services to a wide range of clients.
            In doing so, often collect and use personal information. In
            addition, WORLDWIDE INFORMATION at times may send to individuals
            information and marketing materials concerning relevant developments
            in our various areas of service.
          </p>
          <h4>Collecting Personal Information</h4>
          <p>
            We collect personal information fairly and in accordance with the
            law. Generally, we collect personal information directly from
            individuals to which it relates. Such collection may be done at the
            start of our relationship with a client as well as during the course
            of our relationship.
          </p>
          <p>
            Occasionally we may obtain information about individuals from other
            sources including, for example:
          </p>
          <ul>
            <li>from a government agency or registry;</li>
            <li>other service providers who serve our clients</li>
            <li>The “Internet”</li>
          </ul>
          <h4>Consent</h4>
          <p>
            In most cases, if we collect, use, or disclose personal information,
            we will obtain consent from the individual to whom it relates.
            Sometimes we will ask for consent in writing, but in some cases, we
            may accept oral consent. Sometimes consent may be implied through
            conduct with us or the nature of our services. Should consent be
            withdrawn, it may impact on our ability to provide our services.
            Withdrawal of consent should always be done in writing.
          </p>
          <h4>Use of Personal Information</h4>
          <p>
            We use personal information to provide services, to administer our
            client databases, and occasionally to include individuals in our
            information distribution and marketing activities. The preceding
            includes the following uses: 1) we may share certain aggregated
            demographic information with our business partners regarding the
            users of our websites; 2) we may share certain personally
            identifiable consumer information with our business partners
            regarding applicants and other consumers; and 3) we may from time to
            time communicate service offerings to applicants and other consumers
            via our co-marketing partners.{" "}
          </p>
          <h4>Disclosure of Personal Information</h4>
          <p>
            Subject to the above provision titled “Use of Personal Information”,
            we will not disclose personal information we collect from you to
            third parties without your permission except to the extent necessary
            including:
          </p>
          <ul>
            <li>to fulfill your requests for services;</li>
            <li>
              when the relevant individual has consented to the disclosure;
            </li>
            <li>
              when we are required by law to do so, or required by a warrant, a
              subpoena, or rules of court to do so;
            </li>
            <li>
              when the services we are providing requires us to give a client’s
              personal information to third parties, his or her consent will be
              implied, unless he or she tells us otherwise;
            </li>
            <li>
              where it is necessary to protect ourselves from liability or to
              collect fees or disbursements;
            </li>
            <li>
              where the disclosure is in connection with a merger, acquisition,
              or liquidation of our company; or
            </li>
            <li>
              if we engage a third party to provide administrative services to
              us (like computer back-up services, archival file storage, or
              insurance) and the third party is bound by obligations regarding
              privacy which are consistent with this Policy.
            </li>
          </ul>
          <h4>Updating Information</h4>
          <p>
            Since we use personal information to provide our services, it is
            important that the information be accurate and up-to-date. If during
            the course of a service relationship, any personal information
            changes, we ask that clients please inform us so that we can make
            any necessary changes. We may also periodically inquire of clients
            whether their personal information is accurate and up-to-date.
          </p>
          <h4>Securing Personal and Other Information</h4>
          <p>
            WORLDWIDE INFORMATION, LLC takes precautions to ensure that personal
            information is kept safe from loss, unauthorized access,
            modification or disclosure. Among the steps taken to protect
            personal information are:
          </p>
          <ul>
            <li>premises security;</li>
            <li>
              deploying technological safeguards including security software and
              a variety of firewalls to prevent unauthorized computer access or
              “hacking” both internally and externally;
            </li>
            <li>internal password and security policies;.</li>
            <li>
              we limit the storage of information we maintain regarding subjects
              to the most minimal time frame necessary to complete a “Batch
              process” or as otherwise directed by law;
            </li>
          </ul>
          <p>
            WORLDWIDE INFORMATION implements and maintains a comprehensive
            information security program designed to ensure the security and
            confidentiality of sensitive data, including but not limited to
            subscriber codes, security digits, passwords and other PII
            information, and to protect against anticipated threats and
            unauthorized access to such information. At any time “Information”
            or Data” is not being utilized and or processed in the presence of
            an authorized member of our team, it is stored behind locked access
            areas to limit and minimize potential risk exposure.
          </p>
          <h4>Disposal of Personal Information</h4>
          <p>
            WORLDWIDE INFORMATION is committed to the proper disposal of
            personal information by taking reasonable measures to protect
            against unauthorized access to or use of the information in
            connection with its disposal. WORLDWIDE INFORMATION requires the
            pulverizing or shredding of papers containing personal information
            so that the information cannot practicably be read or reconstructed,
            and the destruction or erasure of electronic media containing
            personal information so that the information cannot practicably be
            read or reconstructed. We only allow approved personnel access to
            this internal confidential information.{" "}
          </p>
          <h4>Monitoring regarding Certain Services</h4>
          <p>
            WORLDWIDE INFORMATION may from time to time monitor compliance with
            established standards by subscribers to certain WORLDWIDE
            INFORMATION services and to ensure that certain information is being
            used only for permissible purposes allowed under the relevant
            agreement for service. WORLDWIDE INFORMATION may immediately cease
            providing all or any such services to any subscriber that is not in
            full compliance with the preceding as well as applicable laws and
            standards. WORLDWIDE INFORMATION or certain business allies may also
            conduct periodic compliance audits or to review an appropriate
            sample of authorizations, as well as to confirm relevant consent(s).
            Certain data or services may not be provided to the relevant
            subscriber in the event of non-compliance with the preceding.
          </p>
          <h4>Due Diligence regarding Certain Services</h4>
          <p>
            In order to ensure that certain data is safeguarded and only
            provided to companies/approved users that have been appropriately
            verified and credentialed, WORLDWIDE INFORMATION may undertake due
            diligence on any subscriber of relevant services prior to granting
            access to such information. Such due diligence may include credit
            reports. If a credit report is not available or consent to access a
            credit report is not provided, certain data or services may not be
            provided to the relevant subscriber/user. In addition, WORLDWIDE
            INFORMATION will require a subscriber to sign a service agreement
            prior to certain services being provided to the relevant
            subscriber/user.
          </p>
          <h4>Security Utilizing/Matching Certain Numbers</h4>
          <p>
            In order to protect sensitive data from the risk of identity theft
            and other possible fraud, WORLDWIDE INFORMATION may insist that
            clients facilitate the matching (for verification purposes) of
            certain numbers or partial numbers which are personal to the client,
            and relevant services may only be delivered upon relevant matches
            occurring.
          </p>
          <h4>E-mail</h4>
          <p>
            E-mail is not an entirely secure medium, and appropriate precautions
            should be taken when contacting us to send personal or confidential
            information. We will NEVER ask for “Credit Card” payment information
            via email so please do not ever send it to us via email delivery.
          </p>
          <h4>Correcting and Accessing Personal Information</h4>
          <p>
            You are entitled to ensure that personally identifiable information
            in our file is correct and current. You may review this information
            by contacting us or by using the methods listed on our website.
          </p>
          <p>
            An individual may ask for access to personal information we hold
            about him or her at any time.
          </p>
          <p>
            Detailed requests which require archive or other retrieval costs may
            be subject to our reasonable fees and disbursements (including any
            actual out-of-pocket expenses).
          </p>
          <p>
            An individual’s rights to access his or her personal information are
            not absolute. There are a number of situations where we may deny
            access. If we deny your request for access to, or refuse a request
            to correct personal information, we will provide a reason or reasons
            for doing so.
          </p>
          <h4>Web Site</h4>
          <p>
            Our website may contain links to other sites, which are not governed
            by this Policy.
          </p>
          <p>
            On our website, like most other commercial websites, we monitor
            traffic patterns, site usage and related site information in order
            to optimize our web service. We may provide aggregated information
            to third parties, but these statistics do not include any
            identifiable personal information.
          </p>
          <p>
            When you visit our website, we may track information to administer
            the site and analyze its usage. The following are a few examples of
            information we may track: your Internet protocol address; the kind
            of browser or computer you use; the number of links you click within
            the site; the Province, state, county, or jurisdiction from which
            you accessed the site; the date and time of your visit; the name of
            your Internet service provider; the web page you linked to our site
            from; pages you viewed on our site, and how long you visited for.{" "}
          </p>
          <p>
            In some cases we may use cookies to personalize or enhance your user
            experience. One of the primary purposes of cookies is to provide a
            convenience feature to save you time. Hence, this simplifies the
            process of delivering relevant content and eases site navigation by
            providing and saving your preferences and login information as well
            as providing personalized functionality.
          </p>
          <p>
            You have the ability to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies. If you should decide to reject
            cookies by changing your browser settings then be aware that this
            may disable some of the functionality on our website.
          </p>
          <h4>Requests for Access</h4>
          <p>
            Any questions or any access requests regarding personal information
            should be directed to the professional with whom individuals
            normally deal in writing, and forwarded onto our Chief Technology
            Officer at:
          </p>
          <p>
            Chief Technology Officer <br />
            Worldwide Information LLC <br />
            100 Cummings Ctr Ste 240-C <br />
            Beverly, MA 01915
          </p>
          <p>by email to Support@worldwideinformation.com</p>
          <p>by phone to (978) 712-2001 Ext. 233</p>
          <h4>Changes to this Privacy and Security Policy</h4>
          <p>
            WORLDWIDE INFORMATION, LLC reviews all of its policies and
            procedures periodically. We reserve the right to change this Privacy
            and Security Policy from time to time without notice
          </p>
        </div>
      </div>
    </>
  );
}
