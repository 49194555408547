import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Col, Row } from "reactstrap";
import AppAlert from "../../Components/AppAlert/AppAlert";
import batchPreviewAlert from "../../assets/icons/alert.svg";
import "./BatchFilePreview.css";
import useTable from "../../Components/useTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { TableBody, TableCell, TableRow } from "@mui/material";
import TableLoader from "../../Components/TableLoader/TableLoader";
import {
  batchFileDownload,
  batchFileUploadForCA,
  batchSearchPreview,
  downloadFile,
} from "../../Utils/services/batchSearch.service";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { ToastrService } from "../../Utils/services/tostr.service";
import { roles } from "../../shared/constants/constants";
import { Auth } from "../../Utils/helpers";
import { batchSearchRecords } from "../../Utils/services/review.service";

const style = {
  margin: "40px auto 0 auto",
  height: "90%",
  width: "94%",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  overflow: "scroll",
};

const headCells = [
  {
    id: "licenseNumber",
    label: "License Plate Number",
    width: "40%",
    disableSorting: true,
  },
  { id: "state", label: "State", width: "20%", disableSorting: true },
  {
    id: "isFormatCorrect",
    label: "Is Format Correct",
    width: "40%",
    disableSorting: true,
  },
];

export default function BatchFilePreview(props) {
  const { onClose, file, batchId, accountId } = props;

  const [currentUserRole] = useState(Auth.getUserRole());
  const [data, setData] = useState([]);
  const [fileDataIsInvalid, setFileDataIsInvalid] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { TblContainer, TblHead } = useTable(data, headCells);

  useEffect(() => {
    if (file) {
      handleBatchPreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortRequest = (orderBy, order) => {
    setData([]);
    setPage(0);
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  const handleClose = (flag = false, reason) => {
    onClose(flag);
  };

  const handleBatchPreview = async () => {
    setloader(true);
    try {
      let response = await batchSearchPreview(file, isSuperAdmin());

      if (response.code === 200) {
        const temp = Array.from(data);
        if (response.body) {
          temp.push(...response.body.batchSearchRecordsPreview);
          setData(temp);
          setTotalRecords(response.body.batchSearchRecordsPreview.length);
          setFileDataIsInvalid(response.body.error);
        }
        setloader(false);
      } else {
        setloader(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setError(error.message);
    }
  };

  const downloadErrorFile = async () => {
    setIsLoading(true);
    try {
      let response = await batchFileDownload(data);

      downloadFile(response, "batch_preview_file.csv");
      setIsLoading(false);
      handleClose(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleBatchFileUpload = async () => {
    setIsLoading(true);
    try {
      let response = await batchFileUploadForCA(file);

      if (response.code === 200) {
        // ToastrService.success(response.message);
        setIsLoading(false);
        handleClose(true);
      } else {
        ToastrService.error(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleBatchSearchForSA = () => {
    setIsLoading(true);
    try {
      batchSearchRecords(file, batchId, accountId);

      setTimeout(() => {
        ToastrService.success("Your file will be processed shortly");
        setIsLoading(false);
        handleClose(true);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const isSuperAdmin = () => {
    return currentUserRole.includes(roles.super_admin) ? true : false;
  };

  return (
    <>
      <Modal
        open={true}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={true}
      >
        <Box sx={style}>
          <AppLoader isLoading={isLoading} />
          <AppAlert
            className="batchPreviewAlert"
            icon={batchPreviewAlert}
            open={true}
            message="Preview only reflect the License Plate and State. Batch file consists of all the column."
            secondaryMessage="You can continue with your search or download the error file and re-upload after corrections highlighted in the file."
            // link={fileDataIsInvalid ? "download file" : ""}
            // linkClicked={downloadErrorFile}
          />
          <Box sx={{ pl: 4, pr: 4, pb: 3 }}>
            <div className="batchPreviewTitleSection">
              <div className="batchPreviewTitle">Batch Search Preview</div>
              {fileDataIsInvalid && (
                <div className="batchPreviewTitleBtn">
                  <Button
                    className="batchPreviewBtn"
                    onClick={() => downloadErrorFile()}
                    disabled={isLoading}
                  >
                    Download Error File
                  </Button>
                </div>
              )}
            </div>
            <div id="parent-modal-description" className="batchPreviewList">
              <Row className="batchResultList">
                <Col>
                  {error !== "" && (
                    <span className="text-danger errorMsg">{error}</span>
                  )}
                </Col>
              </Row>
              <Row className="batchResultList">
                <Col>
                  <InfiniteScroll
                    dataLength={data.length}
                    next={() => addNewData()}
                    hasMore={data.length < totalRecords}
                    height={398}
                    endMessage={
                      !loader &&
                      !data.length && (
                        <p className="text-secondary mt-3 text-center">
                          No Result Found
                        </p>
                      )
                    }
                  >
                    <TblContainer>
                      <TblHead
                        handleSort={(orderBy, order) =>
                          handleSortRequest(orderBy, order)
                        }
                      />
                      <TableBody>
                        {data.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <div className="batchPreviewPlateCell">
                                <div className="smallFileIcon"></div>
                                <div className="batchPreviewPlateText">
                                  {item.plateNumber !== ""
                                    ? item.plateNumber
                                    : "-"}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tableCellValue">
                                {item.licenseState !== ""
                                  ? item.licenseState
                                  : "-"}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className={`${
                                  item.isValid === "YES"
                                    ? "greenText"
                                    : "redText"
                                }`}
                              >
                                {item.isValid}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                        {loader && <TableLoader columnsNumber={3} />}
                      </TableBody>
                    </TblContainer>
                  </InfiniteScroll>
                </Col>
              </Row>
            </div>
            <div className="batchPreviewBtns mt-3">
              <Button
                className="batchPreviewBtn"
                onClick={() => {
                  if (isSuperAdmin()) {
                    handleBatchSearchForSA();
                  } else {
                    handleBatchFileUpload();
                  }
                }}
                disabled={!data.length || isLoading}
              >
                Continue
              </Button>
              <Button
                className="batchPreviewBtn cancelBtn"
                onClick={() => handleClose(false)}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
