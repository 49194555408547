import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Col, Row, Spinner } from "reactstrap";
import {
  Stack,
} from "@mui/material";
import { maxFileSize } from "../../shared/constants/constants";
import { ToastrService } from "../../Utils/services/tostr.service";
import {
  checkFileStatus,
  deleteFileFromList,
  uploadFile,
  uploadFileOnS3Bucket,
} from "../../Utils/services/downloads.service";
import "./UploadFilesModel.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 650 ? 600 : 350,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  outline: "none",
  boxShadow: 24,
  p: 4,
};

const UploadFilesModel = (props) => {
  const {
    openModel,
    title,
    closeModel,
    isUpload,
    accountId,
    isDetele,
    fileId,
    getListOfDownloads,
    isLoading,
    setIsLoading,
    setFileName,
    setStatusApiCallRepeat,
    file,
    setFile,
    apiCallRepeated,
  } = props;

  const [error, setError] = useState(false);

  const handleFile = async (files, onDropHandle) => {
    if (onDropHandle) {
      setFile(files);
    } else {
      if (files.length) {
        if (
          files[0].type === "application/x-ms-dos-executable" ||
          files[0].type === "application/x-msdownload" ||
          files[0].type === "application/octet-stream"
        ) {
          files[0].size < maxFileSize && setFile(files[0]);
        } else {
          ToastrService.error(
            "Please make sure that the file format is exe and the size is not more than 2GB."
          );
        }
      }
    }
  };

  const handleFileDrop = (ev) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          if (
            ev.dataTransfer.items[i].type ===
            "application/x-ms-dos-executable" ||
            ev.dataTransfer.items[i].type === "application/x-msdownload" ||
            ev.dataTransfer.items[i].type === "application/octet-stream"
          ) {
            const file = ev.dataTransfer.items[i].getAsFile();
            if (file.size > maxFileSize) {
              ToastrService.error(
                "Please make sure that the file format is exe and the size is not more than 2GB."
              );
              break;
            }
            handleFile(file, true);
            break;
          } else {
            ToastrService.error(
              "Please make sure that the file format is exe and the size is not more than 2GB."
            );
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files;
        handleFile(file);
      }
    }
  };

  // Upload file .EXE
  const handleFileUpload = async () => {
    if (isUpload) {
      // Listen for upload button click event
      if (!file) {
        setError(true);
        ToastrService.error("Please select a file to upload & user.");
        return;
      } else {
        try {
          setIsLoading(true);

          const presignedUrl = await uploadFileOnS3Bucket(
            file?.name
          );

          if (presignedUrl.code === 200) {
            const url = presignedUrl?.body;

            const result = await fetch(url, {
              method: "PUT",
              headers: {
                AllowedOrigins: "*",
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/octet-stream",
              },
              body: file,
            });

            const finalResult = result.ok;

            if (finalResult) {
              const response = await uploadFile(
                file?.name,
                null
              );
              if (response.code === 200) {
                ToastrService.success(`${response?.message}`);
                getListOfDownloads(accountId);
                closeModel(false);
                setFile("");
                setIsLoading(false);

                const fileStatusResult = await checkFileStatus(file?.name);

                if (fileStatusResult.body === "IN_PROGRESS") {
                  setStatusApiCallRepeat(true);
                  apiCallRepeated();
                } else if (fileStatusResult.body === "INFECTED") {
                  getListOfDownloads(accountId);
                  ToastrService.error(`File ${file?.name} is corrupted, it will be deleted by system`);
                } else if (fileStatusResult.body === "CLEAN") {
                  getListOfDownloads(accountId);
                }
              } else {
                ToastrService.error(`${response?.message}`);
                setIsLoading(false);
              }
            }
          } else {
            presignedUrl.code === 400 &&
              ToastrService.error(`${presignedUrl.message}`);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          ToastrService.error(`Something went's wrong while file uploading.`);
        }
      }
    }
  };

  const deleteFile = async () => {
    setIsLoading(true);
    const response = await deleteFileFromList(fileId);

    if (response.code === 200) {
      ToastrService.success(`${response?.message}`);
      getListOfDownloads(accountId);
      closeModel(false);
      setIsLoading(false);
      setFileName("");
    } else {
      ToastrService.error(`${response?.message}`);
      setIsLoading(false);
      setFileName("");
    }
  };

  const handleCancleForUploadUpdate = () => {
    closeModel(false);
    setError(false);
    isUpload && setFile("");
  };

  const handleCancleForDelete = () => {
    closeModel(false);
    setError(false);
  };

  return (
    <Modal
      open={openModel}
      disableEscapeKeyDown={true}
      onClose={() => closeModel(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <>
          <Stack
            sx={{
              fontSize: "20px",
              color: "#673ab7",
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
          >
            {title}
          </Stack>
          {isUpload && (
            <>
              <Row
                style={{
                  margin: "15px 0",
                }}
              >
                <Col
                  xs={{ size: 10 }}
                  id="draggableFileContainer"
                  className="batchSearchContainer"
                  onClick={(e) => {
                    if (!file?.name) {
                      document.getElementById("batchFileInput").click();
                    }
                  }}
                  style={{
                    height: "150px",
                    border: !file?.name && error && "4px dotted red",
                    opacity: file?.name ? 0.5 : 1,
                    cursor: file?.name ? "not-allowed" : "pointer",
                  }}
                  onDrop={handleFileDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "150px",
                      width: "100%",
                    }}
                  >
                    <div className="batchSearchDivTitle">
                      <div className="smallUploadIconBatchSearch"></div>
                      <div className="batchSearchText">
                        <div className="batchSearchTextTitle">
                          Want to do a Upload EXE File?
                        </div>
                        <div className="batchSearchSubTitle">
                          Upload .exe file of max 2GB
                        </div>
                        {!file?.name && error && (
                          <p
                            className="batchSearchTextTitle"
                            style={{ color: "red", fontSize: "14px" }}
                          >
                            Please select file!
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    accept=".exe"
                    id="batchFileInput"
                    onClick={(e) => (e.currentTarget.value = "")}
                    onChange={(e) => {
                      handleFile(e.target.files);
                    }}
                    hidden
                  />
                </Col>
              </Row>
              {file?.name && (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "5px 0",
                  }}
                >
                  {file?.name && (
                    <Col
                      md={{ size: 10 }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        title={file?.name}
                        className="text-truncate"
                        style={{ maxWidth: "90%" }}
                      >
                        {file?.name}
                      </div>
                      <button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#673ab7",
                          color: "#fff",
                        }}
                        onClick={() => setFile("")}
                        disabled={isLoading}
                      >
                        &times;
                      </button>
                    </Col>
                  )}
                </Row>
              )}
            </>
          )}
          {isDetele && (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "15px 0",
                gap: 8,
              }}
            >
              <Col xs={{ size: 4 }}>
                <Button style={{ width: "100%" }} onClick={deleteFile}>
                  {isLoading ? (
                    <Spinner style={{ height: "1rem", width: "1rem" }} />
                  ) : (
                    "Delete File"
                  )}
                </Button>
              </Col>
              <Col xs={{ size: 4 }}>
                <Button
                  style={{ width: "100%" }}
                  onClick={handleCancleForDelete}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
          {isUpload && (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <Col md={{ size: 4 }}>
                <Button
                  style={{ width: "100%" }}
                  onClick={handleFileUpload}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner style={{ height: "1rem", width: "1rem" }} />
                  ) : isUpload && (
                    "Upload"
                  )}
                </Button>
              </Col>
              <Col md={{ size: 4 }}>
                <Button
                  style={{
                    width: "100%",
                    cursor: isLoading ? "not-allowed" : "pointer",
                  }}
                  onClick={handleCancleForUploadUpdate}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </>
      </Box>
    </Modal>
  );
};

export default UploadFilesModel;
