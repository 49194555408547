import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "../Components/Header/Header";
import Sidebar, {checkUserPrivileges} from "../Components/Sidebar/Sidebar";
import AccessDenied from "../Pages/AccessDenied/AccessDenied";
import NotFound from "../Pages/NotFound/NotFound";
import AuthenticatedRoute from "../routes/AuthenticatedRoute";
import DashboardRoutes from "../routes/DashboardRoutes";
import { roles } from "../shared/constants/constants";
import useSubDomainName from "../shared/hooks/useSubDomainName";
import useToken from "../shared/hooks/useToken";
import { Auth } from "../Utils/helpers";
import DownloadOnly from "../Pages/DownloadOnly/DownloadOnly";
import SubscriptionSearch from "../Pages/SubscriptionSearch/SubscriptionSearch";
import SubscriptionDataset from "../Pages/SubscriptionDataset/SubscriptionDataset";

const Sidebarlayout = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [currentUserRole] = useState(Auth.getUserRole());

  const { subDomainName, setSubDomainName } = useSubDomainName();

  useEffect(() => {
    saveSubDomainName();
    const updateDimensions = () => {
      let element = document.getElementById("main-wrapper");
      setWidth(window.innerWidth);
      if (element) {
        if (width <= 764) {
          element.classList.add("show-mobile-sidebars");
        } else {
          element.classList.remove("show-mobile-sidebars");
        }
      }
    };
    if (document.readyState === "complete") {
      updateDimensions();
    }
    window.addEventListener("resize", updateDimensions.bind(this));
    window.addEventListener("load", updateDimensions.bind(this));
    return () => {
      window.removeEventListener("load", updateDimensions.bind(this));
      window.removeEventListener("resize", updateDimensions.bind(this));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const saveSubDomainName = () => {
    if (subDomainName === "") {
      setSubDomainName();
    }
  };

  const { token } = useToken();

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <div id="main-wrapper" className="">
      <div className="headerPanel">
        <Header />
      </div>

      <div className="sidebarPanel">
        <Sidebar />
      </div>

      <div className="page-wrapper d-block">
        <div className="page-content">
          <Switch>
            {AuthenticatedRoute.map((prop) => {
              if (prop.redirect) {
                return (
                  <Redirect
                    exact
                    from={prop.path}
                    to={
                      currentUserRole &&
                      currentUserRole.includes(roles.super_admin)
                        ? "/account/list"
                        : prop.pathTo
                    }
                    key={prop.id}
                  />
                );
              } else {
                if (!prop.children) {
                  return (
                    <Route
                      exact
                      path={"/account" + prop.path}
                      component={
                        Auth.getUserRole() == "SUPER_ADMIN" &&
                        prop.component == DownloadOnly
                          ? ""
                          : checkUserPrivileges(prop?.privilege)
                          ? prop.component
                          : AccessDenied
                      }
                      key={prop.id}
                    />
                  );
                } else {
                  return prop.children.map((item) =>
                    (checkUserPrivileges(item?.privilege)) ? (
                      <Route
                        exact
                        path={"/account" + item.path}
                        component={item.component}
                        key={item.id}
                      />
                    ) : (
                      <Route
                        exact
                        path={"/account" + item.path}
                        component={AccessDenied}
                        key={item.id}
                      />
                    )
                  );
                }
              }
            })}
            {DashboardRoutes.map((item) => {
              if (
                Auth.getUserPrivileges() &&
                Auth.getUserPrivileges().includes(item.privilege)
              ) {
                return (
                  <Route
                    exact
                    key={item.id}
                    path={item.path}
                    component={item.component}
                  />
                );
              } else {
                return (
                  <Route
                    exact
                    path={"/account" + item.path}
                    component={AccessDenied}
                    key={item.id}
                  />
                );
              }
            })}
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
export default Sidebarlayout;
