import React from "react";
import { Col, Row } from "reactstrap";
import AppDatePicker from "../AppDatePicker/AppDatePicker";
import "./DateRangeFilter.css";

export default function DateRangeFilter(props) {
  const { className, startDate, endDate, onChange } = props;

  const handleChange = (date, startDateChanged = true) => {
      let obj = {
          startDate: startDateChanged ? date : startDate,
          endDate: !startDateChanged ? date : endDate
      }
      onChange(obj);
  };

  return (
    <>
      <div className={`dateFilterSection ${className ? className : ""}`}>
        <Row>
          <Col md={{ size: 3 }}>
            <AppDatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => {
                if (startDate !== date) {
                  handleChange(date);
                }
              }}
            />
          </Col>
          <Col md={{ size: 3 }} className="endDatePicker">
            <AppDatePicker
              label="End Date"
              value={endDate}
              minDate={startDate}
              onChange={(date) => {
                if (endDate !== date) {
                  handleChange(date, false);
                }
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
