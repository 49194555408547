import { useState } from "react";

export default function useToken() {

  const getToken = () => {
    const tokenString = sessionStorage.getItem("AUTH_TOKEN");
    return tokenString && tokenString !== "" ? tokenString : null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    sessionStorage.setItem("AUTH_TOKEN", userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
