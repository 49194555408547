import React, { useEffect, useState } from "react";
import { Collapse, Nav, NavItem, NavLink } from "reactstrap";
import "./Sidebar.css";
import AuthenticatedRoute from "../../routes/AuthenticatedRoute";
import { useHistory } from "react-router";
import { Auth } from "../../Utils/helpers";
import backArrow from "../../assets/icons/sidebar/arrow-ios-back-outline.svg";
import downwardArrow from "../../assets/icons/sidebar/arrow-ios-downward-outline.svg";
import { roles, privileges } from "../../shared/constants/constants";

const checkRole = () => {
  const role = JSON.parse(sessionStorage.getItem("role"));
  if (role?.includes(roles.super_admin)) return "SUPER_ADMIN";
  if (role?.includes(roles.admin)) return "ADMIN";
  if (role?.includes(roles.company_admin)) return "COMPANY_ADMIN";
  if (role?.includes(roles.non_admin)) return "NON_ADMIN";
  return null;
};

const checkAccountType = () => {
  return sessionStorage.getItem("accountType");
};

export const filterPrivileges = (listOfPrivileges, accountType, role) => {
  const filters = {
    SUBSCRIPTION_ONLY: {
      NON_ADMIN: [
        privileges.subscription_only,
        privileges.my_profile,
        privileges.subscription_search,
      ],
      ADMIN: [
        privileges.subscription_only,
        privileges.add_user,
        privileges.list_user,
        privileges.my_profile,
        privileges.subscription_search,
        privileges.list_subscription_datasets,
      ],
      COMPANY_ADMIN: [
        privileges.subscription_only,
        privileges.add_user,
        privileges.edit_user,
        privileges.list_user,
        privileges.delete_user,
        privileges.my_profile,
        privileges.subscription_search,
        privileges.list_subscription_datasets,
      ],
    },
    SEARCH_ONLY: {
      NON_ADMIN: [
        privileges.single_search,
        privileges.edit_user,
        privileges.view_all_searches,
        privileges.batch_search,
        privileges.my_profile,
      ],
      ADMIN: [
        privileges.single_search,
        privileges.edit_user,
        privileges.list_user,
        privileges.add_user,
        privileges.view_all_searches,
        privileges.batch_search,
        privileges.my_profile,
      ],
      COMPANY_ADMIN: [
        privileges.single_search,
        privileges.add_user,
        privileges.edit_user,
        privileges.list_user,
        privileges.delete_user,
        privileges.view_all_searches,
        privileges.batch_search,
        privileges.my_profile,
      ],
    },
    SEARCH_AND_SUBSCRIPTION: {
      NON_ADMIN: [
        privileges.single_search,
        privileges.edit_user,
        privileges.view_all_searches,
        privileges.batch_search,
        privileges.my_profile,
        privileges.subscription_only,
        privileges.subscription_search,
      ],
      ADMIN: [
        privileges.single_search,
        privileges.add_user,
        privileges.edit_user,
        privileges.list_user,
        privileges.add_user,
        privileges.delete_user,
        privileges.view_all_searches,
        privileges.batch_search,
        privileges.my_profile,
        privileges.subscription_only,
        privileges.subscription_search,
        privileges.list_subscription_datasets,
      ],
      COMPANY_ADMIN: [
        privileges.single_search,
        privileges.add_user,
        privileges.edit_user,
        privileges.list_user,
        privileges.delete_user,
        privileges.view_all_searches,
        privileges.batch_search,
        privileges.my_profile,
        privileges.subscription_only,
        privileges.subscription_search,
        privileges.list_subscription_datasets,
      ],
    },
    SUPER_ADMIN: [
      privileges.upload_files,
      privileges.my_profile,
      privileges.review_flow,
      privileges.list_account,
      privileges.billing,
      privileges.add_account,
      privileges.add_subscription_dataset,
      privileges.list_subscription_datasets,
    ],
  };

  let filteredPrivileges = filters[accountType]?.[role] || listOfPrivileges;

  if (accountType === "SEARCH_ONLY") {
    filteredPrivileges = filteredPrivileges.filter(
      (priv) => priv !== privileges.subscription_only
    );
  }

  if (listOfPrivileges && !listOfPrivileges.includes(privileges.batch_search)) {
    filteredPrivileges = filteredPrivileges.filter(
      (priv) => priv !== privileges.batch_search
    );
  }

  return filteredPrivileges;
};

export function checkUserPrivileges(privilegeArray) {
  let flag = false;
  const privileges = getPrivileges();

  if (privilegeArray && privileges)
    if (typeof privilegeArray === "string") {
      return privileges.includes(privilegeArray);
    } else {
      privilegeArray.forEach((item) => {
        if (privileges.includes(item)) {
          flag = true;
        }
      });
      return flag;
    }
}

export const getPrivileges = () => {
  let privileges = Auth.getUserPrivileges();
  const accountType = sessionStorage.getItem("accountType");

  const updatedPrivileges = filterPrivileges(
    privileges,
    accountType,
    checkRole()
  );

  return updatedPrivileges;
};

export default function Sidebar(props) {
  const [activePath, setActivePath] = useState(window.location.pathname);
  const [navState, setNavState] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const initializeNavState = () => {
      const initialNavState = [];
      for (let i = 0; i < AuthenticatedRoute.length; i++) {
        let childrenPaths = AuthenticatedRoute[i].childrenPaths;
        if (childrenPaths) {
          childrenPaths.forEach((item) => {
            if (activePath.includes(item)) {
              initialNavState.push(AuthenticatedRoute[i].id);
            }
          });
        }
      }
      setNavState(initialNavState);
    };

    initializeNavState();
    checkAccountType();
    getPrivileges();

    const unlisten = history.listen((location, action) => {
      setActivePath(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [activePath, history]);

  const setNavbarState = (id, resetArray = false) => {
    let data = resetArray ? [] : Array.from(navState);
    let index = data.indexOf(id);
    if (index !== -1) {
      data.splice(index, 1);
    } else {
      data.push(id);
    }
    setNavState([...data]);
  };

  function redirectTo(path) {
    history.push(path);
    setActivePath(path);
  }

  return (
    <>
      <div className="left-sidebar">
        <Nav vertical className="mainNav">
          {AuthenticatedRoute.map(
            (prop) =>
              !prop.redirect &&
              checkUserPrivileges(prop.privilege) && (
                <React.Fragment key={prop.id}>
                  {prop.children ? (
                    <>
                      <NavItem
                        onClick={() => {
                          setNavbarState(prop.id);
                        }}
                        style={{
                          display:
                            checkRole() === "SUPER_ADMIN" ||
                            checkUserPrivileges(prop.privilege)
                              ? "inline-block"
                              : "none",
                        }}
                      >
                        <NavLink href="#" className="navlinkItem">
                          <div
                            className="sidebarIcon"
                            style={{ backgroundImage: `url(${prop.icon})` }}
                          >
                            <div className="sidebarText">
                              {prop.name}{" "}
                              <span
                                className="sidebarArrowIcon"
                                style={{
                                  backgroundImage: `url(${
                                    !navState.includes(prop.id)
                                      ? backArrow
                                      : downwardArrow
                                  })`,
                                }}
                              ></span>
                            </div>
                          </div>
                        </NavLink>
                      </NavItem>
                      <Collapse
                        isOpen={navState.includes(prop.id)}
                        className="collapsibleNav"
                      >
                        {prop.children.map(
                          (item) =>
                            checkUserPrivileges(item.privilege) && (
                              <NavItem
                                key={item.id}
                                active={
                                  activePath.replace("/account", "") ===
                                  item.path
                                }
                                onClick={() => {
                                  redirectTo("/account" + item.path);
                                }}
                              >
                                <NavLink href="#" className="navlinkItem">
                                  <div
                                    className="sidebarIcon"
                                    style={{
                                      backgroundImage: `url(${item.icon})`,
                                    }}
                                  >
                                    <div className="sidebarText">
                                      {item.name}
                                    </div>
                                  </div>
                                </NavLink>
                              </NavItem>
                            )
                        )}
                      </Collapse>
                    </>
                  ) : (
                    <NavItem
                      key={prop.id}
                      active={activePath.replace("/account", "") === prop.path}
                      onClick={() => {
                        redirectTo("/account" + prop.path);
                      }}
                    >
                      <NavLink href="#" className="navlinkItem">
                        <div
                          className="sidebarIcon"
                          style={{
                            backgroundImage: `url(${prop.icon})`,
                          }}
                        >
                          <div className="sidebarText">{prop.name}</div>
                        </div>
                      </NavLink>
                    </NavItem>
                  )}
                </React.Fragment>
              )
          )}
        </Nav>
      </div>
      <div className="left-small-sidebar mobileMenu">
        <Nav vertical className="mainNav">
          {AuthenticatedRoute.map(
            (prop) =>
              !prop.redirect &&
              checkUserPrivileges(prop.privilege) && (
                <React.Fragment key={prop.id}>
                  {prop.children ? (
                    prop.children.map(
                      (item) =>
                        checkUserPrivileges(item.privilege) && (
                          <NavItem
                            key={item.id}
                            active={
                              activePath.replace("/account", "") === item.path
                            }
                            onClick={() => {
                              redirectTo("/account" + item.path);
                              setNavbarState(prop.id, true);
                            }}
                            title={item.name}
                          >
                            <NavLink href="#" className="navlinkItem">
                              <div
                                className="sidebarIcon"
                                style={{
                                  backgroundImage: `url(${item.icon})`,
                                }}
                              ></div>
                            </NavLink>
                          </NavItem>
                        )
                    )
                  ) : (
                    <NavItem
                      key={prop.id}
                      active={activePath.replace("/account", "") === prop.path}
                      onClick={() => {
                        redirectTo("/account" + prop.path);
                        setNavbarState(prop.id, true);
                      }}
                      title={prop.name}
                    >
                      <NavLink href="#" className="navlinkItem">
                        <div
                          className="sidebarIcon"
                          style={{
                            backgroundImage: `url(${prop.icon})`,
                          }}
                        ></div>
                      </NavLink>
                    </NavItem>
                  )}
                </React.Fragment>
              )
          )}
        </Nav>
      </div>
    </>
  );
}
