import * as actionTypes from "../ActionTypes/index";
import { storeInitialState } from "../Store";

const subDomainReducer = (state = storeInitialState.subDomainStore, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SUBDOMAIN_NAME: {
      return {
        ...state,
        subDomain: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default subDomainReducer;
