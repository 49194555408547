export const columns = [
  { id: "name", label: "Name", tenantAdmin: true, disableSorting: false },
  { id: "state", label: "State", tenantAdmin: true, disableSorting: false },
  { id: "type", label: "Type", tenantAdmin: true, disableSorting: false },
  { id: "year", label: "Year", tenantAdmin: true, disableSorting: false },
  { id: "version", label: "Version", tenantAdmin: true, disableSorting: false },
  {
    id: "createdAt",
    label: "Created At",
    tenantAdmin: false,
    disableSorting: false,
  },
  {
    id: "createdBy",
    label: "Created By",
    tenantAdmin: false,
    disableSorting: false,
  },
  {
    id: "publishedAt",
    label: "Published At",
    tenantAdmin: false,
    disableSorting: false,
  },
  { id: "status", label: "Status", tenantAdmin: false, disableSorting: false },
  {
    id: "total_seats",
    label: "Total Seats",
    tenantAdmin: true,
    hideInAdmin: true,
    disableSorting: false,
  },
  {
    id: "assigned_seats",
    label: "Assigned Seats",
    tenantAdmin: true,
    hideInAdmin: true,
    disableSorting: false,
  },
  {
    id: "available_seats",
    label: "Available Seats",
    tenantAdmin: true,
    hideInAdmin: true,
    disableSorting: false,
  },
  {
    id: "actions",
    label: "Actions",
    align: "right",
    tenantAdmin: true,
    disableSorting: true,
  },
];
