import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "../../Redux/ActionTypes";
import { domainName } from "../constants/constants";

export default function useSubDomainName() {
  const { subDomain } = useSelector((state) => state.subDomain);

  const getSubDomainName = () => {
    return subDomain;
  };

  const [subDomainName, setSubDomainName] = useState(getSubDomainName());
  const dispatch = useDispatch();

  const saveSubDomainName = async () => {
    const full = process.env.NODE_ENV === "development" ? "qa-wwiliveportal.com" : window.location.host;
    const parts = full.split(".");
    const sub = parts[0];
    if (sub !== "" && sub !== domainName) {
      setSubDomainName(sub);
      dispatch({
        type: ActionTypes.SAVE_SUBDOMAIN_NAME,
        payload: sub,
      });
    }
    return Promise.resolve();
  };

  return {
    setSubDomainName: saveSubDomainName,
    subDomainName,
  };
}
