import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Spinner,
} from "reactstrap";
import AppInput from "../../Components/AppInput/AppInput";
import TermsofUse from "../../Components/TermsOfUse/TermsofUse";
import PasswordStatusScreens from "../../Containers/PasswordStatusScreens/PasswordStatusScreens";
import { passwordRegex } from "../../shared/constants/constants";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import useSubDomainName from "../../shared/hooks/useSubDomainName";
import {
  resetPassword,
  verifyResetPasswordToken,
} from "../../Utils/services/login.service";
import "./ResetPassword.css";

export default function ResetPassword(props) {
  useDocumentTitle("Reset-Password");

  const [successPage, setSuccessPage] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [termsOfUsePage, setTermsOfUsePage] = useState(false);

  const [showError, setShowError] = useState(false);
  const [state, setState] = useState({
    fields: {},
    error: {},
  });

  const history = useHistory();

  const { subDomainName, setSubDomainName } = useSubDomainName();

  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    const userName = query.get("username");
    const displayName = query.get("displayName");
    const isNewUser = query.get("isNewUser");
    let fields = {
      token,
      userName,
      displayName,
      isNewUser: isNewUser === "true" ? true : false
    };
    setState({ ...state, fields });
    saveSubDomainName();
    verifyToken(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSubDomainName = () => {
    if (subDomainName === "") {
      setSubDomainName();
    }
  };

  const verifyToken = async (token) => {
    setLoader(true);
    try {
      const response = await verifyResetPasswordToken(token);

      const data = response.data.responseObject;
      if (data.code !== 200) {
        setErrorPage(true);
        const err = {
          alert: data.message,
        };
        setState({ ...state, error: err });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setErrorPage(true);
      const err = {
        alert: error.message,
      };
      setState({ ...state, error: err });
    }
  };

  function handleValidation() {
    let fields = state.fields;
    let errors = {};
    let formIsValid = true;

    if (fields["isNewUser"]) {
      if (!fields["tempPassword"]) {
        formIsValid = false;
        errors["tempPassword"] = "Temporary password is required";
      }
    }

    if (!fields["newPassword"]) {
      formIsValid = false;
      errors["newPassword"] = "New password is required";
    } else if (fields["newPassword"].length < 8) {
      formIsValid = false;
      errors["newPassword"] = "New password must be minimum 8 characters.";
    } else if (fields["newPassword"].length > 20) {
      formIsValid = false;
      errors["newPassword"] = "New password must be maximum 20 characters.";
    } else {
      if (!passwordRegex.test(fields["newPassword"])) {
        formIsValid = false;
        errors["newPassword"] =
          "New password must have at least one uppercase and one special character.";
      }
    }

    if (!fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "Confirm password is required";
    } else if (fields["confirmPassword"].length < 8) {
      formIsValid = false;
      errors["confirmPassword"] =
        "Confirm password must be minimum 8 characters.";
    } else if (fields["confirmPassword"].length > 20) {
      formIsValid = false;
      errors["confirmPassword"] =
        "Confirm password must be maximum 20 characters.";
    } else {
      if (!passwordRegex.test(fields["confirmPassword"])) {
        formIsValid = false;
        errors["confirmPassword"] =
          "Confirm password must have at least one uppercase and one special character.";
      }
    }

    if (!fields["termsConditions"]) {
      formIsValid = false;
      errors["termsConditions"] =
        "Please indicate that you agree to terms and conditions";
    }

    if (fields["newPassword"] !== fields["confirmPassword"]) {
      formIsValid = false;
      errors["alert"] = "New password and Confirm password did not match";
    }

    setState({ ...state, error: errors });
    setShowError(true);
    return formIsValid;
  }

  function handleChange(e, field) {
    let fields = state.fields;

    let value =
      e.target.name === "checkbox" ? e.target.checked : e.target.value;
    fields[field] = value;

    let err = state.error;
    err[field] = "";

    err["alert"] = "";

    setState({ ...state, fields, err });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (handleValidation()) {
      try {
        const { userName, tempPassword, newPassword, token, isNewUser } =
          state.fields;

        let requestBody = {
          email: userName,
          newPassword
        };

        if (isNewUser) {
          requestBody['tempPassword'] = tempPassword
        }

        const response = await resetPassword(requestBody, token, isNewUser);

        const data = response.data.responseObject;
        if (data.code === 200) {
          setSuccessPage(true);
        } else {
          const err = {
            alert: data.message,
          };
          setState({ ...state, error: err });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        const err = {
          alert: error.message,
        };
        setState({ ...state, error: err });
      }
    } else {
      setIsLoading(false);
    }
  };

  function goToHome() {
    history.push(`/`);
  }

  return (
    <>
      {termsOfUsePage ? (
        <TermsofUse backClick={() => setTermsOfUsePage(false)} />
      ) : (
        <div>
          <header className="header-container">
            <Navbar light>
              <NavbarBrand
                className="me-auto logoContainer"
                onClick={goToHome}
              ></NavbarBrand>
            </Navbar>
          </header>
          {loader ? (
            <div className="resetPasswordContainer">
              <div className="centralLoader"></div>
              <div className="successScreenMsg my-3">
                Verifying link. Please wait...
              </div>
            </div>
          ) : (
            <div className="resetPasswordContainer">
              <div className="resetPasswordHeader">
                <div className="title">Welcome {state.fields.displayName}!</div>
                <div className="subTitle">
                  {!successPage &&
                    !errorPage &&
                    "Please change your password to get started"}
                  {successPage && "Your password has been successfully changed."}
                </div>
              </div>
              {!successPage && !errorPage && (
                <div className="resetPasswordSection">
                  {showError && state.error.alert && (
                    <Alert
                      fade={false}
                      color="danger"
                      toggle={() => setShowError(false)}
                    >
                      {state.error.alert}
                    </Alert>
                  )}
                  {
                    state.fields.isNewUser
                    &&
                    <AppInput
                      label="Temporary Password"
                      className="my-3"
                      placeholder="Please enter temporary password"
                      type="password"
                      value={state.fields.tempPassword}
                      onChange={(e) => handleChange(e, "tempPassword")}
                      errorMsg={showError ? state.error.tempPassword : ""}
                      validations={{
                        required: true,
                      }}
                    />
                  }
                  <AppInput
                    label="New Password"
                    className="my-3"
                    placeholder="Please enter new password"
                    type="password"
                    value={state.fields.newPassword}
                    onChange={(e) => handleChange(e, "newPassword")}
                    errorMsg={showError ? state.error.newPassword : ""}
                    validations={{
                      required: true,
                      minLength: 8,
                      maxLength: 20,
                      pattern: passwordRegex,
                    }}
                  />
                  <AppInput
                    label="Confirm New Password"
                    className="mb-3"
                    type="password"
                    value={state.fields.confirmPassword}
                    onChange={(e) => handleChange(e, "confirmPassword")}
                    errorMsg={showError ? state.error.confirmPassword : ""}
                    validations={{
                      required: true,
                      minLength: 8,
                      maxLength: 20,
                      pattern: passwordRegex,
                    }}
                  />

                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      name="checkbox"
                      value={state.fields.termsConditions}
                      onChange={(e) => handleChange(e, "termsConditions")}
                      // error={showError ? state.error.termsConditions : ''}
                    />
                    <Label check className="resetPassCheckboxLabel">
                      I agree to{" "}
                      <span
                        className="text-primary"
                        onClick={() => setTermsOfUsePage(true)}
                      >
                        terms and conditions (Read)
                      </span>
                    </Label>
                  </FormGroup>
                  {showError && state.error.termsConditions !== "" && (
                    <div className="text-danger errorMsg">
                      {state.error.termsConditions}
                    </div>
                  )}

                  <div>
                    <Button
                      className="resetPassBtn mt-3"
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <Spinner size="sm" className="mx-2"></Spinner>
                      )}
                      Submit
                    </Button>
                  </div>
                </div>
              )}
              {(successPage || errorPage) && (
                <PasswordStatusScreens
                  page={successPage ? "success" : "error"}
                  tenant={state.fields.tenant}
                  errormsg={state.error.alert}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
