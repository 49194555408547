import React, { useState } from "react";
import { useHistory } from "react-router";
import { logoutUser } from "../../../Utils/services/login.service";
import { ToastrService } from "../../../Utils/services/tostr.service";
import AppLoader from "../../AppLoader/AppLoader";
import "./Menu.css";
import { roles } from "../../../shared/constants/constants";
import { Auth } from "../../../Utils/helpers";

export default function Menu() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const userRole = Auth.getUserRole();

  const showUserMenu = () => {
    document.getElementById("menuBar")?.classList.toggle("hiddenMenu");
  };

  document.addEventListener("mouseup", function (e) {
    const container = document.getElementById("menuContainer");
    if (container) {
      if (!container.contains(e.target)) {
        document.getElementById("menuBar")?.classList.add("hiddenMenu");
      }
    }
  });

  const logoutClicked = async () => {
    setIsLoading(true);
    try {
      const response = await logoutUser();
      if (response.code === 200) {
        sessionStorage.clear();
        history.push("/login");
      } else {
        // console.log(response);
        ToastrService.error(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      ToastrService.error(error.message);
    }
    showUserMenu();
  };

  const redirectTo = (path) => {
    history.push(`/account/${path}`);
    showUserMenu();
  };

  return (
    <>
      <AppLoader isLoading={isLoading} />
      <div id="menuContainer">
        <div className="userMenuContainer" onClick={showUserMenu}>
          <div className="userMenuImage"></div>
          {/* <div className="userMenuText">
							Ajay
					</div> */}
        </div>
        <div className="menuBar hiddenMenu" id="menuBar">
          <ul className="menu-item">
            {sessionStorage
              .getItem("role")
              ?.includes(roles.download_only && roles.non_admin) ? (
              <li>
                <div className="menu" onClick={() => redirectTo("profile")}>
                  <span className="menuIcon menuProfileIcon"></span>
                  <span>My Account</span>
                </div>
              </li>
            ) : (
              sessionStorage
                .getItem("role")
                ?.includes(roles.download_only && roles.admin) && (
                <li>
                  <div className="menu" onClick={() => redirectTo("profile")}>
                    <span className="menuIcon menuProfileIcon"></span>
                    <span>My Account</span>
                  </div>
                </li>
              )
            )}
            <li>
              <div className="menu" onClick={logoutClicked}>
                <span className="menuIcon menuLogoutIcon"></span>
                <span>Log out</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
