import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import "./AppMenu.css";

export default function AppMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { header, options, onSelect } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    let textContent = e.target.textContent;
    let selectedOption = options.filter((item) => item.label === textContent);
    if (selectedOption.length) {
      onSelect(selectedOption[0].value);
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        size="small"
        className="AppMenuHeaderDiv"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {header}
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.label}
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "35px",
              color: "#606D77",
            }}
          >
            <ListItemIcon>
              <div
                className="AppMenuIcon"
                style={{ backgroundImage: `url(${item.icon})` }}
              ></div>
            </ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
