import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import AppAutocomplete from "../../Components/AppAutocomplete/AppAutocomplete";
import AppInput from "../../Components/AppInput/AppInput";
import AppLoader from "../../Components/AppLoader/AppLoader";
import {
  copyToClipboard,
  emailRegex,
  onlyText,
  roles,
  titles,
} from "../../shared/constants/constants";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { Auth } from "../../Utils/helpers";
import { ToastrService } from "../../Utils/services/tostr.service";
import { getProfileDetails } from "../../Utils/services/user.service";
import "./MyProfile.css";

export default function MyProfile() {
  useDocumentTitle("My Profile");

  const initialState = {
    fields: {},
    error: {},
  };

  const [formError, setFormError] = useState("");
  const [formValues, setFormValues] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKeyValue, setApiKeyValue] = useState("");

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    setIsLoading(true);
    try {
      let result = await getProfileDetails();
      if (result.code === 200) {
        if (result.body) {
          let data = {
            ...result.body,
            status: result.body.status ? "Active" : "InActive",
            userRole: result.body.isAdmin ? "Admin" : "User",
          };
          setFormValues({ fields: data, error: {} });
          if (result.body.apiKey) {
            setApiKeyValue(result.body.apiKey);
          }
        }
      } else {
        ToastrService.error(result.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(error.message);
    }
  };

  const handleChange = (e, field) => {
    setFormError("");
    let fields = formValues.fields;

    let value =
      e.target.name === "checkbox" ? e.target.checked : e.target.value;
    fields[field] = value;

    let err = formValues.error;
    err[field] = "";

    setFormValues({ ...formValues, fields, err });
  };

  const checkUserIsAdmin = () => {
    if (
      Auth.getUserRole().includes(roles.admin) ||
      Auth.getUserRole().includes(roles.company_admin)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <AppLoader isLoading={isLoading} />
      <div className="container-fluid">
        <div className="pageTitle">My Profile</div>
        <form>
          <div className="addUserSection">
            <div className="mt-4">
              <Row>
                <Col md={{ size: 6 }} className="addUserInputs">
                  <AppInput
                    label="Email Id"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.email}
                    errorMsg={formValues.error.email}
                    validations={{
                      required: true,
                      pattern: emailRegex,
                    }}
                    onChange={(e) => handleChange(e, "email")}
                    disabled
                  />

                  <AppInput
                    label="First Name"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.firstName}
                    errorMsg={formValues.error.firstName}
                    validations={{
                      required: true,
                      maxLength: 25,
                      pattern: onlyText,
                    }}
                    onChange={(e) => handleChange(e, "firstName")}
                    disabled
                  />

                  <AppAutocomplete
                    label="Title"
                    className="mb-4 addUserSelectInput"
                    value={formValues.fields.title}
                    options={titles}
                    onChange={(e) => handleChange(e, "title")}
                    disabled
                  />

                  {/* {apiKeyValue !== "" && checkUserIsAdmin() ? (
                    <div
                      className="apiKeyText text-secondary profilePageCopyIconContainer"
                      title="Click to copy"
                    >
                      <b>API Key:</b> &nbsp;
                      {apiKeyValue} &nbsp;
                      <div
                        className="profilePageCopyIcon"
                        onClick={() => copyToClipboard(apiKeyValue)}
                      ></div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* <AppSelect
                    label="User Role"
                    className="mb-4 addAccountSelectInput"
                    value={formValues.fields.userRole}
                    options={userRole}
                    errorMsg={formValues.error.userRole}
                    onChange={(e) => handleChange(e, "userRole")}
                    disabled
                    required
                  /> */}

                  {/* {(!viewForm && !editForm) && (
                    <AppMultipleSelect
                      label="Allow Search in States"
                      className="mb-4 addUserSelectInput"
                      value={formValues.fields.allowedStateIdList}
                      options={stateList}
                      errorMsg={formValues.error.allowedStateIdList}
                      onChange={(e) => handleChange(e, "allowedStateIdList")}
                      required
                    />
                  )} */}
                </Col>
                <Col md={{ size: 6 }} className="addUserInputs">
                  <AppInput
                    label="Account Number"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.accountNumber}
                    disabled
                  />

                  <AppInput
                    label="Last Name"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.lastName}
                    errorMsg={formValues.error.lastName}
                    validations={{
                      required: true,
                      maxLength: 25,
                      pattern: onlyText,
                    }}
                    onChange={(e) => handleChange(e, "lastName")}
                    disabled
                  />

                  <AppInput
                    label="Phone Number"
                    className="mb-4"
                    type="number"
                    value={formValues.fields.phoneNumber}
                    errorMsg={formValues.error.phoneNumber}
                    validations={{
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                    }}
                    onChange={(e) => handleChange(e, "phoneNumber")}
                    disabled
                  />
                </Col>
              </Row>
              <div>
                <Row className="mb-3">
                  <Col>
                    {formError !== "" && (
                      <span className="text-danger errorMsg">{formError}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 6 }} className="addUserBtns">
                    {/* <div>
                      <Button className="addUserBtn" type="submit">
                        {isLoading && (
                          <Spinner size="sm" className="mx-2"></Spinner>
                        )}
                        Submit
                      </Button>
                    </div> */}
                    {/* <div>
                      <Button
                        className="addUserBtn cancelBtn"
                        type="button"
                        onClick={clearForm}
                      >
                        Cancel
                      </Button>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
