import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { Button, Col, Row, Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import useTable from "../../Components/useTable";
import {
  getListOfSubscriptionUsers,
  updateFileUserLists,
} from "../../Utils/services/downloads.service";
import { ToastrService } from "../../Utils/services/tostr.service";
import AppInput from "../../Components/AppInput/AppInput";

const headCellsForDownload = [
  {
    id: "accountName",
    label: "ACCOUNT NAME",
    width: "25%",
    disableSorting: true,
  },
  {
    id: "accountType",
    label: "Account Type",
    width: "25%",
    disableSorting: true,
  },
  {
    id: "url",
    label: "URL",
    width: "25%",
    disableSorting: true,
  },
  { id: "action", label: "ACTION", width: "25%", disableSorting: true },
];

const FileUsersLists = () => {
  const { fileid } = useParams();
  const [fileList, setFileList] = useState([
    {
      accountId: 0,
      tenant: "",
      accountType: "",
      accountURL: "",
      isAccessed: false,
    },
  ]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { TblContainer, TblHead } = useTable(fileList, headCellsForDownload);
  const [searchQuery, setSearchQuery] = useState("");
  const [selecteAllAccounts, setSelecteAllAccounts] = useState(false);

  useEffect(() => {
    getUserLists();
  }, []);

  useEffect(() => {
    const isMatch = fileList?.map((item) => item?.isAccessed)?.includes(false);

    if (isMatch) {
      setSelecteAllAccounts(false);
    } else {
      setSelecteAllAccounts(true);
    }
  }, [fileList]);

  const handleAllFilesAssign = (event) => {
    const { checked } = event?.target;
    setSelecteAllAccounts(checked);
    if (checked) {
      const updatedData = fileList.map((item) =>
        item.accountId ? { ...item, isAccessed: true } : item
      );
      setFileList(updatedData);
    } else {
      const updatedData = fileList.map((item) =>
        item.accountId ? { ...item, isAccessed: false } : item
      );
      setFileList(updatedData);
    }
  }

  // Retrieve user's or company's name for assigning a file to multiple users
  const getUserLists = async () => {
    try {
      setIsLoading(true);
      const result = await getListOfSubscriptionUsers(fileid);

      if (result?.code === 200) {
        setFileList(result?.body);
        setIsLoading(false);
      } else {
        setFileList([]);
        setIsLoading(false);
      }
    } catch (error) {
      setFileList([]);
      setIsLoading(false);
    }
  };

  // Onchange event on check box for assigning users to that file
  const onChangeCheckBox = (id) => {
    const updatedData = fileList.map((item) =>
      item.accountId == id ? { ...item, isAccessed: !item.isAccessed } : item
    );
    setFileList(updatedData);
  };

  // Updating users on that file
  const updateFileList = async () => {
    try {
      setIsLoading(true);

      let updatedData = fileList?.filter((item) => {
        if (item?.isAccessed) {
          return item?.accountId;
        }
      });

      updatedData = updatedData?.map((ele) => ele?.accountId);

      const result = await updateFileUserLists(fileid, updatedData);

      if (result?.code === 200) {
        getUserLists();
        setIsLoading(false);
        setSelecteAllAccounts(false);
        ToastrService.success(`${result?.message}.`);
      } else {
        setIsLoading(false);
        ToastrService.error(`${result?.message}.`);
        getUserLists();
      }
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(`Something went wrong!`);
    }
  };

  return (
    <>
      <div className="d-block">
        <div className="page-content">
          <AppLoader isLoading={isLoading} />
          <div className="container-fluid">
            <div className="userListSection">
              <Row className="mb-2 d-flex justify-content-between align-items-center">
                <Col lg={{ size: 6 }}>
                  <div className="pageTitle">{location?.state?.state}</div>
                </Col>
                <Col lg={{ size: 3 }} md={{ size: 12 }} className="d-flex justify-content-end">
                  <AppInput
                    label="Search here"
                    placeholder="Search here..."
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-100"
                    value={searchQuery}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 12 }} className="d-flex justify-content-end align-items-center">
                  Select all accounts<Checkbox
                    color="success"
                    checked={selecteAllAccounts}
                    onChange={(event) => {
                      handleAllFilesAssign(event);
                      // setSelecteAllAccounts(state => !state);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InfiniteScroll
                    className="userListTable"
                    dataLength={fileList?.length}
                    height="100%"
                  >
                    <TblContainer>
                      <TblHead />
                      <TableBody>
                        {fileList?.length ? (
                          fileList
                            ?.filter((item) => {
                              return item?.tenant?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || item?.accountURL?.toLowerCase()?.includes(searchQuery?.toLowerCase())
                            })
                            ?.map((item) => (
                              <TableRow key={item?.accountId}>
                                <TableCell>
                                  <div className="text-truncate">
                                    {item?.tenant}
                                  </div>
                                </TableCell>
                                <TableCell>{item?.accountType}</TableCell>
                                <TableCell className="companyNameColumn text-lowercase">
                                  <a
                                    target="_blank"
                                    href={item?.accountURL}
                                    className="text-decoration-none"
                                  >
                                    {item?.accountURL}
                                  </a>
                                </TableCell>
                                <TableCell className="accountListIconSection text-start">
                                  <Checkbox
                                    color="success"
                                    defaultChecked
                                    checked={item?.isAccessed}
                                    onChange={() =>
                                      onChangeCheckBox(item?.accountId)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={headCellsForDownload?.length}
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              No Result Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </TblContainer>
                  </InfiniteScroll>
                </Col>
              </Row>
              <Row className="mt-2 mb-2 d-flex justify-content-end">
                <Col sm={{ size: 2 }}>
                  <Button
                    style={{ width: "100%" }}
                    onClick={updateFileList}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner style={{ height: "1rem", width: "1rem" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUsersLists;
