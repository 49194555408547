import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import AppIconSection from "../../Components/AppIconSection/AppIconSection";
import TableLoader from "../../Components/TableLoader/TableLoader";
import useTable from "../../Components/useTable";
import Dashboard from "../../Containers/Dashboard/Dashboard";
import DashboardNavTabs from "../../Containers/DashboardNavTabs/DashboardNavTabs";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { batchFileRecordDetails } from "../../Utils/services/batchSearch.service";
import "./BatchResult.css";
import { convertTZ } from "../../shared/constants/constants";

const headCells = [
  {
    id: "plateNumber",
    label: "License Plate Number",
    width: "35%",
  },
  { id: "licenseState", label: "State", width: "15%" },
  {
    id: "searchedOn",
    label: "Searched On",
    width: "25%",
    disableSorting: true,
  },
  {
    id: "searchedBy",
    label: "Searched By",
    width: "25%",
    disableSorting: true,
  },
];

export default function BatchResult() {
  useDocumentTitle("Batch Results");

  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [batchId, setBatchId] = useState(null);

  const { TblContainer, TblHead } = useTable(data, headCells);

  const history = useHistory();

  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const batchHistoryId = query.get("id");
    if (batchHistoryId) {
      setBatchId(batchHistoryId);
      getBatchFileRecordDetails(batchHistoryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page]);

  const getBatchFileRecordDetails = async (id) => {
    setloader(true);
    try {
      let response = await batchFileRecordDetails(id, {
        sortDirection: order,
        sortBy: orderBy,
        pageNumber: page,
        size: 10,
        searchBy: "",
      });

      if (response.code === 200) {
        const temp = Array.from(!page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach(
            (item) => (item.searchedOn = convertTZ(item.searchedOn))
          );
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setError("");
      } else {
        setloader(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setError(error.message);
    }
  };

  const handleSortRequest = (orderBy, order) => {
    document.getElementsByClassName("batchResultTable")[0].scrollTo(0, 0);
    setOrder(order);
    setPage(0);
    setOrderBy(orderBy);
    setTimeout(() => {
      setData([]);
    }, 10);
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  const checkIfValueIsNullOrEmpty = (value) => {
    if (value && value !== "") {
      return value;
    } else {
      return "-";
    }
  };

  return (
    <>
      <Dashboard />
      <DashboardNavTabs active="batch" />
      <div className="container-fluid mt-4">
        <Row className="batchResultHeader">
          <Col md={{ size: 6 }}>
            <div
              className="backIcon logo"
              onClick={() => history.push("/account/batchHistory")}
            ></div>
            <div className="batchResultTitle">Batch Search Result</div>
          </Col>
          <Col md={{ size: 6 }}>
            <AppIconSection />
          </Col>
        </Row>
        <div className="batchResultSection">
          <Row className="batchResultList">
            <Col>
              {error !== "" && (
                <span className="text-danger errorMsg">{error}</span>
              )}
            </Col>
          </Row>
          <Row className="batchResultList">
            <Col>
              <InfiniteScroll
                className="batchResultTable"
                dataLength={data.length}
                next={() => addNewData()}
                hasMore={data.length < totalRecords}
                height={398}
                endMessage={
                  !loader &&
                  !data.length && (
                    <p className="text-secondary mt-3 text-center">
                      No Result Found
                    </p>
                  )
                }
              >
                <TblContainer>
                  <TblHead
                    handleSort={(orderBy, order) =>
                      handleSortRequest(orderBy, order)
                    }
                  />
                  <TableBody>
                    {data.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell className="licensePlateColumn">
                          <div
                            className="licensePlateCell"
                            onClick={() => {
                              sessionStorage.setItem("searchId", item.id);
                              sessionStorage.setItem("batchId", batchId);
                              history.push(
                                "/account/search/result/false?batchResult=true"
                              );
                            }}
                          >
                            <div className="smallCarIcon"></div>
                            <div className="licenseNumberText">
                              {checkIfValueIsNullOrEmpty(item.plateNumber)}
                            </div>
                          </div>
                          <div className="tableCellSubValue">
                            VIN: {checkIfValueIsNullOrEmpty(item.vin)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(item.licenseState)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(item.searchedOn)}
                          </div>
                          {/* <div className="tableCellSubValue">10:32 AM</div> */}
                        </TableCell>
                        <TableCell>
                          <div className="tableCellValue">
                            {checkIfValueIsNullOrEmpty(item.searchedBy)}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {loader && <TableLoader columnsNumber={4} />}
                  </TableBody>
                </TblContainer>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
