import { Auth } from "../helpers";
import baseUrl from "./interceptor";

// Get file list of that user.
export const getDownloadOnlyDetails = (subdomain) => {
  return baseUrl.get("wwi/files/get-account-files", {
    Authorization: Auth.getAuthToken(),
    Subdomain: subdomain,
  });
};

// download file from aws s3 bucket.
export const downloadFile = (fileId, subdomain) => {
  return baseUrl.get(`wwi/files/get-download-url?fileId=${fileId}`, {
    Authorization: Auth.getAuthToken(),
    Subdomain: subdomain,
  });
};

// Get List of Files and their assigned users
export const getListOfAllTanentFilesUsers = () => {
  return baseUrl.get(`wwi/files/get/files/accounts`);
};

// After file upload on S3 Bucket then store info reguarding file in database. & Update the users respective of that file
export const uploadFile = (fileName, fileId = null) => {
  const fileUpload = {
    // fileName: fileName,
    // fileId: fileId,
  };

  return baseUrl.post(`wwi/files/upload?fileName=${fileName}`, fileUpload);
};

// Get List of users
export const getTanentUsersList = () => {
  return baseUrl.get(`auth/user/get/download-users`);
};

// Delete file of that users.
export const deleteFileFromList = (fileId) => {
  return baseUrl.delete(`wwi/files/delete?fileId=${fileId}`);
};

// Upload file on aws S3 bucket
export const uploadFileOnS3Bucket = (fileName) => {
  return baseUrl.get(`wwi/files/get/upload/url?fileName=${fileName}`);
};

// Check status of file during uploading period
export const checkFileStatus = (fileName) => {
  return baseUrl.get(`wwi/files/get/scan/status?fileName=${fileName}`);
};

// Retrieve user's or company's name for assigning a file to multiple users
export const getListOfSubscriptionUsers = (fileid) => {
  return baseUrl.get(`wwi/files/all-accounts?fileId=${fileid}`);
};

// Updating users on that file
export const updateFileUserLists = (fileid, userList) => {
  const reqBody = {
    accountIds: userList,
    fileId: fileid,
  };

  return baseUrl.post(`wwi/files/update-file-access`, reqBody);
};
