import { Auth } from "../helpers";
import { baseUrlLogin } from "./interceptor";

export const getListOfSearches = (data, accountId) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post("wwi/account/stats", data, {
    headers: headers,
    params: {
      accountId,
    },
  });
};

export const getSearchRecordsCount = (data, accountId) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post("wwi/account/summary", data, {
    headers: headers,
  });
};


export const downloadSearchListFile = (data, accountId) => {
  let headers = {
    Authorization: Auth.getAuthToken(),
  };

  return baseUrlLogin.post("wwi/export/account/stats", data, {
    headers: headers,
    params: {
      accountId,
    },
  });
};

export const downloadAccountsSearchCountFile = (data) => {
  let headers = {
    Authorization: Auth.getAuthToken() 
  };

  return baseUrlLogin.post("wwi/account/summary/export", data, {
    headers: headers,
    responseType: "blob"
  });
};
