import baseUrl from "./interceptor";

export const getUserDetails = (email) => {
  return baseUrl.get("auth/user/get/details", {
    params: {
      email: email ? email : null,
    }
  });
};

export const addNewUser = (body) => {
  return baseUrl.post("auth/user/add", body);
};

export const editUser = (body) => {
  return baseUrl.post("auth/user/update/details", body);
};

export const getListOfUsers = (body) => {
  return baseUrl.post("auth/tenant/get/list", body);
};

export const deleteUser = (id) => {
  return baseUrl.delete("auth/user/delete", {
    params: {
      id
    }
  });
}

export const resendInviteToUser = (user) => {
  return baseUrl.post("auth/tenant/resend/invite", {}, {
    params: {
      userId: user?.id,
      accountNumber: user?.accountNumber
    }
  });
}

export const fetchListOfState = (tenant) => {
  return baseUrl.get("auth/tenant/get/state/list", {}, {
    params: {
      tenant
    }
  });
}

export const getAllActiveSubscriptionTenants = () => {
  return baseUrl.get("auth/tenant/active-subscription-tenants");
}

export const getProfileDetails = () => {
  return baseUrl.get("auth/user/profile");
};