import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../Pages/NotFound/NotFound";
import UnauthenticatedRoute from "../routes/UnauthenticatedRoute";
import useToken from "../shared/hooks/useToken";

const Fulllayout = (props) => {
  const { token } = useToken();

  if (token) {
    return <Redirect to="/account" />;
  }

  return (
    <div className="page-wrapper d-block">
      <div className="page-content container-fluid">
        <Switch>
          {UnauthenticatedRoute.map((prop, key) => {
            if (prop.redirect) {
              return <Redirect exact from={prop.path} to={prop.pathTo} key={key} />;
            } else {
              return (
                <Route exact path={prop.path} component={prop.component} key={key} />
              );
            }
          })}
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};
export default Fulllayout;
