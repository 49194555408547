import { Divider, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import RightArrow from "../../assets/icons/right_arrow.svg";
import AppInput from "../../Components/AppInput/AppInput";
import { Button } from "reactstrap";
import {
  getListOfTenants,
  getListOfUsers,
} from "../../Utils/services/accounts.service";
import { convertTZ } from "../../shared/constants/constants";
import dayjs from "dayjs";
import IconAppInput from "../../Components/IconAppInput/IconAppInput";
import searchIcon from "../../assets/icons/small_search.png";
import InfiniteScroll from "react-infinite-scroll-component";
import AppLoader from "../../Components/AppLoader/AppLoader";
import {
  bulkAssignUsersToDataset,
  getDatasetSeatsCountByAccountForSuperAdmin,
  updateDatasetSeatsCountByAccountForSuperAdmin,
} from "../../Utils/services/subscription-datasets.service";
import { ToastrService } from "../../Utils/services/tostr.service";
import { Auth } from "../../Utils/helpers";
import { getAllActiveSubscriptionTenants } from "../../Utils/services/user.service";

const AssignUsers = ({
  selectedDataset,
  closeDrawer,
  onSave,
  isTenantAdmin,
}) => {
  const [dataset, setDataset] = useState(selectedDataset);
  const [accounts, setAccounts] = useState();
  const [totalSeats, setTotalSeats] = useState();
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);

  const fetchListOfUsers = async (resetData = false) => {
    setloader(true);
    try {
      let response = await getListOfUsers({
        sortDirection: order,
        sortBy: orderBy,
        pageNumber: page,
        size: 15,
        searchBy: searchQuery,
      });
      if (response.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach((item) => {
            const date = convertTZ(item.onBoardedDate);
            item.onBoardedDate = dayjs(date).format("MM-DD-YYYY");
          });
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setIsLoading(false);
      } else {
        setloader(false);
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setIsLoading(false);
      setError(error.message);
    }
  };

  const fetchSubscriptionAccountsList = async () => {
    setIsLoading(true);
    const response = await getAllActiveSubscriptionTenants();
    const data = await getDatasetSeatsCountByAccountForSuperAdmin(dataset.id);

    if (response && response.code === 200 && response.body) {
      setData(response.body);
    }

    if (data) {
      setAccounts(data.accounts);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isTenantAdmin) {
      fetchListOfUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, searchQuery]);

  useEffect(() => {
    if (!isTenantAdmin) fetchSubscriptionAccountsList();
  }, []);

  const addNewData = () => {
    setPage(page + 1);
  };

  const handleSave = async () => {
    if (isTenantAdmin) {
      setIsLoading(true);
      await bulkAssignUsersToDataset(
        [...dataset.assigned_seats.map((e) => e.email)],
        dataset.id
      );
      await onSave();
      ToastrService.success("Users Assigned Successfully!");
      closeDrawer();
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const res = await updateDatasetSeatsCountByAccountForSuperAdmin(
        dataset.id,
        accounts
      );
      if (res && res.request && res.request.response) {
       // console.log(JSON.parse(res.request.response).message);
        ToastrService.error(JSON.parse(res.request.response).message);
      } else {
        await onSave();
        ToastrService.success("Accounts Assigned Successfully!");
        closeDrawer();
      }

      setIsLoading(false);
    }
  };

  const handleUsersList = () => {
    return (
      data &&
      data.length > 0 &&
      data.map((user) => {
        if (
          user.isActive &&
          (dataset.total_seats - dataset.assigned_seats.length > 0 ||
            (dataset.total_seats - dataset.assigned_seats.length === 0 &&
              dataset.assigned_seats.map((e) => e.email).includes(user.email)))
        )
          return (
            <label
              htmlFor={user.email}
              className="d-flex align-items-start bg-light my-2 p-2 rounded"
              style={{ cursor: "pointer" }}
            >
              <div className="me-2">
                <input
                  type="checkbox"
                  id={user.email}
                  className="form-check-input"
                  checked={dataset.assigned_seats
                    .map((e) => e.email)
                    .includes(user.email)}
                  onClick={(e) => {
                    setDataset((prev) => {
                      return {
                        ...prev,
                        assigned_seats: e.target.checked
                          ? [
                              ...prev.assigned_seats,
                              {
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                              },
                            ]
                          : prev.assigned_seats.filter(
                              (seat) => seat.email !== user.email
                            ),
                      };
                    });
                  }}
                />
              </div>
              <div>
                <div className="fw-bold" style={{ cursor: "pointer" }}>
                  <div>
                    {user.firstName} {user.lastName}
                    {user.email === Auth.getUserEmail() ? ` (You)` : ""}
                  </div>
                  <div className="fw-normal text-muted">{user.email}</div>
                </div>
              </div>
            </label>
          );
      })
    );
  };

  const handleAccountsList = () => {
    return (
      data &&
      data.length > 0 &&
      data.map((tenant) => {
        const account =
          accounts &&
          accounts.length > 0 &&
          accounts.find((e) => e.accountNumber === tenant.accountNumber);

        const seats = account ? account.maxSeatsCount.toString() : "0";

        if (
          !searchQuery ||
          (searchQuery &&
            tenant.tenant.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          const assignedSeatsGreaterWarning = account && account.maxSeatsCount < account.assignedSeatsCount;
          const warning = assignedSeatsGreaterWarning;
          return (
         <div className="mt-3" key={tenant.accountNumber}>
           {warning && <div
               style={{
                 background: "orange",
                   fontSize:16,
               }}
               className="rounded-top px-2 py-1 text-center">Total Seats is lesser than Assigned Seats
           </div>}
           <label
              htmlFor={tenant.accountNumber}
              style={{ border: warning ? "1px solid orange" : null }}
              className={(!warning && "rounded-top ") + "w-100 d-flex align-items-start bg-light mb-2 p-2 rounded-bottom"}
            >
              <div className="flex-grow-1 d-flex align-items-start gap-2 justify-content-between" >
                <div className="fw-bold">
                  <div>{tenant.tenant}</div>
                  <div className="fw-normal text-muted">
                    {`${tenant.accountType.replaceAll("_", " ")}`}
                  </div>
                </div>
                <div>
                  <AppInput
                    type="number"
                    label="Total Seats"
                    value={seats}
                    onChange={(e) => {
                      if(e.target.value >= 0)
                      setAccounts((prev) => {
                        const existingAccount = prev.findIndex(
                          (e) => e.accountNumber === tenant.accountNumber
                        );

                        if (existingAccount >= 0) {
                          prev[
                            prev.findIndex(
                              (e) => e.accountNumber === tenant.accountNumber
                            )
                          ].maxSeatsCount = Number.isNaN(
                            parseInt(e.target.value.toString())
                          )
                            ? 0
                            : parseInt(e.target.value.toString());
                        } else {
                          prev.push({
                            accountNumber: tenant.accountNumber,
                            maxSeatsCount: Number.isNaN(
                              parseInt(e.target.value.toString())
                            )
                              ? 0
                              : parseInt(e.target.value.toString()),
                          });
                        }

                        return [...prev];
                      });
                    }}
                  />
                  <span style={{
                    fontSize:12
                  }} className="text-muted">
                    {`Assigned Seats (${account?.assignedSeatsCount || 0})`}
                  </span>
                </div>
              </div>
            </label></div>
          );
        }
      })
    );
  };

  if (!dataset) {
    return <></>;
  }

  return (
    <div
      className="container-fluid px-0 d-flex flex-column vh-100"
      style={{ width: "100%" }}
    >
      <AppLoader isLoading={isLoading} />
      <div
        className="pageTitle section-title text-center"
        style={{ background: "white" }}
      >
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <div>
                Assign {isTenantAdmin ? "Users" : "Accounts"} ({dataset.name})
              </div>
            </div>
            <div className="col-auto">
              <Tooltip title="Cancel">
                <IconButton color="primary" onClick={closeDrawer}>
                  <img src={RightArrow} width={20} alt="Close" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="container">
        {isTenantAdmin && (
          <div className="row px-3 py-2">
            <span
              className="text-muted d-flex justify-content-evenly fw-bold"
              style={{ fontSize: 13 }}
            >
              <span>Total Seats: {dataset.total_seats}</span>
              <span>Assigned Seats: {dataset.assigned_seats.length}</span>
              <span>
                Available Seats:{" "}
                {dataset.total_seats - dataset.assigned_seats.length}
              </span>
            </span>
          </div>
        )}
      </div>
      <Divider />
      <div className="px-3 pt-3">
        <IconAppInput
          label="Search..."
          icon={searchIcon}
          placeholder={
            isTenantAdmin ? "Search by Name/Email ID" : "Search by Company Name"
          }
          onEnter={(value) => {
            if (searchQuery !== value) {
              if (isTenantAdmin) {
                setData([]);
                setTotalRecords(0);
                setPage(0);
              }
              setSearchQuery(value);
            }
          }}
        />
        {!isTenantAdmin && (
          <div className="d-flex justify-content-center pt-2 gap-2">
            <AppInput
              type="number"
              label="Total Seats (All)"
              value={totalSeats?.toString()}
              onChange={(e) => {
                if (e.target.value >= 0 ) {
                  setTotalSeats(
                    parseInt(e.target.value.toString().replaceAll("-", ""))
                  );
                } else {
                  setTotalSeats(null);
                }
              }}
            />

            <Button
              disabled={!(totalSeats >= 0)}
              onClick={() => {
                setAccounts((prev) => {
                  return data.map((e) => {

                    const account = prev && prev.length > 0 ? prev.find((e1) => e1.accountNumber === e.accountNumber) : null;

                    return {
                      accountNumber: e.accountNumber,
                      maxSeatsCount: totalSeats,
                      assignedSeatsCount: account?.assignedSeatsCount || 0
                    };
                  });
                });
              }}
            >
              Update
            </Button>
          </div>
        )}
      </div>
      <div className="flex-grow-1 overflow-auto px-3" id="scrollableDiv">
        {isTenantAdmin ? (
          <InfiniteScroll
            // className="flex-grow-1"

            dataLength={data.length}
            next={() => addNewData()}
            hasMore={data.length < totalRecords}
            endMessage={
              !loader &&
              !data.length && (
                <p className="text-secondary mt-3 text-center">
                  No Result Found
                </p>
              )
            }
            scrollableTarget="scrollableDiv"
            loader={<div>Loading...</div>}
          >
            {handleUsersList()}
          </InfiniteScroll>
        ) : (
          handleAccountsList()
        )}
      </div>
      <Divider />
      <div className="p-3 d-flex justify-content-center">
        <Button className="mx-2" style={{ width: "100%" }} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AssignUsers;
