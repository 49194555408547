import * as React from "react";
import "./AppAutocomplete.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl } from "@mui/material";

export default function AppAutocomplete(props) {
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState("");

  const { label, options, className, errorMsg, validations, onChange, disabled, value } =
    props;

  React.useEffect(() => {
    if (value && value !== "") {
      setInputValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (event) => {
    let selectedValue = {
      target: {
        name: "",
        value: "",
      },
    };
    if (event.target.tagName === "INPUT") {
      selectedValue.target.value = event.target.value;
    } else {
      selectedValue.target.value = event.target.textContent;
    }
    selectedValue.target.name = "appAutoComplete";

    if (validations && validations['maxLength']) {
      if (
        selectedValue.target.value !== "" &&
        selectedValue.target.value.length > validations['maxLength']
      ) {
        setError(`${label} must be maximum ${validations['maxLength']} characters.`);
      } else {
        setError("");
      }
    }
    setInputValue(selectedValue.target.value);
    onChange(selectedValue);
  };

  return (
    <FormControl className={`selectContainer ${className ? className : ""}`}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        className="customAutocompleteField"
        disableClearable
        options={options.map((option) => option.name)}
        sx={{
          "&.Mui-selected": {
            background: "#673ab7",
            color: "#fff",
          }
        }}
        value={inputValue || (value ? value : "")}
        error={error !== "" || (errorMsg && errorMsg !== "") ? "error" : ""}
        renderInput={(params) => (
          <TextField
            className={`autocompleteInput ${inputValue !== "" || value ? "autoCompleteHaveValue" : ""
              }`}
            {...params}
            label={label}
            onChange={handleOnChange}
            InputProps={{
              ...params.InputProps,
              type: "text"
            }}
          />
        )}
        onSelect={handleOnChange}
        disabled={disabled ? disabled : false}
      />
      {(error !== "" || (errorMsg && errorMsg !== "")) && (
        // <span className="text-danger errorMsg">{error !== "" ? error : errorMsg ? errorMsg : ''}</span>
        <span className="text-danger errorMsg">
          {errorMsg && errorMsg !== "" ? errorMsg : error}
        </span>
      )}
    </FormControl>
  );
}
