import React, { useEffect, useState } from "react";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Chip,
  Drawer,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import useTable from "../../Components/useTable";
import { columns } from "./columns";
import editIcon from "../../assets/icons/edit-2-outline.svg";
import trashIcon from "../../assets/icons/trash-2-outline.svg";
import infoIcon from "../../assets/icons/info.svg";
import refreshOutline from "../../assets/icons/refresh-outline.svg";
import addPerson from "../../assets/icons/sidebar/person-add-outline.svg";
import AssignUsers from "./AssignUsers";
import {
  deleteSubscriptionDatasetBySuperAdmin,
  getAllSubscriptionDatasetsForSuperAdmin,
  getSubscriptionLicensesForTenantAdmin,
} from "../../Utils/services/subscription-datasets.service";
import { Auth } from "../../Utils/helpers";
import { format } from "date-fns";
import { ToastrService } from "../../Utils/services/tostr.service";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import searchIcon from "../../assets/icons/small_search.png";
import AppInput from "../../Components/AppInput/AppInput";

const sampleData = [
  {
    id: 0,
    name: "Dataset 1",
    state: "ID",
    type: "MVR",
    year: "2021",
    version: "Q4",
    createdAt: 1731951707247,
    createdBy: "John Doe",
    available_licenses: 13,
    status: "draft",
  },
  {
    id: 1,
    name: "Dataset 2",
    state: "ID",
    type: "MVR",
    year: "2021",
    version: "Q4",
    createdAt: 1731951707247,
    createdBy: "John Doe",
    available_licenses: 13,
    status: "processing",
  },
  {
    id: 2,
    name: "Dataset 3",
    state: "ID",
    type: "DL",
    year: "2021",
    version: "Q3",
    createdAt: 1731951707247,
    createdBy: "John Doe",
    available_licenses: 1,
    status: "success",
  },
  {
    id: 4,
    name: "Dataset 4",
    state: "ID",
    type: "DL",
    year: "2021",
    version: "Q3",
    createdAt: 1731951707247,
    createdBy: "John Doe",
    available_licenses: 1,
    status: "info",
  },
  {
    id: 5,
    name: "Dataset 5",
    state: "ID",
    type: "DL",
    year: "2021",
    version: "Q3",
    createdAt: 1731951707247,
    createdBy: "John Doe",
    available_licenses: 1,
    status: "published",
  },
  {
    id: 6,
    name: "Dataset 6",
    state: "ID",
    type: "DL",
    year: "2021",
    version: "Q3",
    createdAt: 1731951707247,
    createdBy: "John Doe",
    available_licenses: 1,
    status: "failed",
  },
];

const SubscriptionDatasets = () => {
  useDocumentTitle("Subscription Datasets");

  const history = useHistory();

  const [assignUsersDrawer, setAssignUsersDrawer] = useState();
  const [datasets, setDatasets] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState();
  const [failureReasonModal, setFailureReasonModal] = useState();
  const [lastViewedDataset, setLastViewedDataset] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    dataset: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDatasets, setFilteredDatasets] = useState([]);

  const isTenantAdmin =
    Auth.getUserRole().includes("COMPANY_ADMIN") ||
    Auth.getUserRole().includes("ADMIN");

  const filteredColumns = columns.filter((e) => {
    if (isTenantAdmin && e.tenantAdmin) {
      return e;
    } else if (!isTenantAdmin && !e.hideInAdmin) {
      return e;
    }
  });

  const handleSort = (cellId, sortDirection) => {
    setOrder(sortDirection.toLowerCase());
    setOrderBy(cellId);

    const sortedData = [...filteredDatasets].sort((a, b) => {
      let aValue = a[cellId];
      let bValue = b[cellId];

      // Handle special cases
      if (cellId === "status") {
        aValue = aValue.toString().toLowerCase();
        bValue = bValue.toString().toLowerCase();
      } else if (cellId === "createdAt" || cellId === "publishedAt") {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      } else if (cellId === "assigned_seats") {
        aValue = aValue?.length || 0;
        bValue = bValue?.length || 0;
      } else {
        aValue = aValue.toString().toLowerCase();
        bValue = bValue.toString().toLowerCase();
      }

      // Handle null/undefined values
      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      // Compare values
      if (sortDirection === "ASC") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    });

    setFilteredDatasets(sortedData);
  };

  const { TblContainer, TblHead } = useTable(null, filteredColumns);

  const getDatasets = async () => {
    setLoading(true);
    let data = [];
    try {
      if (!isTenantAdmin) {
        const res = await getAllSubscriptionDatasetsForSuperAdmin();
        if (res) {
          data = res;
        }
      } else {
        const res = await getSubscriptionLicensesForTenantAdmin();
        if (res && res.subscriptions) {
          data = res.subscriptions.map((dataset) => ({
            id: dataset.dataSet.id,
            name: dataset.dataSet.name,
            state: dataset.dataSet.state,
            type: dataset.dataSet.type,
            year: dataset.dataSet.year,
            version: dataset.dataSet.version,
            createdAt: dataset.dataSet.createdAt,
            createdBy: dataset.dataSet.createdBy,
            total_seats: dataset.maxSeatCount,
            assigned_seats: dataset.assignedSeats,
            available_seats: (
              dataset.maxSeatCount - dataset.assignedSeats.length
            ).toString(),
            status: dataset.status,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching datasets:", error);
      ToastrService.error("Failed to fetch datasets");
    }

    setDatasets(data);
    setFilteredDatasets(data);
    setSearchQuery("");
    setLoading(false);
  };

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const handleDeleteDataset = async (dataset) => {
    if (dataset.status === "PUBLISHED" && !deleteConfirmation.open) {
      setDeleteConfirmation({
        open: true,
        dataset: dataset,
      });
      return;
    }

    setDeleteConfirmation({ open: false, dataset: null });
    setLoading(true);
    setLoadingMessage("Deleting " + dataset.name);

    const request = await deleteSubscriptionDatasetBySuperAdmin(dataset.id);
    await getDatasets();

    setLoading(false);
    setLoadingMessage();
    ToastrService.success(
      "Dataset " + dataset.name + " was successfully Deleted!"
    );
  };

  const handleSearch = (e) => {
    const value = (e?.target?.value ?? e ?? "").toLowerCase().trim();
    setSearchQuery(value);

    if (!datasets) {
      return;
    }

    if (value === "") {
      console.log("Empty search - showing all datasets");
      setFilteredDatasets(datasets);
    } else {
      const searchResults = datasets.filter((dataset) => {
        const searchableFields = [dataset.name];

        return searchableFields.some((field) =>
          field?.toString().toLowerCase().includes(value)
        );
      });
      console.log("Search results:", searchResults);
      setFilteredDatasets(searchResults);
    }
  };

  useEffect(() => {
    const prev = query.get("prev");
    if (prev) {
      setLastViewedDataset(prev);
      history.replace(window.location.pathname);
    }
    getDatasets();
  }, []);

  const openFailureReasonModal = (id) => {
    setFailureReasonModal(id);
  };

  const closeFailureReasonModal = () => {
    setFailureReasonModal();
  };

  return (
    <div className="container-fluid">
      <AppLoader isLoading={loading} message={loadingMessage} />

      {deleteConfirmation.open && (
        <Modal
          isOpen={deleteConfirmation.open}
          size="lg"
          toggle={() => setDeleteConfirmation({ open: false, dataset: null })}
        >
          <ModalHeader
            toggle={() => setDeleteConfirmation({ open: false, dataset: null })}
          >
            Confirm Delete Dataset?
          </ModalHeader>
          <ModalBody>
            <div style={{ wordWrap: "break-word" }}>
              Tenant users will not be able to search using this dataset. Are
              you sure you want to delete this dataset?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() =>
                setDeleteConfirmation({ open: false, dataset: null })
              }
            >
              Cancel
            </Button>
            <Button
              className={"btn-danger"}
              color="danger"
              onClick={() => {
                deleteConfirmation.dataset &&
                  handleDeleteDataset(deleteConfirmation.dataset);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <div className="d-flex justify-content-between">
        <div className="pageTitle">Subscription Datasets</div>

        <div className="d-flex gap-2 align-items-center">
          <AppInput
            type="text"
            className="appSearchInput"
            placeholder="Search datasets..."
            onChange={handleSearch}
            value={searchQuery}
            style={{
              paddingLeft: "30px",
              position: "relative",
            }}
          />

          <Tooltip title="Refresh Datasets">
            <img
              src={refreshOutline}
              onClick={() => {
                getDatasets();
                // setLastViewedDataset();
              }}
              style={{
                cursor: "pointer",
                width: 24,
              }}
            />
          </Tooltip>

          {!isTenantAdmin && (
            <div className="accountListIcons">
              <Tooltip title="Add Subscription Dataset">
                <div
                  className="smallAddIcon"
                  style={{
                    marginTop: 4,
                  }}
                  onClick={() => {
                    history.push("/account/subscription/dataset");
                  }}
                ></div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {!loading && filteredDatasets && filteredDatasets.length === 0 && (
        <div className="text-danger">
          {searchQuery ? (
            `No datasets found matching "${searchQuery}"`
          ) : (
            <>
              No Dataset available.{" "}
              {isTenantAdmin
                ? "Contact WWI Admin to add datasets to your account!"
                : "Please add subscription datasets"}
            </>
          )}
        </div>
      )}
      <div className="row mt-4">
        <Drawer
          open={assignUsersDrawer ? true : false}
          onClose={() => setAssignUsersDrawer()}
          anchor="right"
          PaperProps={{
            style: {
              width: "50%",
              padding: 0,
            },
          }}
        >
          <AssignUsers
            selectedDataset={assignUsersDrawer}
            closeDrawer={() => setAssignUsersDrawer()}
            onSave={async () => {
              getDatasets();
            }}
            isTenantAdmin={isTenantAdmin}
          />
        </Drawer>
        <TblContainer>
          <TblHead
            style={{ position: "sticky", top: 48 }}
            handleSort={handleSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {filteredDatasets &&
              filteredDatasets.length > 0 &&
              filteredDatasets.map((data, i) => {
                return (
                  <TableRow
                    key={data.id + i}
                    className={lastViewedDataset === data.id ? "bg-light" : ""}
                  >
                    {filteredColumns.map((column) => {
                      if (column.id === "assigned_seats") {
                        return (
                          <TableCell key={column.id}>
                            {data.assigned_seats.length}
                          </TableCell>
                        );
                      }

                      if (column.id === "createdAt") {
                        return (
                          <TableCell key={column.id}>
                            {format(data.createdAt, "PPpp")}
                          </TableCell>
                        );
                      }

                      if (column.id === "publishedAt" && data.publishedAt) {
                        return (
                          <TableCell key={column.id}>
                            {data.publishedAt !== -1
                              ? format(data.publishedAt, "PPpp")
                              : "-"}
                          </TableCell>
                        );
                      }

                      if (column.id === "actions") {
                        return (
                          <TableCell align="right" key={column.id} width={100}>
                            {!isTenantAdmin &&
                            data["status"].toString().toLowerCase() ===
                              "draft" ? (
                              <Tooltip title="Continue Editing">
                                <img
                                  src={editIcon}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push(
                                      "/account/subscription/dataset?id=" +
                                        data.id
                                    );
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                            {(isTenantAdmin ||
                              (!isTenantAdmin &&
                                data["status"].toString().toLowerCase() ===
                                  "published")) && (
                              <Tooltip
                                title={
                                  isTenantAdmin
                                    ? "Assign Users"
                                    : "Assign Accounts"
                                }
                              >
                                <img
                                  src={addPerson}
                                  style={{
                                    cursor: "pointer",
                                    width: 24,
                                  }}
                                  onClick={() => {
                                    setAssignUsersDrawer(data);
                                  }}
                                />
                              </Tooltip>
                            )}
                            {!isTenantAdmin &&
                            data["status"].toString().toLowerCase() !==
                              "processing" ? (
                              <span>
                                {data["status"].toString().toLowerCase() ===
                                  "failed" && (
                                  <>
                                    {" "}
                                    <Tooltip title={"Show Failure Reason"}>
                                      <img
                                        src={infoIcon}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openFailureReasonModal(data.id);
                                        }}
                                      />
                                    </Tooltip>
                                    <Modal
                                      isOpen={
                                        failureReasonModal &&
                                        failureReasonModal === data.id
                                      }
                                      size="lg"
                                      toggle={() => closeFailureReasonModal()}
                                    >
                                      <ModalHeader
                                        toggle={() => closeFailureReasonModal()}
                                      >
                                        Failure Reason ({data.name})
                                      </ModalHeader>
                                      <ModalBody>
                                        <div style={{ wordWrap: "break-word" }}>
                                          {data.failureReason}
                                        </div>
                                      </ModalBody>
                                    </Modal>
                                  </>
                                )}
                                <Tooltip title="Delete Dataset">
                                  <img
                                    src={trashIcon}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 10,
                                    }}
                                    onClick={() => handleDeleteDataset(data)}
                                  />
                                </Tooltip>
                              </span>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        );
                      }

                      if (column.id === "status") {
                        return (
                          <TableCell key={column.id}>
                            <Chip
                              size="small"
                              label={data[column.id]}
                              style={{
                                textTransform: "uppercase",
                              }}
                              color={
                                data[column.id].toString().toLowerCase() ===
                                "published"
                                  ? "success"
                                  : data[column.id].toString().toLowerCase() ===
                                    "failed"
                                  ? "error"
                                  : data[column.id].toString().toLowerCase() ===
                                    "processing"
                                  ? "info"
                                  : "default"
                              }
                            />
                          </TableCell>
                        );
                      }

                      if (column.id === "name") {
                        return (
                          <TableCell key={column.id}>
                            {data[column.id] || "-"}
                            {lastViewedDataset === data.id && (
                              <div
                                className="text-small"
                                style={{ fontSize: 12 }}
                              >
                                Recently Configured
                              </div>
                            )}
                          </TableCell>
                        );
                      }

                      if (column.id === "total_seats") {
                        return (
                          <TableCell key={column.id}>
                            {data[column.id]}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={column.id}>
                          {data[column.id] || "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </TblContainer>
      </div>
    </div>
  );
};

export default SubscriptionDatasets;
