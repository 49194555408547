import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import BatchFilePreview from "../../Containers/BatchFilePreview/BatchFilePreview";
import Dashboard from "../../Containers/Dashboard/Dashboard";
import { maxFileSize } from "../../shared/constants/constants";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { ToastrService } from "../../Utils/services/tostr.service";
import "./BatchSearch.css";
import { batchFileUploadForCA } from "../../Utils/services/batchSearch.service";
import AppLoader from "../../Components/AppLoader/AppLoader";

const successModalActionBtns = [{ name: "OK", submit: true }];

export default function BatchSearch() {
  useDocumentTitle("Batch Search");

  const [openPreview, setOpenPreview] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [file, setFile] = useState();


  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const handleFile = async (files, onDropHandle) => {
    if (onDropHandle) {
      setFile(files);
      // console.log("=====>>", files);
      // setOpenPreview(true);
      setIsLoading(true);
      try {
        let response = await batchFileUploadForCA(files);
        // console.log(response);

        if (response.code === 200) {
          ToastrService.success(response.message);
          setIsLoading(false);
        } else {
          ToastrService.error(response.message);
          setIsLoading(false);
        }
      } catch (error) {
        // console.log(error);
        setIsLoading(false);
        setError(error.message);
      }
    } else {
      if (files.length) {
        if (files[0].type === "text/csv" && files[0].size < maxFileSize) {
          setFile(files[0]);
          // console.log("=====>>", files[0]);
          // setOpenPreview(true);
          setIsLoading(true);
          try {
            let response = await batchFileUploadForCA(files[0]);
            // console.log(response);
    
            if (response.code === 200) {
              ToastrService.success(response.message);
              setIsLoading(false);
            } else {
              ToastrService.error(response.message);
              setIsLoading(false);
            }
          } catch (error) {
            // console.log(error);
            setIsLoading(false);
            setError(error.message);
          }
        } else {
          ToastrService.error(
            "Please make sure that the file format is csv and the size is not more than 15MB."
          );
        }
      }
    }
  };

  const handleFileDrop = (ev) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          if (ev.dataTransfer.items[i].type === "text/csv") {
            const file = ev.dataTransfer.items[i].getAsFile();
            if (file.size > maxFileSize) {
              ToastrService.error(
                "Please make sure that the file format is csv and the size is not more than 15MB."
              );
              break;
            }
            handleFile(file, true);
            break;
          } else {
            ToastrService.error(
              "Please make sure that the file format is csv and the size is not more than 15MB."
            );
          }
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files;
        handleFile(file);
      }
    }
  };

  const downloadSampleFile = (e) => {
    e.stopPropagation();
    const anchor = document.createElement("a");
    anchor.setAttribute("href", "/files/sample_batch_search.csv");
    anchor.setAttribute("target", "_blank");
    anchor.setAttribute("download", "sample_batch_search.csv");
    anchor.click();
  };

  return (
    <>
      {/* {openPreview && (
        <BatchFilePreview
          file={file}
          onClose={(flag) => {
            setOpenPreview(false);
            setFile(null);
            if (flag) {
              setSuccessModal(true);
            }
          }}
        />
      )} */}
      <AppLoader isLoading={isLoading} />
      <Dashboard />
      {/* <DashboardNavTabs active="batch" /> */}
      <div className="container-fluid mt-4">
        <Row className="batchSearchHeader">
          <Col md={{ size: 6 }}>
            <div className="batchSearchTitle">Batch Search</div>
          </Col>
          <Col md={{ size: 6 }}></Col>
        </Row>
        <div className="batchSearchSection">
          <Row>
            <Col
              md={{ size: 6 }}
              id="draggableFileContainer"
              className="batchSearchContainer"
              onClick={() => document.getElementById("batchFileInput").click()}
              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="batchSearchDiv">
                <div className="batchSearchDivTitle">
                  <div className="smallUploadIconBatchSearch"></div>
                  <div className="batchSearchText">
                    <div className="batchSearchTextTitle">
                      Want to do a Batch Search?
                    </div>
                    <div className="batchSearchSubTitle">
                      Upload .csv file of max 15MB
                    </div>
                  </div>
                </div>
                <div className="batchSearchSubTitle sampleInputFileText">
                  (Download the Sample File here)
                  {/* <Link
                    to="/files/sample_batch_search.csv"
                    className="link sampleFileLink"
                    target="_blank"
                    download
                    onClick={(e) => e.stopPropagation()}
                  >
                    sample file
                  </Link>
                  ) */}
                </div>
                <div className="mt-1">
                  <Button
                    className="batchSampleFileBtn"
                    onClick={(e) => downloadSampleFile(e)}
                  >
                    Download Sample File
                  </Button>
                </div>
              </div>
              <div className="batchSearchIcon"></div>
            </Col>
          </Row>
        </div>
      </div>
      <input
        type="file"
        accept=".csv"
        id="batchFileInput"
        onClick={(e) => (e.currentTarget.value = "")}
        onChange={(e) => handleFile(e.target.files)}
        hidden
      />
      {successModal && (
        <ConfirmModal
          title="Thank you for uploading the file. We will notify you once the results are ready for you to download."
          actionBtns={successModalActionBtns}
          hideCloseIcon={true}
          backdropCloseDisabled={true}
          onClose={(flag) => {
            setSuccessModal(false);
            history.push("/account/batchHistory");
          }}
        >
          <br />
        </ConfirmModal>
      )}
    </>
  );
}
