export const columns = {
  DL: [
    { id: "LicNum", label: "License Number" },
    { id: "Class", label: "Class" },
    { id: "ExpirationDate", label: "Exp Date" },
    { id: "LastName", label: "Last Name" },
    { id: "FirstName", label: "First Name" },
    { id: "MidName", label: "Middle Name" },
    { id: "Suffix", label: "Suffix" },
    { id: "BirthDate", label: "Date of Birth" },
    { id: "Height", label: "Height" },
    { id: "Sex", label: "Gender" },
    { id: "MailAddrSt1", label: "Street 1 (Legal)" },
    { id: "MailAddrSt2", label: "Street 2 (Legal)" },
    { id: "MailAddrCity", label: "City (Legal)" },
    { id: "MailAddrState", label: "State (Legal)" },
    { id: "MailAddrZip", label: "Zip (Legal)" },
    { id: "ResAddrSt1", label: "Street 1 (Residential)" },
    { id: "ResAddrSt2", label: "Street 2 (Residential)" },
    { id: "ResAddrCity", label: "City (Residential)" },
    { id: "ResAddrState", label: "State (Residential)" },
    { id: "ResAddrZip", label: "Zip (Residential)" },
    {id:"CutDate", label:"Cut Date"}
  ],
  MVR: [
    { id: "VIN", label: "VIN" },
    { id: "ModelYear", label: "Model Year" },
    { id: "Manufacturer", label: "Make" },
    { id: "ModelName", label: "Model" },
    { id: "PriColor", label: "Color (Primary)" },
    { id: "SecColor", label: "Color (Secondary)" },
    { id: "RegIssueDate", label: "Registration Issue Date" },
    { id: "RegEffectiveDate", label: "Registration Effective Date" },
    { id: "RegExpDate", label: "Registration Expiration Date" },
    { id: "RegPrefix", label: "Registration Prefix" },
    { id: "RegNumber", label: "Registration Number" },
    { id: "RegColor", label: "Plate Color" },
    { id: "InsuranceCode", label: "Insurance Code" },
    { id: "GaragedName", label: "Garaged Name" },
    { id: "Owner1MailStreet1", label: "Owner 1 Mailing Street 1" },
    { id: "Owner1MailStreet2", label: "Owner 1 Mailing Street 2" },
    { id: "Owner1MailCity", label: "Owner 1 Mailing City" },
    { id: "Owner1MailState", label: "Owner 1 Mailing State" },
    { id: "Owner1MailZip", label: "Owner 1 Mailing Zip" },
    { id: "Owner1ResStreet1", label: "Owner 1 Residential Street 1" },
    { id: "Owner1ResStreet2", label: "Owner 1 Residential Street 2" },
    { id: "Owner1ResCity", label: "Owner 1 Residential City" },
    { id: "Owner1ResState", label: "Owner 1 Residential State" },
    { id: "Owner1ResZip", label: "Owner 1 Residential Zip" },
    { id: "Owner1LastName", label: "Owner 1 Last Name" },
    { id: "Owner1FirstName", label: "Owner 1 First Name" },
    { id: "Owner1MiddleName", label: "Owner 1 Middle Name" },
    { id: "Owner1Suffix", label: "Owner 1 Suffix" },
    { id: "Owner1DOB", label: "Owner 1 Date of Birth" },
    { id: "Owner1LicNum", label: "Owner 1 License Number" },
    { id: "Owner2LastName", label: "Owner 2 Last Name" },
    { id: "Owner2FirstName", label: "Owner 2 First Name" },
    { id: "Owner2MiddleName", label: "Owner 2 Middle Name" },
    { id: "Owner2Suffix", label: "Owner 2 Suffix" },
    { id: "Owner2DOB", label: "Owner 2 Date of Birth" },
    { id: "Owner2LicNum", label: "Owner 2 License Number" },
    { id: "TitleNum", label: "Title Number" },
    { id: "VehTypeName", label: "Vehicle Type" },
    { id: "NumCylinders", label: "Cylinders" },
    { id: "NumPassengers", label: "Passengers" },
    { id: "NumDoors", label: "Doors" },
    { id: "Weight", label: "Weight" },
    { id: "CorpName", label: "Corporation Name" },
    { id: "LienHolder1Name", label: "Lien Holder Name" },
    { id: "LienHolder1Address", label: "Lien Holder Address" },
    { id: "LienHolder1City", label: "Lien Holder City" },
    { id: "LienHolder1State", label: "Lien Holder State" },
    { id: "LienHolder1Zip", label: "Lien Holder Zip" },
    {id:"CutDate", label:"Cut Date"}
  ],
};
