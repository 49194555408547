import { Box, TableBody, TableCell, TableRow, styled } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Redirect, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import AppLoader from "../../Components/AppLoader/AppLoader";
import UploadFilesModel from "../../Components/UploadFilesModel/UploadFilesModel";
import useTable from "../../Components/useTable";
import {
  checkFileStatus,
  getListOfAllTanentFilesUsers,
} from "../../Utils/services/downloads.service";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import useToken from "../../shared/hooks/useToken";
import { useLocation } from "react-router-dom";
import "./UploadFiles.css";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ToastrService } from "../../Utils/services/tostr.service";
import uploadFileIcon from "../../assets/icons/sidebar/upload-icon-white.svg";

const headCellsForDownload = [
  { id: "fileName", label: "FILE NAME", width: "25%", disableSorting: true },
  {
    id: "uploadedOn",
    label: "UPLOADED ON",
    width: "25%",
    disableSorting: true,
  },
  {
    id: "scanstatus",
    label: "SCAN STATUS",
    width: "25%",
    disableSorting: true,
  },
  { id: "action", label: "ACTION", width: "25%", disableSorting: true },
];

const ToolTipUserList = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "#000",
    maxWidth: 280,
    maxHeight: 150,
    overflowY: "scroll",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const UploadFiles = () => {
  useDocumentTitle("Upload Files");

  const [fileList, setFileList] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { TblContainer, TblHead } = useTable(fileList, headCellsForDownload);
  const { token } = useToken();
  const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isDetele, setIsDetele] = useState(false);
  const [fileId, setFileId] = useState(0);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [fileName, setFileName] = useState("");
  const location = useLocation();
  const [showListofUsers, setShowListofUsers] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [statusApiCallRepeat, setStatusApiCallRepeat] = useState(false);
  const [file, setFile] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getListOfDownloads();
  }, []);

  const getListOfDownloads = async () => {
    setIsLoading(true);
    try {
      const response = await getListOfAllTanentFilesUsers();

      if (response.code === 200) {
        setFileList(response.body);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleUploadFileUsers = () => {
    setSuccessModal(true);
    setIsUpdate(false);
    setIsUpload(true);
    setIsDetele(false);
  };

  function redirectTo(path, fileName) {
    history.push(path, { state: `${fileName}` });
  }

  const handleDeleteFile = (item) => {
    setSuccessModal(true);
    setIsUpdate(false);
    setIsUpload(false);
    setIsDetele(true);
    setFileId(item?.fileId);
    setFileName(item?.fileName);
  };

  const setvisibleBox = (id) => {
    setRecordId(id);
    setShowListofUsers(true);
  };

  const setdisableBox = (id) => {
    id && setRecordId("");
    setShowListofUsers(false);
  };

  const apiCallRepeated = async () => {
    const interval = setInterval(async () => {
      const fileStatusResult = await checkFileStatus(file?.name);

      if (fileStatusResult.body === "CLEAN") {
        clearInterval(interval);
        getListOfDownloads();
        setStatusApiCallRepeat(false);
      } else if (fileStatusResult.body === "INFECTED") {
        clearInterval(interval);
        ToastrService.error(
          `File ${file?.name} is corrupted, it will be deleted by system`
        );
        getListOfDownloads();
        setStatusApiCallRepeat(false);
      }
    }, 30000);
  };

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <div className="d-block">
        <div className="page-content">
          <AppLoader isLoading={isLoading} />
          <div className="container-fluid">
            <div className="userListSection">
              <Row className="mb-2">
                <Col lg={{ size: 6 }}>
                  <div className="pageTitle">Subscription Portal</div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={{ size: 10 }}>
                  {error && (
                    <span className="text-danger errorMsg">{error}</span>
                  )}
                </Col>
                <Col
                  xs={{ size: 2, justifyContent: "flex-end" }}
                  className="d-flex justify-content-end"
                >
                  <Button
                    className="d-flex align-items-center gap-2"
                    title="Upload File"
                    onClick={handleUploadFileUsers}
                  >
                    <div title="Upload Files">
                      <img src={uploadFileIcon} alt="Upload Icon" />
                    </div>
                    Upload File
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <InfiniteScroll
                    className="userListTable"
                    dataLength={fileList?.length}
                    height="100%"
                  >
                    <TblContainer>
                      <TblHead />
                      <TableBody>
                        {fileList?.length ? (
                          fileList?.map((item) => (
                            <TableRow key={item.fileId}>
                              <TableCell
                                title={item.fileName}
                                className="companyNameColumn"
                              >
                                {item?.fileName?.length > 40
                                  ? item?.fileName
                                    ?.substring(0, 40)
                                    ?.concat("...")
                                  : item?.fileName}
                              </TableCell>
                              <TableCell
                                title={format(
                                  new Date(
                                    item?.updatedAt
                                      ? item?.updatedAt
                                      : item?.createdAt
                                  ),
                                  "yyyy-MM-dd"
                                )}
                              >
                                {format(
                                  new Date(
                                    item?.updatedAt
                                      ? item?.updatedAt
                                      : item?.createdAt
                                  ),
                                  "yyyy-MM-dd"
                                )}
                              </TableCell>
                              <TableCell title={item.fileScanStatus}>
                                {item?.fileScanStatus}
                              </TableCell>
                              <TableCell className="accountListIconSection text-start">
                                <div
                                  title="Manage Access"
                                  className="smallEditIcon"
                                  onClick={() => redirectTo("/account/uploads/" + item?.fileId, item?.fileName)}
                                >
                                </div>
                                <div
                                  title="Delete File & Users"
                                  className="smallDeleteIcon"
                                  onClick={() => handleDeleteFile(item)}
                                ></div>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={headCellsForDownload?.length}
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              No Result Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </TblContainer>
                  </InfiniteScroll>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <UploadFilesModel
        openModel={successModal}
        title={
          isDetele &&
          `Delete File: ${fileName?.length > 30
            ? fileName?.substring(0, 30)?.concat("...")
            : fileName
          }`
        }
        closeModel={setSuccessModal}
        isUpload={isUpload}
        accountId={id}
        accountNo={location?.state}
        isDetele={isDetele}
        fileId={(isDetele && fileId)}
        getListOfDownloads={getListOfDownloads}
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setFileName={setFileName}
        setStatusApiCallRepeat={setStatusApiCallRepeat}
        file={file}
        setFile={setFile}
        apiCallRepeated={apiCallRepeated}
      />
    </>
  );
};

export default UploadFiles;
