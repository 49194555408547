import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import AppAutocomplete from "../../Components/AppAutocomplete/AppAutocomplete";
import AppInput from "../../Components/AppInput/AppInput";
import AppLoader from "../../Components/AppLoader/AppLoader";
import AppSelect from "../../Components/AppSelect/AppSelect";
import {
  addNewTenant,
  createAccountKey,
  editTenant,
  getBatchFileSources,
  getTenantDetails,
} from "../../Utils/services/accounts.service";
import { ToastrService } from "../../Utils/services/tostr.service";
import {
  Status,
  accountTypesLists,
  createMask,
  defaultBatchSearchProvider,
  destroyMask,
  domainName,
  emailRegex,
  onlyText,
  roles,
  titles,
  websiteText,
} from "../../shared/constants/constants";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import useStatesList from "../../shared/hooks/useStatesList";
import "./AddAccount.css";
import {
  Box,
  Divider,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import AppTabs from "../../Components/AppTabs/AppTabs";
import AppTab from "../../Components/AppTabs/AppTab";
import {
  createSubscriptionDatasetForSuperAdmin,
  getAllSubscriptionDatasetsForSuperAdmin,
  getSubscriptionLicensesForTenantAccount,
  updateSubscriptionLicensesForTenantAccount,
} from "../../Utils/services/subscription-datasets.service";
import { useFieldArray, useForm } from "react-hook-form";

export default function AddAccount(props) {
  useDocumentTitle("Add Account");
  const initialState = {
    fields: {},
    error: {},
  };

  const { watch, setValue, getValues, control } = useForm();

  const [formValues, setFormValues] = useState(initialState);
  const [oldInfo, setOldInfo] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [viewForm, setViewForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [apiKeyValue, setApiKeyValue] = useState("");
  const [sourcesList, setSourcesList] = useState([]);
  const [activeTab, setActiveTab] = useState("account-details");

  const [availableDatasets, setAvailableDatasets] = useState([]);
  // const [assignedDatasets, setAssignedDatasets] = useState([]);

  const assignedDatasets = useFieldArray({
    name: "assignedDatasets",
    control,
    keyName: "key",
  });

  const history = useHistory();

  const { search } = useLocation();

  const { statesList, setStateList, loader } = useStatesList();

  const query = new URLSearchParams(search);

  useEffect(() => {
    setActiveTab("account-details");
    setFormValues(initialState);
    getStateList();
    getBatchFileSourceList();

    const viewFlag = query.get("view");
    const editFlag = query.get("edit");
    const userId = query.get("id");
    setViewForm(false);
    setEditForm(false);

    if (viewFlag) setViewForm(true);
    if (editFlag) setEditForm(true);
    if (userId) getUserInfo(userId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getStatesList = () => {
    return statesList?.filter((ele) => ele?.id !== 0);
  };

  const getStateList = async () => {
    if (statesList && !statesList.length) {
      await setStateList();
    }
  };

  const handleLicenseFetch = async (id) => {
    const allDatasets = await getAllSubscriptionDatasetsForSuperAdmin();
    const assignedLicenses = await getSubscriptionLicensesForTenantAccount(id);

    if (
      assignedLicenses &&
      assignedLicenses.subscriptions &&
      assignedLicenses.subscriptions.length > 0
    ) {
      setValue(
        "assignedDatasets",
        assignedLicenses.subscriptions.map((subscription) => ({
          id: subscription.dataSet.id,
          name: subscription.dataSet.name,
          seats: subscription.maxSeatCount,
          assignedSeats: subscription.assignedSeats.length,
        }))
      );
    }

    if (allDatasets) {
      setAvailableDatasets(allDatasets.filter((e) => e.status === "PUBLISHED"));
    }

    // await handleSetAvailableDatasets(assignedLicenses);
  };

  const handleSaveSubscriptionLicense = async () => {
    setIsLoading(true);
    const data = getValues();

    let datasets = {};
    if (data.assignedDatasets && data.assignedDatasets.length > 0)
      data.assignedDatasets.map((dataset) => {
        datasets[dataset.id] = parseInt(dataset.seats.toString());
      });

    const res = await updateSubscriptionLicensesForTenantAccount(
      oldInfo.accountNumber,
      {
        ...datasets,
      }
    );

    if (res && res.request && res.request.response) {
      //console.log(JSON.parse(res.request.response).message);
      ToastrService.error(JSON.parse(res.request.response).message);
    } else {
      ToastrService.success("Subscription Details saved!");
      props.history.push("/account/list");
    }

    setIsLoading(false);
  };

  const getUserInfo = async (id) => {
    setIsLoading(true);

    try {
      let result = await getTenantDetails(id);
      if (result.code === 200) {
        setOldInfo({ ...result.body });
        let data = { ...result.body };
        data = {
          ...data,
          ...result.body.userDto,
          phoneNumber: createMask(result.body.userDto.phoneNumber.toString()),
          isActive: result.body.isActive ? "Active" : "InActive",
          confirmEmail: result.body.userDto.email,
        };
        setFormValues({ fields: data, error: {} });

        if (result.body.accountType.includes("SUBSCRIPTION")) {
          await handleLicenseFetch(result.body.accountNumber);
        }

        if (result.body && result.body.apiKey) {
          setApiKeyValue(result.body.apiKey);
        }
      } else {
        ToastrService.error(result.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(error.message);
    }
  };

  const getBatchFileSourceList = async () => {
    setIsLoading(true);
    try {
      let response = await getBatchFileSources();

      if (response.code === 200) {
        setSourcesList(response.body);
      } else {
        setFormError(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setFormError(error.message);
    }
  };

  const handleChange = (e, field) => {
    setFormError("");
    let fields = formValues.fields;

    let value =
      e?.target?.name === "checkbox" ? e?.target?.checked : e?.target?.value;

    if (field === "phoneNumber") {
      let temp = destroyMask(e.target.value);
      value = createMask(temp);
    }

    if (field === "subdomain") {
      value = e.target.value.toLowerCase();
    }

    if (field === "billingReferenceCode") {
      value = e.target.value.replace(/[^a-zA-Z0-9-]/g, "");
    }

    fields[field] = value;

    let err = formValues.error;
    err[field] = "";

    setFormValues({ ...formValues, fields, err });

    if (
      apiKeyValue === "" &&
      field === "isApiSearchAllowed" &&
      e.target.checked
    ) {
      createApiKey();
    }
  };

  const handleValidation = () => {
    let fields = formValues.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["tenant"] || fields["tenant"].trim() === "") {
      formIsValid = false;
      errors["tenant"] = "Company Name is required.";
    } else {
      if (fields["tenant"].length > 50) {
        formIsValid = false;
        errors["tenant"] = "Company Name must be maximum 50 characters.";
      }
    }

    if (fields["website"] && fields["website"].length > 50) {
      formIsValid = false;
      errors["website"] = "Company Website URL must be maximum 50 characters.";
    }

    if (!fields["state"]) {
      formIsValid = false;
      errors["state"] = "State is required.";
    }

    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "City is required.";
    }

    if (!fields["zip"]) {
      formIsValid = false;
      errors["zip"] = "Zip Code is required.";
    } else {
      if (fields["zip"].length < 5) {
        formIsValid = false;
        errors["zip"] = "Zip Code must be minimum 5 characters.";
      }
    }

    if (!fields["addressLine1"] || fields["addressLine1"].trim() === "") {
      formIsValid = false;
      errors["addressLine1"] = "Address Line 1 is required.";
    } else {
      if (fields["addressLine1"].length > 50) {
        formIsValid = false;
        errors["addressLine1"] =
          "Address Line 1 must be maximum 50 characters.";
      }
    }

    if (fields["addressLine2"]) {
      if (fields["addressLine2"].length > 50) {
        formIsValid = false;
        errors["addressLine2"] =
          "Address Line 2 must be maximum 50 characters.";
      }
    }

    if (fields["title"]) {
      if (fields["title"].length > 50) {
        formIsValid = false;
        errors["title"] = "Title must be maximum 50 characters.";
      }
    }

    if (fields["isBatchSearchAllowed"] && !fields["batchSearchProvider"]) {
      formIsValid = false;
      errors["batchSearchProvider"] = "Batch Search Provider is required.";
    }

    if (fields["allowedStates"]) {
      formIsValid = true;
    } else {
      if (!fields["allowedStates"]) {
        formIsValid = false;
        errors["allowedStates"] = "Allow States is required.";
      }
    }

    if (fields["accountType"]) {
      formIsValid = true;
    } else {
      if (!fields["accountType"]) {
        formIsValid = false;
        errors["accountType"] = "Please select account type is required.";
      }
    }

    if (editForm) {
      if (!fields["isActive"]) {
        formIsValid = false;
        errors["isActive"] = "Status is required.";
      }
    } else {
      if (!fields["subdomain"] || fields["subdomain"].trim() === "") {
        formIsValid = false;
        errors["subdomain"] = "Subdomain Name is required.";
      } else if (fields["subdomain"].length > 10) {
        formIsValid = false;
        errors["subdomain"] = "Subdomain Name must be maximum 10 characters.";
      } else if (!websiteText.test(fields["subdomain"])) {
        formIsValid = false;
        errors["subdomain"] = "Invalid Subdomain Name. Only text allowed.";
      }
    }

    if (!fields["firstName"] || fields["firstName"].trim() === "") {
      formIsValid = false;
      errors["firstName"] = "First Name is required.";
    } else if (fields["firstName"].length > 25) {
      formIsValid = false;
      errors["firstName"] = "First Name must be maximum 25 characters.";
    } else if (!onlyText.test(fields["firstName"])) {
      formIsValid = false;
      errors["firstName"] = "Invalid First Name. Only text allowed.";
    }

    if (!fields["lastName"] || fields["lastName"].trim() === "") {
      formIsValid = false;
      errors["lastName"] = "Last Name is required.";
    } else if (fields["lastName"].length > 25) {
      formIsValid = false;
      errors["lastName"] = "Last Name must be maximum 25 characters.";
    } else if (!onlyText.test(fields["lastName"])) {
      formIsValid = false;
      errors["lastName"] = "Invalid Last Name. Only text allowed.";
    }

    if (!fields["phoneNumber"]) {
      formIsValid = false;
      errors["phoneNumber"] = "Phone Number is required.";
    } else if (destroyMask(fields["phoneNumber"]).length < 10) {
      formIsValid = false;
      errors["phoneNumber"] = "Phone Number must be minimum 10 characters.";
    } else if (destroyMask(fields["phoneNumber"]).length > 10) {
      formIsValid = false;
      errors["phoneNumber"] = "Phone Number must be maximum 10 characters.";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email ID is required.";
    } else if (fields["email"].length > 50) {
      formIsValid = false;
      errors["email"] = "Email ID must be maximum 50 characters.";
    } else {
      if (!emailRegex.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Invalid Email ID.";
      }
    }

    if (!fields["confirmEmail"]) {
      formIsValid = false;
      errors["confirmEmail"] = "Confirm Email ID is required.";
    } else if (fields["confirmEmail"].length > 50) {
      formIsValid = false;
      errors["confirmEmail"] =
        "Confirm Email ID must be maximum 50 characters.";
    } else {
      if (!emailRegex.test(fields["confirmEmail"])) {
        formIsValid = false;
        errors["confirmEmail"] = "Invalid Confirm Email ID.";
      }
    }

    if (fields["email"] !== fields["confirmEmail"]) {
      formIsValid = false;
      errors["confirmEmail"] = "Email ID's don’t match.";
    }

    // if (!fields["allowedStateIdList"]) {
    //   formIsValid = false;
    //   errors["allowedStateIdList"] = "Allow search in state is required.";
    // }

    setFormValues({ ...formValues, error: errors });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const {
      accountId,
      accountNumber,
      tenant,
      website,
      addressLine1,
      addressLine2,
      state,
      zip,
      title,
      firstName,
      lastName,
      phoneNumber,
      email,
      city,
      isActive,
      subdomain,
      isBatchSearchAllowed,
      isApiSearchAllowed,
      batchSearchProvider,
      allowedStates,
      accountType,
      billingReferenceCode,
    } = formValues.fields;

    let isAllowTobatchsearch = null;

    if (isBatchSearchAllowed && batchSearchProvider) {
      isAllowTobatchsearch = true;
    } else if (!isBatchSearchAllowed) {
      isAllowTobatchsearch = true;
    } else {
      isAllowTobatchsearch = false;
    }

    if (handleValidation() && isAllowTobatchsearch) {
      let userRoles = [roles.company_admin, roles.search_admin];
      if (isBatchSearchAllowed) {
        userRoles.push(roles.batch_admin);
      }
      try {
        let response;
        if (!editForm) {
          response = await addNewTenant({
            tenant,
            website,
            addressLine1,
            addressLine2,
            state,
            city,
            billingReferenceCode,
            batchSearchProvider: isBatchSearchAllowed
              ? batchSearchProvider
              : defaultBatchSearchProvider,
            zip,
            subdomain,
            isBatchSearchAllowed: isBatchSearchAllowed
              ? isBatchSearchAllowed
              : false,
            isApiSearchAllowed: isApiSearchAllowed ? isApiSearchAllowed : false,
            apiKey: apiKeyValue,
            userDto: {
              title: title ? title.trim() : "",
              firstName,
              lastName,
              phoneNumber: destroyMask(phoneNumber),
              email,
              roles: userRoles,
            },
            allowedStates: allowedStates || [],
            accountType,
          });
        } else {
          response = await editTenant(
            {
              accountNumber,
              tenant,
              website,
              addressLine1,
              addressLine2,
              state,
              city,
              zip,
              billingReferenceCode,
              isBatchSearchAllowed: isBatchSearchAllowed
                ? isBatchSearchAllowed
                : false,
              batchSearchProvider: isBatchSearchAllowed
                ? batchSearchProvider
                : defaultBatchSearchProvider,
              isActive: isActive === "Active" ? true : false,
              isApiSearchAllowed: isApiSearchAllowed
                ? isApiSearchAllowed
                : false,
              apiKey: apiKeyValue,
              userDto: {
                title: title ? title.trim() : "",
                firstName,
                lastName,
                phoneNumber: destroyMask(phoneNumber),
                email,
                roles: userRoles,
              },
              allowedStates: allowedStates || [],
              accountType,
            },
            accountId
          );
        }

        if (response.code === 200) {
          ToastrService.success(response.message);
          props.history.push("/account/list");
        } else {
          setFormError(response.message);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setFormError(error.message);
      }
    } else {
      setIsLoading(false);
      setFormError(
        "Please provide information for required fields highlighted in red."
      );
    }
  };

  const createApiKey = async () => {
    setIsLoading(true);
    try {
      let response = await createAccountKey();

      if (response.code === 200) {
        setApiKeyValue(response.body);
      } else {
        setFormError(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setFormError(error.message);
    }
  };

  function clearForm() {
    setFormValues(initialState);
    history.push("/account/list");
  }

  function AccountDetailsForm() {
    return (
      <form onSubmit={handleSubmit}>
        <div className="addAccountSection">
          <div className="mb-2">
            <Row>
              <Col md={{ size: 12 }}>
                <div className="pageTitle sectionTitle">Company Details</div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6 }} className="addAccountInputs">
                {(viewForm || editForm) && (
                  <AppInput
                    label="Account Number"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.accountNumber}
                    disabled={viewForm || editForm}
                  />
                )}

                <AppInput
                  label="Company Name"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.tenant}
                  errorMsg={formValues.error.tenant}
                  validations={{
                    required: true,
                    maxLength: 50,
                  }}
                  onChange={(e) => handleChange(e, "tenant")}
                  disabled={viewForm ? true : editForm ? false : false}
                />
                <AppInput
                  label="Company Website URL"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.website}
                  errorMsg={formValues.error.website}
                  validations={{
                    maxLength: 50,
                  }}
                  onChange={(e) => handleChange(e, "website")}
                  disabled={viewForm ? true : editForm ? false : false}
                />
                <AppInput
                  label="Subdomain Name"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.subdomain}
                  helperText={
                    formValues.fields.subdomain
                      ? `Portal URL: https://${formValues.fields.subdomain}.${window.location.host}`
                      : ""
                  }
                  errorMsg={formValues.error.subdomain}
                  validations={{
                    required: true,
                    maxLength: 10,
                    pattern: websiteText,
                  }}
                  onChange={(e) => handleChange(e, "subdomain")}
                  disabled={viewForm ? true : editForm ? true : false}
                />
                {(formValues.fields.accountType && editForm) ||
                (formValues.fields.accountType && viewForm) ? (
                  <AppSelect
                    label="Select Account Type"
                    className="mb-4"
                    value={formValues.fields.accountType}
                    options={accountTypesLists}
                    errorMsg={formValues.error.accountType}
                    onChange={(e) => handleChange(e, "accountType")}
                    disabled={viewForm ? true : editForm ? false : false}
                    editDetails={editForm || viewForm}
                    setNameForView={true}
                    required
                  />
                ) : (
                  <AppSelect
                    label="Select Account Type"
                    className="mb-4"
                    value={formValues.fields.accountType}
                    options={accountTypesLists}
                    errorMsg={formValues.error.accountType}
                    onChange={(e) => handleChange(e, "accountType")}
                    disabled={viewForm ? true : editForm ? false : false}
                    addForm={true}
                    setNameForView={true}
                    required
                  />
                )}
                {(formValues.fields.allowedStates && editForm) ||
                (formValues.fields.allowedStates && viewForm) ? (
                  <AppSelect
                    label="Select Allow States"
                    className="mb-4"
                    value={formValues.fields.allowedStates}
                    options={statesList}
                    errorMsg={formValues.error.allowedStates}
                    onChange={(e) => handleChange(e, "allowedStates")}
                    onChangeSelectAll={(value) => {
                      setFormValues((prevState) => ({
                        ...prevState,
                        fields: {
                          ...prevState.fields,
                          allowedStates: value,
                        },
                      }));
                    }}
                    disabled={viewForm ? true : editForm ? false : false}
                    allowMulti={true}
                    editDetails={editForm || viewForm}
                    required
                  />
                ) : (
                  <AppSelect
                    label="Select Allow States"
                    className="mb-4"
                    value={formValues.fields.allowedStates}
                    options={statesList}
                    errorMsg={formValues.error.allowedStates}
                    onChange={(e) => handleChange(e, "allowedStates")}
                    disabled={viewForm ? true : editForm ? false : false}
                    onChangeSelectAll={(value) => {
                      setFormValues((prevState) => ({
                        ...prevState,
                        fields: {
                          ...prevState.fields,
                          allowedStates: value,
                        },
                      }));
                    }}
                    allowMulti={true}
                    addForm={true}
                    required
                  />
                )}
                {formValues.fields.isBatchSearchAllowed ? (
                  <AppSelect
                    label="Batch Search Provider"
                    className="mb-4 addAccountSelectInput"
                    value={formValues.fields.batchSearchProvider}
                    options={sourcesList}
                    errorMsg={formValues.error.batchSearchProvider}
                    disabled={viewForm ? true : false}
                    setNameForView={true}
                    onChange={(e) => handleChange(e, "batchSearchProvider")}
                    editDetails={editForm || viewForm}
                    addForm={true}
                    required
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col md={{ size: 6 }} className="addAccountInputs">
                {(viewForm || editForm) && formValues.fields.accountType && (
                  <AppSelect
                    label="Status"
                    className="mb-4 addAccountSelectInput"
                    value={formValues.fields.isActive}
                    options={Status}
                    errorMsg={formValues.error.isActive}
                    onChange={(e) => handleChange(e, "isActive")}
                    disabled={viewForm ? true : editForm ? false : false}
                    editDetails={editForm || viewForm}
                    setNameForView={true}
                    required
                  />
                )}
                <AppInput
                  label="Address Line 1"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.addressLine1}
                  errorMsg={formValues.error.addressLine1}
                  validations={{
                    required: true,
                    maxLength: 50,
                  }}
                  onChange={(e) => handleChange(e, "addressLine1")}
                  disabled={viewForm ? true : editForm ? false : false}
                />
                <AppInput
                  label="Address Line 2"
                  className="mb-4"
                  type="text"
                  validations={{
                    maxLength: 50,
                  }}
                  value={formValues.fields.addressLine2}
                  onChange={(e) => handleChange(e, "addressLine2")}
                  disabled={viewForm ? true : editForm ? false : false}
                />
                <Row>
                  <Col md={{ size: 4 }} className="addAccountSmallInputs">
                    <AppInput
                      label="City"
                      className="mb-4"
                      type="text"
                      value={formValues.fields.city}
                      errorMsg={formValues.error.city}
                      validations={{
                        required: true,
                      }}
                      onChange={(e) => handleChange(e, "city")}
                      disabled={viewForm ? true : editForm ? false : false}
                    />
                  </Col>
                  <Col md={{ size: 4 }} className="addAccountSmallInputs">
                    {(formValues.fields.state && editForm) ||
                    (formValues.fields.state && viewForm) ? (
                      <AppSelect
                        label="State"
                        className="mb-4 addAccountSelectInput"
                        value={formValues.fields.state}
                        options={getStatesList()}
                        setNameForView={true}
                        errorMsg={formValues.error.state}
                        onChange={(e) => handleChange(e, "state")}
                        disabled={viewForm ? true : editForm ? false : false}
                        editDetails={editForm || viewForm}
                        required
                      />
                    ) : (
                      <AppSelect
                        label="State"
                        className="mb-4 addAccountSelectInput"
                        value={formValues.fields.state}
                        setNameForView={true}
                        options={getStatesList()}
                        errorMsg={formValues.error.state}
                        onChange={(e) => handleChange(e, "state")}
                        disabled={viewForm ? true : editForm ? false : false}
                        addForm={true}
                        required
                      />
                    )}
                  </Col>
                  <Col
                    md={{ size: 4 }}
                    className="addAccountSmallInputs zipInput"
                  >
                    <AppInput
                      label="Zip Code"
                      className="mb-4"
                      type="number"
                      value={formValues.fields.zip}
                      errorMsg={formValues.error.zip}
                      validations={{
                        required: true,
                        minLength: 5,
                      }}
                      onChange={(e) => handleChange(e, "zip")}
                      disabled={viewForm ? true : editForm ? false : false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6 }} className="addAccountInputs">
                <Row>
                  {/* <Col md={{ size: 6 }} className="addAccountSmallInputs">
                      <FormGroup check inline className="mb-1">
                        <Input
                          type="checkbox"
                          name="checkbox"
                          checked={formValues.fields.isApiSearchAllowed}
                          onChange={(e) =>
                            handleChange(e, "isApiSearchAllowed")
                          }
                          disabled={viewForm ? true : editForm ? false : false}
                        />
                        <Label check>Allow Search Using API</Label>
                      </FormGroup>

                      {formValues.fields.isApiSearchAllowed ? (
                        <div className="apiKeyText text-secondary mb-2">
                          <b>API Key: </b>
                          {apiKeyValue}
                        </div>
                      ) : (
                        ""
                      )}
                    </Col> */}
                  {formValues.fields.accountType === "SUBSCRIPTION_ONLY" ? (
                    ""
                  ) : (
                    <>
                      <Col md={{ size: 6 }} className="addAccountSmallInputs">
                        <FormGroup check inline className="mb-2">
                          <Input
                            type="checkbox"
                            name="checkbox"
                            checked={formValues.fields.isBatchSearchAllowed}
                            onChange={(e) =>
                              handleChange(e, "isBatchSearchAllowed")
                            }
                            disabled={
                              viewForm ? true : editForm ? false : false
                            }
                          />
                          <Label check>Allow Batch Search</Label>
                        </FormGroup>
                      </Col>
                      {/* <Col md={{ size: 6 }} className="addAccountSmallInputs">
                            <FormGroup check inline className="mb-2">
                              <Input
                                type="checkbox"
                                name="checkbox"
                                checked={formValues.fields.isAllStatesAllowed}
                                onChange={(e) =>
                                  handleChange(e, "isAllStatesAllowed")
                                }
                                // errorMsg={formValues.error.isAllStatesAllowed}
                                disabled={viewForm ? true : editForm ? false : false}
                              />
                              <Label check>Allow All States</Label>
                            </FormGroup>
                          </Col> */}
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col md={{ size: 12 }}>
                <div className="pageTitle sectionTitle">
                  Contact Person Details
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6 }} className="addAccountInputs">
                <AppAutocomplete
                  label="Title"
                  className="mb-4 addAccountSelectInput"
                  value={formValues.fields.title}
                  options={titles}
                  errorMsg={formValues.error.title}
                  validations={{
                    maxLength: 50,
                  }}
                  onChange={(e) => handleChange(e, "title")}
                  disabled={viewForm ? true : editForm ? false : false}
                />

                <AppInput
                  label="First Name"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.firstName}
                  errorMsg={formValues.error.firstName}
                  validations={{
                    required: true,
                    maxLength: 25,
                    pattern: onlyText,
                  }}
                  onChange={(e) => handleChange(e, "firstName")}
                  disabled={viewForm ? true : editForm ? false : false}
                />

                <AppInput
                  label="Last Name"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.lastName}
                  errorMsg={formValues.error.lastName}
                  validations={{
                    required: true,
                    maxLength: 25,
                    pattern: onlyText,
                  }}
                  onChange={(e) => handleChange(e, "lastName")}
                  disabled={viewForm ? true : editForm ? false : false}
                />

                {/* {!viewForm && !editForm && (
                    <AppMultipleSelect
                      label="Allow Search in States"
                      className="mb-4 addAccountSelectInput"
                      value={formValues.fields.allowedStateIdList}
                      options={statesList}
                      errorMsg={formValues.error.allowedStateIdList}
                      onChange={(e) => handleChange(e, "allowedStateIdList")}
                      required
                    />
                  )} */}
              </Col>
              <Col md={{ size: 6 }} className="addAccountInputs">
                <AppInput
                  label="Phone Number"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.phoneNumber}
                  errorMsg={formValues.error.phoneNumber}
                  validations={{
                    required: true,
                  }}
                  onChange={(e) => handleChange(e, "phoneNumber")}
                  disabled={viewForm ? true : editForm ? false : false}
                />

                <AppInput
                  label="Email ID"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.email}
                  errorMsg={formValues.error.email}
                  validations={{
                    required: true,
                    maxLength: 50,
                    pattern: emailRegex,
                  }}
                  onChange={(e) => handleChange(e, "email")}
                  disabled={viewForm ? true : editForm ? false : false}
                />
                {!viewForm && (
                  <AppInput
                    label="Confirm Email ID"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.confirmEmail}
                    errorMsg={formValues.error.confirmEmail}
                    validations={{
                      pattern: emailRegex,
                      maxLength: 50,
                      required: true,
                    }}
                    onChange={(e) => handleChange(e, "confirmEmail")}
                    disabled={viewForm ? true : editForm ? false : false}
                  />
                )}
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col md={{ size: 12 }}>
                <div className="pageTitle sectionTitle">Billing Details</div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6 }} className="addAccountInputs">
                <AppInput
                  label="Billing Reference Code"
                  className="mb-4"
                  type="text"
                  value={formValues.fields.billingReferenceCode}
                  errorMsg={formValues.error.billingReferenceCode}
                  validations={{
                    maxLength: 255,
                  }}
                  onChange={(e) => handleChange(e, "billingReferenceCode")}
                  disabled={viewForm ? true : editForm ? false : false}
                />
              </Col>
            </Row>
          </div>
          {(!viewForm || editForm) && (
            <div>
              <Row className="mb-3">
                <Col>
                  {formError !== "" && (
                    <span className="text-danger errorMsg">{formError}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 6 }} className="addAccountBtns">
                  <div>
                    <Button className="addAccountBtn" type="submit">
                      {isLoading && (
                        <Spinner size="sm" className="mx-2"></Spinner>
                      )}
                      Submit
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="addAccountBtn cancelBtn"
                      type="button"
                      onClick={clearForm}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </form>
    );
  }

  function SubscriptionDetails() {
    return (
      <div>
        <Row>
          <Col md={{ size: 12 }}>
            <div className="pageTitle sectionTitle">Subscription Details</div>
          </Col>
        </Row>
        <div className="container-fluid pb-5">
          <div className="row">
            <div className="col">
              <div className="subscriptionTable">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="customTableHeaderCell" align="left">
                        Dataset Name
                      </TableCell>
                      <TableCell className="customTableHeaderCell">
                        Total Seats
                      </TableCell>
                      {(viewForm || editForm) && (
                        <TableCell className="customTableHeaderCell">
                          Seats in Use
                        </TableCell>
                      )}
                      {!viewForm && editForm && (
                        <TableCell
                          align="right"
                          className="customTableHeaderCell"
                        >
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assignedDatasets && assignedDatasets.fields.length > 0 ? (
                      assignedDatasets.fields.map((dataset, index) => {
                        return (
                          <TableRow>
                            <TableCell width="40%">{dataset.name}</TableCell>
                            <TableCell width="20%">
                              {viewForm ? (
                                dataset.seats
                              ) : (
                                <AppInput
                                  type="number"
                                  placeholder="Seats"
                                  value={dataset.seats}
                                  onChange={(e) => {
                                    if (parseInt(e.target.value.toString()) > 0)
                                      assignedDatasets.update(index, {
                                        ...assignedDatasets.fields[index],
                                        seats: e.target.value,
                                      });
                                    else
                                      assignedDatasets.update(index, {
                                        ...assignedDatasets.fields[index],
                                        seats: null,
                                      });
                                  }}
                                />
                              )}
                            </TableCell>
                            {(viewForm || editForm) && (
                              <TableCell width="20%">
                                {dataset.assignedSeats}
                              </TableCell>
                            )}
                            {!viewForm && editForm && (
                              <TableCell align="right" width="20%">
                                <Button
                                  type="button"
                                  disabled={dataset.assignedSeats !== 0}
                                  onClick={async () => {
                                    assignedDatasets.remove(
                                      assignedDatasets.fields.findIndex(
                                        (e) => e.id === dataset.id
                                      )
                                    );

                                    setValue("selectedDataset", {});
                                  }}
                                >
                                  Unassign
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                    ) : (
                      <div className="text-muted py-3 ps-3">
                        No Datasets Assigned!
                      </div>
                    )}

                    {!viewForm &&
                      availableDatasets &&
                      availableDatasets.filter((dataset) => {
                        const isAssigned =
                          watch("assignedDatasets") &&
                          watch("assignedDatasets").length > 0 &&
                          watch("assignedDatasets").find(
                            (e) => e.id === dataset.id
                          )
                            ? true
                            : false;

                        return !isAssigned;
                      }).length > 0 && (
                        <TableRow>
                          <TableCell width="40%">
                            <AppSelect
                              label="Select Dataset"
                              options={availableDatasets
                                .filter((dataset) => {
                                  const isAssigned =
                                    watch("assignedDatasets") &&
                                    watch("assignedDatasets").length > 0 &&
                                    watch("assignedDatasets").find(
                                      (e) => e.id === dataset.id
                                    )
                                      ? true
                                      : false;

                                  return !isAssigned;
                                })
                                .map((dataset) => {
                                  return {
                                    id: dataset.id,
                                    initials: dataset.name,
                                  };
                                })}
                              value={watch("selectedDataset.id")}
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setValue("selectedDataset.id", e.target.value);
                              }}
                              editDetails={true}
                            />
                          </TableCell>
                          <TableCell width="20%">
                            <AppInput
                              type="number"
                              value={watch("selectedDataset.seats")}
                              placeholder="Seats"
                              onChange={(e) => {
                                if (parseInt(e.target.value.toString()) > 0)
                                  setValue(
                                    "selectedDataset.seats",
                                    e.target.value
                                  );
                                else setValue("selectedDataset.seats", null);
                              }}
                            />
                          </TableCell>
                          {(viewForm || editForm) && (
                            <TableCell width="20%"></TableCell>
                          )}
                          <TableCell align="right" width="20%">
                            <Button
                              type="button"
                              disabled={
                                !watch("selectedDataset.seats") ||
                                !watch("selectedDataset.id")
                              }
                              onClick={async () => {
                                assignedDatasets.append({
                                  ...watch("selectedDataset"),
                                  name: availableDatasets.find(
                                    (e) => e.id === watch("selectedDataset.id")
                                  ).name,
                                  assignedSeats: 0,
                                });
                                // await handleSetAvailableDatasets(
                                //   watch("assignedDatasets")
                                // );
                                setValue("selectedDataset", {});
                              }}
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </div>
              <div className="my-4" />
              {editForm && (
                <div className="container">
                  <div className="row justify-content-center gap-2">
                    <div className="col-md-2">
                      <Button
                        className="addAccountBtn"
                        type="button"
                        // disabled={disableSaveSubscription()}
                        onClick={handleSaveSubscriptionLicense}
                      >
                        {isLoading && (
                          <Spinner size="sm" className="mx-2"></Spinner>
                        )}
                        Save
                      </Button>
                    </div>
                    <div className="col-md-2">
                      <Button
                        className="addAccountBtn cancelBtn"
                        type="button"
                        onClick={() => {
                          props.history.push("/account/list");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <AppLoader isLoading={isLoading || loader} />

      <div className="container-fluid">
        <div
          className="pageTitle"
          style={{
            textAlign: "center",
          }}
        >
          {viewForm
            ? "Account Details"
            : editForm
            ? "Edit Account"
            : "Add New Account"}
        </div>

        <AppTabs
          centered={true}
          showSingleTab={false}
          active={activeTab}
          onTabChange={setActiveTab}
        >
          <AppTab title="Account Details" tabKey="account-details">
            {AccountDetailsForm()}
          </AppTab>
          <AppTab
            title="Subscription Details"
            tabKey="subscription-details"
            hidden={
              (!viewForm && !editForm) ||
              ((viewForm || editForm) &&
                (!["SEARCH_AND_SUBSCRIPTION", "SUBSCRIPTION_ONLY"].includes(
                  oldInfo.accountType
                ) ||
                  formValues.fields.accountType === "SEARCH_ONLY"))
            }
          >
            {SubscriptionDetails()}
          </AppTab>
        </AppTabs>
      </div>
    </>
  );
}
