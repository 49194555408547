/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Collapse, IconButton } from "@mui/material";
import React from "react";
import "./AppAlert.css";

export default function AppAlert(props) {
  const {
    open,
    onCloseClick,
    message,
    secondaryMessage,
    icon,
    closeBtn,
    className,
    link,
    linkClicked,
  } = props;

  return (
    <>
      <Collapse in={open}>
        <Alert
          className={`appAlertContainer changeAlertWidth ${
            className ? className : ""
          }`}
          severity="warning"
          icon={
            <div
              className="alertMessageIcon"
              style={{
                background: `url(${icon})`,
                top: secondaryMessage ? "13px" : "3px",
              }}
            ></div>
          }
          action={
            closeBtn && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  onCloseClick(true);
                }}
              >
                <div className="crossDeleteIcon alertIcon"></div>
              </IconButton>
            )
          }
          sx={{ mb: 2 }}
        >
          {message}
          {secondaryMessage ? (
            <>
              <br />
              {secondaryMessage}
            </>
          ) : (
            ""
          )}
          &nbsp;
          {link && link !== "" && (
            <a className="link" onClick={() => linkClicked()}>
              {link}
            </a>
          )}
        </Alert>
      </Collapse>
    </>
  );
}
