import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import useTable from "../../Components/useTable";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Redirect } from "react-router-dom";
import useToken from "../../shared/hooks/useToken";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import {
  downloadFile,
  getDownloadOnlyDetails,
} from "../../Utils/services/downloads.service.js";
import useSubDomainName from "../../shared/hooks/useSubDomainName.jsx";
import downloadIcon from "../../assets/icons/sidebar/download-icon.svg";

const headCellsForDownload = [
  { id: "fileName", label: "FILE NAME", width: "33.33%", disableSorting: true },
  { id: "scan_status", label: "SCAN STATUS", width: "33.33%", disableSorting: true },
  { id: "action", label: "ACTION", width: "33.33%", disableSorting: true },
];

const DownloadOnly = () => {
  useDocumentTitle("Downloads");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { TblContainer, TblHead } = useTable(data, headCellsForDownload);
  const { token } = useToken();
  const { subDomainName, setSubDomainName } = useSubDomainName();

  const getListOfDownloads = async () => {
    setIsLoading(true);

    try {
      const response = await getDownloadOnlyDetails(subDomainName);

      if (response.code === 200) {
        setData(response?.body);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const downloadFileName = async (fileName, fileId) => {
    try {
      const response = await downloadFile(fileId, subDomainName);

      if (response?.code === 200) {
        const presignedurl = response?.body;

        const link = document.createElement('a');
        link.href = presignedurl;
        link.setAttribute('download', `${fileName}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (!subDomainName) {
      setSubDomainName();
    }
    if (token) {
      getListOfDownloads(subDomainName);
    }
  }, []);

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div>
          <AppLoader isLoading={isLoading} />
          <div className={""}>
            <div className="">
              <Row className="mb-2">
                <Col lg={{ size: 6 }}>
                  <div>
                    <div className="pageTitle">Files</div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  {error && (
                    <span className="text-danger errorMsg">
                      {error?.substring(0, 100)}
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <InfiniteScroll
                    className="userListTable"
                    dataLength={data.length}
                    height="100%"
                  >
                    <TblContainer>
                      <TblHead />
                      <TableBody>
                        {data.length ? (
                          data.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell
                                title={item.fileName}
                                className="companyNameColumn"
                              >
                                <div
                                  className="text-truncate text-lowercase"
                                  style={{ maxWidth: "90%" }}
                                  title={item?.fileName}
                                >
                                  {item?.fileName?.length > 40
                                    ? item?.fileName
                                      ?.substring(0, 40)
                                      ?.concat("...")
                                    : item?.fileName}
                                </div>
                              </TableCell>
                              <TableCell
                                title={item?.scanStatus}
                              >
                                {item?.scanStatus}
                              </TableCell>
                              <TableCell
                                className="text-start cursor-pointer"
                                style={{
                                  cursor: "pointer",
                                }}
                                title={item?.scanStatus === "CLEAN" ? `Download file ${item?.fileName}` : "Can't download file"}
                              >
                                <IconButton
                                  disabled={item?.scanStatus === "INFECTED" || item?.scanStatus === "IN_PROGRESS"}
                                  onClick={() =>
                                    downloadFileName(
                                      item?.fileName,
                                      item?.fileId
                                    )
                                  }
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#d1cfcf !important"
                                    }
                                  }}
                                >
                                  <img src={downloadIcon} alt="Download Icon" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              No Result Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </TblContainer>
                  </InfiniteScroll>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadOnly;
