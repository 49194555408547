import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import "./ReviewWorkflow.css";
import ReviewStepper from "../../Containers/ReviewStepper/ReviewStepper";
import { Button } from "reactstrap";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { Box } from "@mui/material";
import {
  downloadBatchResultFileForSA,
  downloadNewBatchFile,
  publishFileToCA,
  rePublishFileToCA,
} from "../../Utils/services/review.service";
import {
  downloadExcelFile,
  downloadFile,
} from "../../Utils/services/batchSearch.service";
import { ToastrService } from "../../Utils/services/tostr.service";
import BatchFilePreview from "../../Containers/BatchFilePreview/BatchFilePreview";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import { getFileNameFromHeader, maxFileSize } from "../../shared/constants/constants";
import * as ActionTypes from "../../Redux/ActionTypes";

const steps = [
  "Download New File",
  "Upload File & Proceed with Searches",
  "Download Result",
  "Upload File & Publish to Customer",
  "Re-publish Expire Data",
];

const publishModalActionBtns = [
  { name: "Yes, Publish", submit: true },
  { name: "Cancel", className: "cancelBtn", submit: false },
];

const republishModalActionBtns = [
  { name: "Yes, Re-publish", submit: true },
  { name: "Cancel", className: "cancelBtn", submit: false },
];

export default function ReviewWorkflow() {
  useDocumentTitle("Review");

  const { accountData } = useSelector((state) => state);

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [publishToCustomerModal, setPublishToCustomerModal] = useState(false);
  const [republishExpireDataModal, setRepublishExpireDataModal] =
    useState(false);
  const [file, setFile] = useState();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!accountData?.accountId && !accountData?.batchFileDetails?.id) {
      history.push("/account/list");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadNewFile = async (originalTracerFile = false) => {
    if (activeStep === 0) {
      setIsLoading(true);
      try {
        let response = await downloadNewBatchFile(
          accountData?.batchFileDetails?.id,
          accountData?.accountId
        );
        const fileName = getFileNameFromHeader(
          response?.headers["content-disposition"]
        );
        downloadFile(response.data, fileName);
        setIsLoading(false);
        handleNext();
      } catch (error) {
        setIsLoading(false);
        ToastrService.error(error.message);
      }
    } else {
      // Download Result File
      setIsLoading(true);
      try {
        let response = await downloadBatchResultFileForSA(
          accountData?.batchFileDetails?.id,
          accountData?.accountId,
          originalTracerFile
        );

        const fileName = getFileNameFromHeader(
          response?.headers["content-disposition"]
        );
        if (fileName.includes("csv")) {
          downloadFile(response.data, fileName);
        } else {
          downloadExcelFile(response.data, fileName);
        }
        setIsLoading(false);
        handleNext();
      } catch (error) {
        setIsLoading(false);
        ToastrService.error(error.message);
      }
    }
  };

  const publishFileToCustomer = async () => {
    setIsLoading(true);
    try {
      let apiResponse = await publishFileToCA(
        file,
        accountData?.batchFileDetails?.id,
        accountData?.accountId
      );

      let response = apiResponse.data.responseObject;
      if (response.code === 200) {
        ToastrService.success(response.message);
        setIsLoading(false);
        dispatch({
          type: ActionTypes.SAVE_ACCOUNT_DATA,
          payload: {
            batchListFilterValue: "Published",
          },
        });
        history.push("/account/batchList");
      } else {
        ToastrService.error(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const rePublishFileToCustomer = async () => {
    setIsLoading(true);
    try {
      let apiResponse = await rePublishFileToCA(
        accountData?.batchFileDetails?.id,
        accountData?.accountId
      );

      let response = apiResponse.data.responseObject;
      if (response.code === 200) {
        ToastrService.success(response.message);
        setIsLoading(false);
        dispatch({
          type: ActionTypes.SAVE_ACCOUNT_DATA,
          payload: {
            batchListFilterValue: "Published",
          },
        });
        history.push("/account/batchList");
      } else {
        ToastrService.error(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    if (
      activeStep === 2 &&
      accountData?.batchFileDetails.fileStatus === "Expired"
    ) {
      setActiveStep(4);
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 4) {
      setActiveStep(2);
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const handleFile = (files) => {
    if (files.length) {
      setFile(files[0]);
      if (activeStep === 1) {
        if (files[0].type !== "text/csv" || files[0].size > maxFileSize) {
          ToastrService.error("Please make sure that the file format is csv and the size is not more than 15MB.");
          return;
        }
        setOpenPreview(true);
      } else {
        if ((files[0].type !== "text/csv" && files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || files[0].size > maxFileSize) {
          ToastrService.error("Please make sure that the file format is xlsx/csv and the size is not more than 15MB.");
          return;
        }
        setPublishToCustomerModal(true);
      }
    }
  };

  return (
    <>
      {openPreview && (
        <BatchFilePreview
          file={file}
          batchId={accountData?.batchFileDetails?.id}
          accountId={accountData?.accountId}
          onClose={(flag) => {
            setOpenPreview(false);
            setFile(null);
            if (flag) {
              dispatch({
                type: ActionTypes.SAVE_ACCOUNT_DATA,
                payload: {
                  batchListFilterValue: "All",
                },
              });
              history.push("/account/batchList");
            }
          }}
        />
      )}
      {publishToCustomerModal && (
        <ConfirmModal
          title="Are you sure you want to publish this file to the customer?"
          actionBtns={publishModalActionBtns}
          onClose={(publishFlag) => {
            if (typeof publishFlag == "boolean" && publishFlag) {
              publishFileToCustomer();
            }
            setPublishToCustomerModal(false);
          }}
        ></ConfirmModal>
      )}
      {republishExpireDataModal && (
        <ConfirmModal
          title="Are you sure you want to re-publish the file to customer? It will reset the downloadable time to 72 hours for customers."
          actionBtns={republishModalActionBtns}
          onClose={(publishFlag) => {
            if (typeof publishFlag == "boolean" && publishFlag) {
              rePublishFileToCustomer();
            }
            setRepublishExpireDataModal(false);
          }}
        ></ConfirmModal>
      )}
      <AppLoader isLoading={isLoading} />
      <div className="container-fluid">
        <div className="pageTitle text-capitalize">
          {accountData?.tenantName}
        </div>
        <div className="sectionTitle mb-3">
          File Name - {accountData?.batchFileDetails?.fileName}
        </div>
        <div className="ReviewPageSection">
          <ReviewStepper
            steps={steps}
            batchDetails={accountData?.batchFileDetails}
            activeStep={activeStep}
            activeStepChanged={(step) => setActiveStep(step)}
          />
        </div>
        <div className="ReviewPageSection mt-4">
          <div className="pageTitle sectionTitle">{steps[activeStep]}</div>
          {(activeStep === 0 || activeStep === 2) && (
            <>
              <div className="text-center">
                {activeStep === 2 && (
                  <div className="mx-3 originalTracerDownloadBtn">
                    <Button
                      onClick={() => downloadNewFile(true)}
                      disabled={isLoading}
                    >
                      Download Result File
                    </Button>
                  </div>
                )}
                {activeStep === 0 && (
                  <Button
                    onClick={() => downloadNewFile()}
                    disabled={isLoading}
                  >
                    Download New File
                  </Button>
                )}
                {activeStep === 2 &&
                  accountData?.batchFileDetails.fileStatus.toLowerCase() !==
                    "pending review" && (
                    <Button
                      onClick={() => downloadNewFile()}
                      disabled={isLoading}
                    >
                      Download Last Published File
                    </Button>
                  )}
              </div>
            </>
          )}
          {(activeStep === 1 || activeStep === 3) && (
            <>
              <div
                className="fileUploadContainer mt-3"
                onClick={() =>
                  document.getElementById("batchFileInput").click()
                }
              >
                <div className="fileUploadTitleSection">
                  <div className="smallUploadIconBatchSearch"></div>
                  <div className="fileUploadText">
                    <div className="fileUploadTextTitle">
                      {activeStep === 1
                        ? "Want to do a Batch Search?"
                        : "Upload File & Publish to Customer"}
                    </div>
                    <div className="fileUploadSubTitle">
                      Upload file of max 15MB
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep === 4 && (
            <>
              <div className="text-center">
                <Button
                  onClick={() => setRepublishExpireDataModal(true)}
                  disabled={isLoading}
                >
                  Re-publish Last Published File
                </Button>
              </div>
            </>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              className="cancelBtn"
              onClick={handleBack}
              disabled={activeStep === 0 || activeStep === 2}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              className="cancelBtn"
              onClick={handleNext}
              disabled={
                activeStep === 1 || activeStep === 3 || activeStep === 4
              }
              sx={{ mr: 1 }}
            >
              Next
            </Button>
          </Box>
        </div>
      </div>
      <input
        type="file"
        accept={activeStep === 1 ? ".csv" : ".csv, .xlsx"}
        id="batchFileInput"
        onClick={(e) => (e.currentTarget.value = "")}
        onChange={(e) => handleFile(e.target.files)}
        hidden
      />
    </>
  );
}
