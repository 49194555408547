import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "reactstrap";
import "./ConfirmModal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 650 ? 600 : 350,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 4,
};

export default function ConfirmModal(props) {
  const { title, children, actionBtns, onClose, hideCloseIcon, backdropCloseDisabled } = props;

  const handleClose = (flag = false) => onClose(flag);

  return (
    <>
      <Modal
        open={true}
        disableEscapeKeyDown={true}
        onClose={() => {if (!backdropCloseDisabled) handleClose();}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {
            !hideCloseIcon
            &&
            <div className="crossDeleteIcon" onClick={handleClose}></div>
          }
          <p className="modalTitle mt-4">{title}</p>
          <div id="parent-modal-description" className="modalSection">
            {children}
          </div>
          <div className="modalBtns">
            {actionBtns &&
              actionBtns.map((btn, key) => (
                <Button
                  className={`addAccountBtn ${
                    btn.className ? btn.className : ""
                  }`}
                  key={btn.name}
                  onClick={() => handleClose(btn.submit)}
                >
                  {btn.name}
                </Button>
              ))}
          </div>
        </Box>
      </Modal>
    </>
  );
}
