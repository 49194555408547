import React from "react";
import { useHistory } from "react-router";
import { privileges } from "../../shared/constants/constants";
import { Auth } from "../../Utils/helpers";
import "./AppIconSection.css";

export default function AppIconSection(props) {
  const history = useHistory();

  const { refreshIcon, hideUploadIcon } = props;

  return (
    <>
      <div className="appIconSection text-end">
        {refreshIcon && (
          <div
            className="appRefreshIcon"
            onClick={() => props.onRefreshClicked()}
            title="Refresh List"
          ></div>
        )}
        {!hideUploadIcon &&
        Auth.getUserPrivileges().includes(privileges.batch_search) ? (
          <div
            className="appUploadIcon"
            onClick={() => history.push("/account/batchSearch")}
            title="Batch Search"
          ></div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
