import React, { useState } from "react";
import "./AppDatePicker.css";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl } from "@mui/material";

export default function AppDatePicker(props) {
  const { label, className, value, minDate, onChange } = props;
  const [selectedDate, setSelectedDate] = useState(value ? value : new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <>
      <FormControl
        className={`dateSelectContainer ${className ? className : ""}`}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disableFuture
            autoOk={true}
            label={label}
            className={`customDatePickerInput ${
              !selectedDate ? "datePickerInputHaveNoValue" : ""
            }`}
            format="MM/dd/yyyy"
            minDate={minDate ? minDate : false}
            variant="inline"
            value={selectedDate}
            onChange={(newValue) => {
              handleDateChange(newValue);
            }}
            inputVariant="outlined"
            size="small"
            id="date-picker-inline"
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </>
  );
}
