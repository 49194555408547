import SearchResult from "../Pages/SearchResult/SearchResult.jsx";
import BatchResult from "../Pages/BatchResult/BatchResult.jsx";
import { privileges } from "../shared/constants/constants.js";
import MyProfile from "../Pages/MyProfile/MyProfile.jsx";
import BillAccount from "../Pages/BillAccount/BillAccount.jsx";
import AccountStats from "../Pages/AccountStats/AccountStats.jsx";
import BatchSearchHistory from "../Pages/BatchSearchHistory/BatchSearchHistory";
import ReviewWorkflow from "../Pages/ReviewWorkflow/ReviewWorkflow.jsx";
import FileUsersLists from "../Pages/FileUsersLists/FileUsersLists.jsx";
import SubscriptionSearch from "../Pages/SubscriptionSearch/SubscriptionSearch.jsx";

const baseUrl = "/account";

var DashboardRoutes = [
  {
    id: 0,
    path: `${baseUrl}/search/result/:id`,
    name: "Search Result",
    component: SearchResult,
    privilege: privileges.my_profile,
  },
  {
    id: 1,
    path: `${baseUrl}/search/batchResult`,
    name: "Batch Search Result",
    component: BatchResult,
    privilege: privileges.batch_search,
  },
  {
    id: 2,
    path: `${baseUrl}/profile`,
    name: "My Profile",
    component: MyProfile,
    privilege: privileges.my_profile,
  },
  {
    id: 3,
    path: `${baseUrl}/bill`,
    name: "Bill Account",
    component: BillAccount,
    privilege: privileges.billing,
  },
  {
    id: 4,
    path: `${baseUrl}/stats`,
    name: "Account Statistics",
    component: AccountStats,
    privilege: privileges.review_flow,
  },
  {
    id: 5,
    path: `${baseUrl}/batchList`,
    name: "Batch File Lists",
    component: BatchSearchHistory,
    privilege: privileges.review_flow,
  },
  {
    id: 6,
    path: `${baseUrl}/review`,
    name: "Batch File Review",
    component: ReviewWorkflow,
    privilege: privileges.review_flow,
  },
  {
    id: 7,
    path: `${baseUrl}/uploads/:fileid`,
    name: "handle File Users",
    component: FileUsersLists,
    privilege: privileges.upload_files,
  },
];

export default DashboardRoutes;
