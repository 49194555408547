import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router";
import { Col, Row, Button } from "reactstrap";
import AppIconSection from "../../Components/AppIconSection/AppIconSection";
import AppLoader from "../../Components/AppLoader/AppLoader";
import DateRangeFilter from "../../Components/DateRangeFilter/DateRangeFilter";
import TableLoader from "../../Components/TableLoader/TableLoader";
import useTable from "../../Components/useTable";
import Dashboard from "../../Containers/Dashboard/Dashboard";
import DashboardNavTabs from "../../Containers/DashboardNavTabs/DashboardNavTabs";
import { convertTZ, firstDayOfMonth, formatDate } from "../../shared/constants/constants";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import {
  downloadSingleSearchCountFile,
  getSingleSearchList,
} from "../../Utils/services/accounts.service";
import { downloadFile } from "../../Utils/services/batchSearch.service";
import "./SingleSearchHistory.css";

const headCells = [
  {
    id: "plate_number",
    label: "License Plate Number",
    width: "18%",
    disableSorting: false,
  },
  { id: "license_state", label: "State", width: "5%", disableSorting: false },
  {
    id: "make",
    label: "Make / Modal",
    width: "12%",
    disableSorting: true,
  },
  { id: "year", label: "Year", width: "5%", disableSorting: true },
  { id: "ownerName", label: "Owner Name", width: "15%", disableSorting: true },
  // {
  //   id: "request_type",
  //   label: "Search Type",
  //   width: "10%",
  //   disableSorting: false,
  // },
  {
    id: "searchedOn",
    label: "Searched On",
    width: "15%",
    disableSorting: true,
  },
  {
    id: "searchedBy",
    label: "Searched By",
    width: "15%",
    disableSorting: true,
  },
  {
    id: "recordAvailableForHrs",
    label: "Record Available for (Hours)",
    width: "15%",
    disableSorting: true,
  },
];

export default function SingleSearchHistory() {
  useDocumentTitle("Single search history");

  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(firstDayOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const { TblContainer, TblHead } = useTable(data, headCells);

  const history = useHistory();

  useEffect(() => {
    fetchSingleSearchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, startDate, endDate]);

  const fetchSingleSearchList = async (resetData = false) => {
    setloader(true);
    try {
      let response = await getSingleSearchList({
        sortDirection: order,
        sortBy: orderBy,
        pageNumber: page,
        size: 10,
        searchBy: "",
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
      });

      if (response.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach(
            (item) => (item.searchedOn = convertTZ(item.searchedOn))
          );
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setError("");
      } else {
        setloader(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setError(error.message);
    }
  };

  const handleSortRequest = (orderBy, order) => {
    document.getElementsByClassName("searchHistoryTable")[0].scrollTo(0, 0);
    setOrder(order);
    setPage(0);
    setOrderBy(orderBy);
    setTimeout(() => {
      setData([]);
    }, 10);
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  const checkIfValueIsNullOrEmpty = (value) => {
    if (value && value !== "") {
      return value;
    } else {
      return "-";
    }
  };

  const downloadSearchListExcelFile = async () => {
    setIsLoading(true);

    try {
      let response = await downloadSingleSearchCountFile({
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
      });

      downloadFile(response, `Accounts_Single_Search_Report.csv`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  return (
    <>
      <AppLoader isLoading={isLoading} />
      <Dashboard />
      <DashboardNavTabs active="single" />
      <div className="container-fluid mt-4">
        <Row className="searchHistoryHeader">
          <Col md={{ size: 6 }}>
            <div className="searchHistoryTitle">Single Search List</div>
          </Col>
          <Col md={{ size: 6 }}>
            <AppIconSection />
          </Col>
        </Row>
        <div className="mt-4">
          <DateRangeFilter
            startDate={startDate}
            endDate={endDate}
            onChange={(data) => {
              handleSortRequest(orderBy, order);
              if (startDate !== data.startDate) {
                setStartDate(data.startDate);
              }
              if (endDate !== data.endDate) {
                setEndDate(data.endDate);
              }
            }}
          />
        </div>
        <Row className="mt-2">
          <Col md={{ size: 6 }}>
            <div className="searchTotalCountTitle">
              Total Search Count : {totalRecords}
            </div>
          </Col>
          <Col md={{ size: 6 }}>
            <div className="text-end">
              <Button
                className="searchHistoryExportBtn"
                onClick={(e) => downloadSearchListExcelFile()}
                disabled={endDate < startDate}
              >
                Export CSV
              </Button>
            </div>
          </Col>
        </Row>
        <div className="historySection">
          <Row className="searchHistoryList">
            <Col>
              {error !== "" && (
                <span className="text-danger errorMsg">{error}</span>
              )}
            </Col>
          </Row>
          <Row className="searchHistoryList">
            <Col>
              <InfiniteScroll
                className="searchHistoryTable"
                dataLength={data.length}
                next={() => addNewData()}
                hasMore={data.length < totalRecords}
                height={398}
                endMessage={
                  !loader &&
                  !data.length && (
                    <p className="text-secondary mt-3 text-center">
                      No Result Found
                    </p>
                  )
                }
              >
                <TblContainer>
                  <TblHead
                    handleSort={(orderBy, order) =>
                      handleSortRequest(orderBy, order)
                    }
                  />
                  <TableBody>
                    {data.map((vehicle, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell className="licensePlateColumn">
                            <div
                              className="licensePlateCell"
                              onClick={() => {
                                sessionStorage.setItem("searchId", vehicle.id);
                                history.push("/account/search/result/false");
                              }}
                            >
                              <div className="smallCarIcon"></div>
                              <div className="licenseNumberText">
                                {checkIfValueIsNullOrEmpty(vehicle.plateNumber)}
                              </div>
                            </div>
                            <div className="tableCellSubValue">
                              VIN: {checkIfValueIsNullOrEmpty(vehicle.vin)}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="tableCellValue">
                              {checkIfValueIsNullOrEmpty(vehicle.licenseState)}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="tableCellValue text-uppercase">
                              {checkIfValueIsNullOrEmpty(vehicle.make)}
                            </div>
                            <div className="tableCellSubValue">
                              {checkIfValueIsNullOrEmpty(vehicle.model)}
                            </div>
                          </TableCell>
                          <TableCell className="tableCellValue">
                            <div className="tableCellValue">
                              {checkIfValueIsNullOrEmpty(vehicle.year)}
                            </div>
                          </TableCell>
                          <TableCell className="tableCellValue">
                            <div className="tableCellValue">
                              {checkIfValueIsNullOrEmpty(vehicle.ownerName)}
                            </div>
                          </TableCell>
                          {/* <TableCell>
                            <div className="tableCellValue">
                              {checkIfValueIsNullOrEmpty(vehicle.searchType)}
                            </div>
                            <div className="tableCellSubValue">{}</div>
                          </TableCell> */}
                          <TableCell>
                            <div className="tableCellValue">
                              {checkIfValueIsNullOrEmpty(vehicle.searchedOn)}
                            </div>
                            <div className="tableCellSubValue">{}</div>
                          </TableCell>
                          <TableCell>
                            <div className="tableCellValue">
                              {checkIfValueIsNullOrEmpty(vehicle.searchedBy)}
                            </div>
                            <div className="tableCellSubValue">{}</div>
                          </TableCell>
                          <TableCell>
                            <div className="remainingTimeCell">
                              <div className="smallTimerIcon"></div>
                              <div
                                className={`tableCellValue remainingTimeValue ${
                                  !+vehicle.recordAvailableForHrs &&
                                  "text-danger"
                                }`}
                              >
                                {checkIfValueIsNullOrEmpty(
                                  vehicle.recordAvailableForHrs
                                )}
                              </div>
                            </div>
                            <div className="tableCellSubValue">Hours Left</div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {loader && <TableLoader columnsNumber={9} />}
                  </TableBody>
                </TblContainer>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
