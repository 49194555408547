import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../Utils/helpers";
import { fetchRestrictedListOfState } from "../../Utils/services/accounts.service";
import { ToastrService } from "../../Utils/services/tostr.service";
import * as ActionTypes from "../../Redux/ActionTypes";

const useRestrictedStatesList = () => {
  const { stateList } = useSelector((state) => state.stateList);

  const getStateList = () => {
    return stateList;
  };

  const [statesList, setStatesList] = useState(getStateList());
  const [stateLoader, setStateLoader] = useState(false);
  const dispatch = useDispatch();

  const saveStatesList = async () => {
    const account_no = sessionStorage.getItem("accountNumber");
    setStateLoader(true);
    try {
      let result = await fetchRestrictedListOfState(Auth.getTenant(), account_no);
      if (result.code === 200) {
        if (result.body && result.body.length) {
          setStatesList([...result.body]);
          dispatch({
            type: ActionTypes.SAVE_RESTRICTED_STATE_LIST,
            payload: [...result.body],
          });
        }
      } else {
        ToastrService.error(result.message);
      }
      setStateLoader(false);
    } catch (error) {
      // console.log(error);
      setStateLoader(false);
      ToastrService.error(error.message);
    }
    return Promise.resolve();
  };

  return {
    setStateList: saveStatesList,
    statesList,
    loader: stateLoader,
  };
}

export default useRestrictedStatesList;
