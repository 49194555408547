import React from "react";
import { useHistory } from "react-router-dom";
import Dashboard from "../../Containers/Dashboard/Dashboard";
import "./SearchHome.css";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { Auth } from "../../Utils/helpers";
import { privileges } from "../../shared/constants/constants";

export default function SearchHome() {
  useDocumentTitle("Search");

  const history = useHistory();

  const redirectToBatchSearchPage = () => {
    history.push("/account/batchSearch");
  };

  return (
    <>
      <Dashboard />
      <div className="searchHomeContainer">
        <div className="iconsContainer">
          <div className="searchIcon">
            <div>Search</div>
          </div>
          <div className="arrowIcon"></div>
          <div className="resultIcon">
            <div>Get results</div>
          </div>
          <div className="arrowIcon"></div>
          <div className="billIcon">
            <div>Send bill</div>
          </div>
        </div>
        <div className="textContainer">It’s that simple. Let’s get started</div>
        {Auth.getUserPrivileges().includes(privileges.batch_search) && (
          <div className="uploadSection" onClick={redirectToBatchSearchPage}>
            <div className="uploadSectionUploadIcon"></div>
            <div className="uploadSectionTextContainer">
              <p>Want to do a Batch search?</p>
              <p className="subTitle">Upload .csv file of max 15MB</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
